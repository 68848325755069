<div class="CotasDevidasComponent" *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(848) && this.quantidadeCotasEmAberto !== 0">

  <div *ngIf="this.stateService.possuiApenasPerfilCondomino(); then vocePossui else condominioPossui"></div>

  <ng-template #vocePossui>
    <div
      class="CollapseLight"
      [ngClass]="{ '__is-open': isCollapseOpen }"
    >
      <div class="Collapse_Header" (click)="toggle()">
        <div class="Collapse_Header_Title">
          <i class="fas fa-exclamation Icon __is-exclamation"></i>
          Você possui {{ quantidadeCotasEmAberto }} {{ (quantidadeCotasEmAberto > 1) ? ' boletos ' : ' boleto ' }} em aberto
        </div>
    
        <div class="Collapse_Header_Icon">
          <i class="fas fa-caret-down"></i>
        </div>
      </div>
    
      <div class="Collapse_Content">
        <div class="CotasDevidasComponent_Container">
          <div class="CotasDevidasComponent_Label">
            Valor total de boletos em aberto acumulados:
          </div>
          
          <div class="CotasDevidasComponent_Value" [ngClass]="{'__is-bad': valorTotal > 0}">
            R$ {{ valorTotal }}
          </div>
        </div>
  
        <div (click)="redirectCotas()" class="CotasDevidasComponent_SeeMoreContainer">
          <a class="Link">
            <i class="Icon fas fa-chevron-right"></i>
            Ver lista completa dos boletos
          </a>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template #condominioPossui>
    <div
      class="CollapseLight"
      [ngClass]="{ '__is-open': isCollapseOpen }"
    >
      <div class="Collapse_Header" (click)="toggle()">
        <div class="Collapse_Header_Title">
          <i class="fas fa-exclamation Icon __is-exclamation"></i>
          O condomínio possui {{ quantidadeCotasEmAberto }} cotas devidas
        </div>
            <div class="Collapse_Header_Icon">
          <i class="fas fa-caret-down"></i>
        </div>
      </div>

      <div class="Collapse_Content">
        <div class="CotasDevidasComponent_Container">
          <div class="CotasDevidasComponent_Label">
            Valor total de cotas devidas acumuladas:
          </div>
          
          <div class="CotasDevidasComponent_Value" [ngClass]="{'__is-bad': valorTotal > 0}">
            R$ {{ valorTotal }}
          </div>
        </div>
  
        <div (click)="redirectCotas()" class="CotasDevidasComponent_SeeMoreContainer">
          <a class="Link">
            <i class="Icon fas fa-chevron-right"></i>
            Ver lista completa de cotas devidas
          </a>
        </div>
      </div>
   </div>
  </ng-template>
</div>