<div class="CadastrarOcorrencia_Upload">
  <label for="{{id}}" class="CadastrarOcorrencia_Upload_Action Button Button_Primary Button_Outline">
    <div class="Button_Content">
      <div class="Button_Content_Icon" *ngIf="icon">
        <i class="fas" [ngClass]="[icon]"></i>
      </div>
      <div class="Button_Content_Label" *ngIf="text">{{text}}</div>
    </div>
  </label>
  <input *ngIf="platform === 'desktop'" class="CadastrarOcorrencia_Upload_Input" type="file" id="{{id}}"
    (change)="onFileChange.emit($event)" #fileInput accept="{{accept}}">
</div>
