<div class="Block_Popup_Lateral">
  <div class="Popup_Lateral_Icon">
      <i class="fas fa-check-circle"></i>
  </div>
  <div class="Popup_Lateral_Text">
    <div class="Popup_Lateral_Title">
      Sucesso!
    </div>
    <div class="Popup_Lateral_Subtitle">
        Operação realizada com sucesso!
    </div>
  </div>
</div>
