import { Platform } from '@ionic/angular';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { ImagemArquivoDTO } from 'src/app/shared/services/DTO/Request/ImagemArquivo.DTO';
import { EspacoDTO } from 'src/app/shared/services/DTO/Request/Espaco.DTO';
import { StateService } from '../../services/stateService';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from '../../services/modal.service';
import Swiper from 'swiper';
import { register } from 'swiper/element';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-modal-espaco',
  templateUrl: './modal-espaco.component.html',
  styleUrls: ['./modal-espaco.component.scss'],
  encapsulation: ViewEncapsulation.None, 
})
export class ModalEspacoComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() open: boolean;
  @Input() espaco = null;
  @Output() eventClose = new EventEmitter();
  // @ViewChild(IonSlides) slides: IonSlides;

  @ViewChild("swiper") swiperRef: ElementRef | undefined;
  public swiper: Swiper = null;
  public swiperEl: Swiper = null;
  
  constructor(
    public stateService: StateService,
    private sanitizer: DomSanitizer,
    private modalService: ModalService,
    public platform: Platform,
  ) {
  }
  
  ngOnDestroy() {
    this.modalService.removeHasModalClassFromContainerDiv();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.espaco?.currentValue) {
      this.swiperInit();
      console.log('espaco dentro do modal: ', this.espaco);
    }
  }
    
  ngAfterViewInit(): void {
    // this.swiperInit();
  }
  
  ngOnInit() {
    this.swiperInit();
    window.scrollTo(0,0)
    // this.swiperInit();
  }
  
  swiperInit() {
    this.swiper = new Swiper('.swiper', {
      speed: 400,
      slidesPerView: 1,
      pagination: true
    });

    // if(this.swiper) this.swiper?.update();
    // console.log('espaco modal:', this.espaco);
  }

  sanitizedUrl(src){
    // console.log(src)
    return this.sanitizer.bypassSecurityTrustUrl(src)
  }

  close() {    
    this.eventClose.emit(false);
    this.stateService.exibirFooterHeaderForModal();
    // this.espaco = null;
  }

  nextSlide() {
    this.swiper?.slideNext();
  }

  prevSlide() {
    this.swiper?.slidePrev();
  }

  getSrcFromBase64(base64: string): string {
    return `data:image/png;base64, ${base64}`;
  }

}
