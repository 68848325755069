<section>
  <div class="Lightbox TextAreaWithConfirmationPopup" [ngClass]="{ '__is-open': this.modalAvaliacaoOpen }">
    <div class="Popup Popup_ComunicacaoGenerica">
      <div class="Popup_Header">
        <div class="Popup_Header_Title">Avalie sua experiência usando o site da {{ customerService.getCustomer().nomeApp === 'Apsa' ? 'APSA' : customerService.getCustomer().nomeApp.replace('by Apsa', '')  }}.</div>
      </div>
  
      <div class="Popup_Content">
        <div class="Popup_Content_MsgDefault">
          Sua opinião é muito importante para continuarmos melhorando.
        </div>
      </div>
  
      <div class="FormGroup" [formGroup]="form" [ngClass]="getErrorClass()">

        <div class="FormGroup_Avaliacao">
          <div class="FormGroup_Avaliacao_Icons">
            <div 
              class="Icons_Content_Otimo" 
              (click)="avaliacao(3)" 
            >
              <i 
              class="far fa-grin-stars"  style="color: #6BAB4F"
              >
              </i>
            </div>
            <div
              class="Icons_Content_Regular" 
              (click)="avaliacao(2)" 
            >  
              <i 
              class="far fa-meh" 
              style="color: #A09323"
              >
              </i>
            </div>
            <div
              class="Icons_Content_Ruim" 
              (click)="avaliacao(1)" 
            >
              <i 
              class="far fa-frown-open" 
              style="color: #E74747"
              >
              </i>
            </div>
          </div>
          <div [style]="backgroundIconeSelecionado"
            class="FormGroup_Avaliacao_Button"
            *ngIf="exibirInput"
          >
            <p [style]="corIconeSelecionado">{{textoIconeSelecionado}}</p>
          </div>
        </div>
        <div class="FormGroup_Input" *ngIf="exibirInput">
          <p class="Title_textarea">O que te motivou a fazer essa avaliação?</p>
          <textarea rows="2"
            formControlName="descricao"
            [placeholder]=" iconeSelecionado != 3 ? this.formPlaceholder : 'Deixe seu comentário aqui. (Opcional)'"
          ></textarea>
        </div>
        <ng-container *ngIf="descricaoValue.errors">
          <div class="FormGroup_Erro" *ngIf="descricaoValue.errors.minlength">
          A descrição deve ter no mínimo 20 caracteres.
          </div>
  
          <div class="FormGroup_Erro" *ngIf="descricaoValue.errors.required">
            Este campo é obrigatório.
          </div>
        </ng-container>
      </div>
  
      <div class="Popup_Footer">
        <div class="Popup_Footer_Buttons">
          <button class="Button Button_Primary Button_Outline __is-full" (click)="close()">
            <div class="Button_Content">
              <div class="Button_Content_Label">
                Cancelar
              </div>
            </div>
          </button>
  
          <button class="Button Button_Primary Button_Solid __is-full" (click)="confirmar()">
            <div class="Button_Content">
              <div class="Button_Content_Label">
                Enviar
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>