import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PoliticaPrivacidadeService } from 'src/app/modules/politica-privacidade/services/politica-privacidade.service';

@Component({
  selector: 'app-politica-privacidade-text',
  templateUrl: './politica-privacidade-text.component.html',
  styleUrls: ['./politica-privacidade-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PoliticaPrivacidadeTextComponent implements OnInit {
  public termos: string;

  constructor(private service: PoliticaPrivacidadeService) { }

  ngOnInit() {
    this.getPoliticaPrivacidade();
  }
  
  getPoliticaPrivacidade() {
    this.service.getPoliticaPrivacidade().subscribe(response => 
      this.termos = response[0].txt_PoliticaPrivacidade, err => console.error(err));
  }
}
