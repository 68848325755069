<div *ngIf="gerenteConta"  class="fale-com-consultor" >
  <div class="CollapseLight" [ngClass]="{ '__is-open': isCollapseOpen }">
    
    <div class="Collapse_Header" (click)="toggle()">
      <div class="Collapse_Header_Title">
        Dados <span *ngIf="isGT" >Gestor(a) {{ customerService.getCustomer().nomeApp === 'Apsa' ? 'APSA' : customerService.getCustomer().nomeApp.replace('by Apsa', '')  }}</span> <span *ngIf="!isGT" >Consultor(a) {{ customerService.getCustomer().nomeApp === 'Apsa' ? 'APSA' : customerService.getCustomer().nomeApp.replace('by Apsa', '')  }}</span>
      </div>
      
      <div class="Collapse_Header_Icon">
        <i class="fas fa-caret-down"></i>
      </div>
    </div>

    <div class="Collapse_Content" >

      <div class="avatar-consultor">
        <img *ngIf="gerenteConta.urlFoto; else semFoto" src="{{gerenteConta.urlFoto}}" alt="foto Consultor">
        
        <ng-template #semFoto >
          <i class="far fa-image"></i>
        </ng-template>
      </div>
      
      <div class="texts">
        <!-- <h2 *ngIf="isGT" >
          Gestor(a) APSA
        </h2>
      
        <h2 *ngIf="!isGT" >
          Consultor(a) APSA
        </h2> -->
        
        <p>
          {{gerenteConta.nome}}
        </p>
        
        <div class="consultor-contact" >
          <i class="fas fa-envelope"></i>
          <!-- <a href="mailto:{{gerenteConta.email}}">{{gerenteConta.email}}</a> -->
          <a (click)="copyMessage(gerenteConta.email)" >Copiar e-mail</a>
        </div>
      
        <div *ngIf="gerenteConta.whatsApp" class="consultor-contact" >
          <i class="fab fa-whatsapp"></i>
          <a target="_blank" href="{{gerenteConta.whatsApp.link}}" >{{gerenteConta.whatsApp.numero}}</a>
        </div>
      </div>

    </div>

  </div>
</div>
