import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { UnidadeDTO } from '../../services/DTO/response/Unidade.DTO';

@Component({
  selector: 'app-unidades',
  templateUrl: './unidades.component.html',
  styleUrls: ['./unidades.component.scss'],
})
export class UnidadesComponent implements OnInit, OnChanges {

  @Output() setUnidades = new EventEmitter();
  @Input() selected: UnidadeDTO;
  @Input() units: UnidadeDTO[] = [];
  @Input() foiAbertoPeloDetalheCondomino: boolean;

  public unit: UnidadeDTO = new UnidadeDTO;
  public disabled: boolean = false;

  constructor() { }

  ngOnInit() {

    if (this.selected) {
      this.unit = this.selected;
      this.disabled = true;
    }

  }

  ngOnChanges() {
    if (this.selected) {
      this.verificaSeFoiAbertoPeloDetalheCondomino();
    }
  }

  selectUnit(unit: UnidadeDTO) {  
    this.unit = unit;
    this.setUnidades.emit(unit);
  }


  verificaSeFoiAbertoPeloDetalheCondomino() {
    this.unit = this.selected;
    this.setUnidades.emit(this.unit);
  }


}
