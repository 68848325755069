<!-- <div class="AcessibilidadeComponent">
  <div class="FormGroup FormGroup_TamanhoFonte" *ngIf="(customer$ | async).podeTrocarTemaDeCores">
    <div class="FormGroup_Label">Tamanho da fonte</div>
    <div class="FormGroup_Input">
      <ion-range (ionChange)="selecionarFonte($event);" class="Range" min="1" max="3" step="1" snaps="true" ticks="true" pin="true">
        <div class="Range_Icon" slot="start">a</div>
        <div class="Range_Icon" slot="end">A</div>
      </ion-range>
    </div>
  </div>
  <div class="FormGroup FormGroup_PlanoFundo" *ngIf="(customer$ | async).podeTrocarTemaDeCores">
    <div class="FormGroup_Label">Plano de fundo</div>
    <div class="FormGroup_Input">
      <div class="ListSelect">
        <div class="ListSelect_Content __is-full-list">
          <div class="ListSelect_Content_Item Content_Item_PlanoFundo1"
          [ngClass]="{'__is-active': (temaAcessibilidade === 1) }">
            <a (click)="selecionarTema(0)">
              <div class="Content_Item_Icon">
                <i class="fas fa-check"></i>
              </div>
              <div class="Content_Item_Label">Clássico</div>
            </a>
          </div>
          <div class="ListSelect_Content_Item Content_Item_PlanoFundo2"
          [ngClass]="{'__is-active': (temaAcessibilidade === 2) }">
            <a (click)="selecionarTema(1)">
              <div class="Content_Item_Icon">
                <i class="fas fa-check"></i>
              </div>
              <div class="Content_Item_Label">Claro</div>
            </a>
          </div>
          <div class="ListSelect_Content_Item Content_Item_PlanoFundo3"
          [ngClass]="{'__is-active': (temaAcessibilidade === 3) }">
            <a (click)="selecionarTema(2)">
              <div class="Content_Item_Icon">
                <i class="fas fa-check"></i>
              </div>
              <div class="Content_Item_Label">Escuro</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="AcessibilidadeComponent">
  <div class="FormGroup FormGroup_TamanhoFonte" *ngIf="(customer$ | async).podeTrocarTemaDeCores">
    <div class="FormGroup_Label">Tamanho da fonte</div>
    <div class="FormGroup_Input">
      <ion-range  [(ngModel)]="fonteAcessibilidade" (ionChange)="selecionarFonte($event);" class="Range" min="1" max="3" step="1" snaps="true" ticks="true" pin="true">
        <div class="Range_Icon" slot="start">a</div>
        <div class="Range_Icon" slot="end">A</div>
      </ion-range>
    </div>
  </div>

  <div class="FormGroup FormGroup_PlanoFundo" *ngIf="(customer$ | async).podeTrocarTemaDeCores">
    <div class="FormGroup_Label">Plano de fundo</div>
    <div class="FormGroup_Input">
      <div class="ListSelect">
        <div class="ListSelect_Content __is-full-list">
          <div class="ListSelect_Content_Item Content_Item_PlanoFundo1"
          [ngClass]="{'__is-active': (temaAcessibilidade === 1) }">
            <a (click)="selecionarTema(0)">
              <div class="Content_Item_Icon">
                <i class="fas fa-check"></i>
              </div>
              <div class="Content_Item_Label">Clássico</div>
            </a>
          </div>
          <div class="ListSelect_Content_Item Content_Item_PlanoFundo2"
          [ngClass]="{'__is-active': (temaAcessibilidade === 2) }">
            <a (click)="selecionarTema(1)">
              <div class="Content_Item_Icon">
                <i class="fas fa-check"></i>
              </div>
              <div class="Content_Item_Label">Claro</div>
            </a>
          </div>
          <div class="ListSelect_Content_Item Content_Item_PlanoFundo3"
          [ngClass]="{'__is-active': (temaAcessibilidade === 3) }">
            <a (click)="selecionarTema(2)">
              <div class="Content_Item_Icon">
                <i class="fas fa-check"></i>
              </div>
              <div class="Content_Item_Label">Escuro</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="FormGroup FormGroup_PlanoFundo" *ngIf="!(customer$ | async).podeTrocarTemaDeCores">
    <div class="FormGroup_Label">Tema da Interface</div>
    <div class="FormGroup_Input">
      <div class="ListSelect">
        <div class="Content_Item_Icon_Tema">
          <!-- icones dos temas -->
          <div [ngClass]="{'__is-active-tema': (temaAcessibilidade === 4) }">
            <a (click)="selecionarTema(3)">
              <div class="Icon_Tema">
                <img src="../../../assets/icones-temas/tema01.svg" />
              </div>
            </a>
          </div>
          <div [ngClass]="{'__is-active-tema': (temaAcessibilidade === 5) }">
            <a (click)="selecionarTema(4)">
              <div class="Icon_Tema">
                <img src="../../../assets/icones-temas/tema02.svg" />
              </div>
            </a>
          </div>
          <div [ngClass]="{'__is-active-tema': (temaAcessibilidade === 6) }">
            <a (click)="selecionarTema(5)">
              <div class="Icon_Tema">
                <img src="../../../assets/icones-temas/tema03.svg" />
              </div>
            </a>
          </div>

          <div [ngClass]="{'__is-active-tema': (temaAcessibilidade === 7) }">
            <a (click)="selecionarTema(6)">
              <div class="Icon_Tema">
                <img src="../../../assets/icones-temas/tema04.svg" />
              </div>
            </a>
          </div>

          <div [ngClass]="{'__is-active-tema': (temaAcessibilidade === 8) }">
            <a (click)="selecionarTema(7)">
              <div class="Icon_Tema">
                <img src="../../../assets/icones-temas/tema05.svg" />
              </div>
            </a>
          </div>

          <div [ngClass]="{'__is-active-tema': (temaAcessibilidade === 9) }">
            <a (click)="selecionarTema(8)">
              <div class="Icon_Tema">
                <img src="../../../assets/icones-temas/tema06.svg" />
              </div>
            </a>
          </div>
          
          <!-- segunda linha -->
          <div [ngClass]="{'__is-active-tema': (temaAcessibilidade === 10) }">
            <a (click)="selecionarTema(9)">
              <div class="Icon_Tema">
                <img src="../../../assets/icones-temas/tema07.svg" />
              </div>
            </a>
          </div>
          <div [ngClass]="{'__is-active-tema': (temaAcessibilidade === 11) }">
            <a (click)="selecionarTema(10)">
              <div class="Icon_Tema">
                <img src="../../../assets/icones-temas/tema08.svg" />
              </div>
            </a>
          </div>
          <div [ngClass]="{'__is-active-tema': (temaAcessibilidade === 12) }">
            <a (click)="selecionarTema(11)">
              <div class="Icon_Tema">
                <img src="../../../assets/icones-temas/tema09.svg" />
              </div>
            </a>
          </div>

          <div [ngClass]="{'__is-active-tema': (temaAcessibilidade === 13) }">
            <a (click)="selecionarTema(12)">
              <div class="Icon_Tema">
                <img src="../../../assets/icones-temas/tema10.svg" />
              </div>
            </a>
          </div>

          <div [ngClass]="{'__is-active-tema': (temaAcessibilidade === 14) }">
            <a (click)="selecionarTema(13)">
              <div class="Icon_Tema">
                <img src="../../../assets/icones-temas/tema11.svg" />
              </div>
            </a>
          </div>

          <div [ngClass]="{'__is-active-tema': (temaAcessibilidade === 15) }">
            <a (click)="selecionarTema(14)">
              <div class="Icon_Tema">
                <img src="../../../assets/icones-temas/tema12.svg" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="Content_Button_Tema">
      <button class="Button Button_Primary Button_Solid" (click)="selecionarTema(0)">
        <div class="Button_Content">
          <div class="Button_Content_Label">Definir tema padrão</div>
        </div>
      </button>
    </div>
  </div>
</div>