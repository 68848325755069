import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StateService } from '../../services/stateService';
import { LightBoxesService } from '../lightboxes';

@Component({
  selector: 'app-modal-politicas-condicoes',
  templateUrl: './modal-politicas-condicoes.component.html',
  styleUrls: ['./modal-politicas-condicoes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalPoliticasCondicoesComponent implements OnInit {

  @Input() open: boolean;
  @Input() salvarNoLocalStorage: boolean;//esse input traz a informação de o termo de aceite está após a tela de splash ou após a tela de login
  @Output() eventClose = new EventEmitter();

  public termosCondicoes: any;
  public step: number = 1;

  public form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, public stateService: StateService, private lightboxService: LightBoxesService) { }

  ngOnInit() {
    this.createForm();
    if(!this.salvarNoLocalStorage) this.getTermosCondicoes();
  }

  createForm() {
    this.form = this.formBuilder.group({
      aceiteTermo: [false, Validators.requiredTrue],
    })
  }

  getTermosCondicoes() {
    this.stateService.usuarioAceitouTermosCondicoes().subscribe(
      response => {
        this.termosCondicoes = response;
      },
      error => {
        this.termosCondicoes = null;
        console.log(error.error)
      }
    )
  }

  goToStep2() {
    this.step++;
  }

  goToExternalLink(url: string): void {
    this.stateService.openExternalLink(url);
  }

  aceitarTermosCondicoes(event: MouseEvent) {
    (event.target as HTMLButtonElement).disabled = true;
    this.eventClose.emit(false)

    if(this.salvarNoLocalStorage){
      this.stateService.salvarTermosMobile();
    }else{
      
      this.stateService.cadastrarUsuarioAceiteTermo().subscribe(
        response => {},
        error => {
          this.lightboxService.open('custom-generico', {
            title: 'Atenção',
            text: error
          }, 10000)
        }
      )
    }
  }

}
