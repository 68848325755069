import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.None, 
})

export class SearchInputComponent implements OnInit {
  public form: UntypedFormGroup;
  public isFiltered: boolean = false;

  @Output() onSubmit = new EventEmitter<string>();
  @Output() onClear = new EventEmitter();
  @Input('placeholder') placeholder: string;

  constructor(private fb: UntypedFormBuilder) { }

  filter() {
    this.onSubmit.emit(this.form.get('searchTerm').value.toString().trim());
    this.isFiltered = true;
  }

  unFilter() {
    if(this.isFiltered) {
      this.form.get('searchTerm').setValue('');
      this.isFiltered = false;
      this.onClear.emit();
    }
  }

  ngOnInit() {
      this.createForm();
  }
  
  createForm() {
    this.form = this.fb.group({
      searchTerm: ['', Validators.compose([Validators.required, Validators.minLength(3)])]
    });
  }
}
