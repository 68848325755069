import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { StateService } from '../../services/stateService';
import { AvaliacaoLojaComponent } from '../avaliacao-loja-app/avaliacao.component';
import { LightBoxesService } from '../lightboxes';

@Component({
  selector: 'modal-avaliar-app',
  templateUrl: './modal-avaliar-app.component.html',
  styleUrls: ['./modal-avaliar-app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalAvaliarApp implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() modalAvaliacaoOpen : boolean;
  @Input() formPlaceholder: string;
  @Output() closeModalAvaliacao = new EventEmitter()
  @Output() onSubmit = new EventEmitter()
  
  public isSubmitted: boolean = false;
  public exibirInput: boolean = false;
  public iconeSelecionado: number = 0
  public textoIconeSelecionado: string = ''
  public corIconeSelecionado: string =''
  public backgroundIconeSelecionado: string =''
  
  constructor(private avaliacaoLoja: AvaliacaoLojaComponent,) { }

  ngOnInit(): void {
  }

  returnPlaceHolder():string{
    return 'Envie a sua sugestão ou melhoria aqui...'
  }

  close(){
    this.reset()
    this.closeModalAvaliacao.emit()
    this.exibirInput = false
    this.iconeSelecionado = 0
  }

  reset() {
    this.form.reset();
    this.isSubmitted = false;
  }

  confirmar(): void {
    this.isSubmitted = true;
    if(this.form.valid) {
      this.exibirInput = false
      this.iconeSelecionado = 0
      this.onSubmit.emit();
      this.close();
    }
  }

  get descricaoValue() {
    return this.form.get('descricao');
  }

  get notaValue() {
    return this.form.get('nota')
  }

  getErrorClass() {
    return {
      '__is-error': this.isSubmitted && this.form.get('descricao').invalid
    };
  }

  avaliacao(nota: number) {
    this.form.get('nota').setValue(nota)
    this.exibirInput = true
    this.iconeSelecionado = nota

    if(this.iconeSelecionado == 3) {  
     this.descricaoValue.clearValidators();
    } else {
      this.descricaoValue.setValidators([Validators.required, Validators.minLength(5)])
    }

    if(nota == 3) {
      this.textoIconeSelecionado = 'ótimo';
      this.corIconeSelecionado = 'color : #6BAB4F';
      this.backgroundIconeSelecionado = 'background : rgba(210,234,227,1)'
    }else if(nota == 2) {
      this.textoIconeSelecionado = 'bom';
      this.corIconeSelecionado = 'color : #A09323';
      this.backgroundIconeSelecionado = 'background : rgba(228,207,30,0.3)'
    } else {
      this.textoIconeSelecionado = 'ruim';
      this.corIconeSelecionado = 'color : #E74747';
      this.backgroundIconeSelecionado = 'background : rgba(231,71,71,0.15)'
    }

    this.descricaoValue.updateValueAndValidity()
  }
}
