import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IFilterFormState {
  dataInicial: string;
  dataFinal: string;
  descricao: string;
  tipoDeDocumento: number[];
  status: number[];
}

@Injectable({ providedIn: 'root' })
export class EntregaExpressaState {

  public filter = new BehaviorSubject<IFilterFormState | null>(null);
  public filterDataInicialFieldRef = new BehaviorSubject<any | null>(null);
  public filterDataFinalFieldRef = new BehaviorSubject<any | null>(null);

  setFilter(filter: IFilterFormState) {
    this.filter.next(filter);
  }

  setFilterDataInicialFieldReference(ref: any) {
    this.filterDataFinalFieldRef.next(ref);
  }

  setFilterDataFinalFieldReference(ref: any) {
    this.filterDataInicialFieldRef.next(ref);
  }

  getFilterDataInicialFieldReferenceValue(): any | null {
    return this.filterDataInicialFieldRef.value;
  }

  getFilterDataFinalFieldReferenceValue(): any | null {
    return this.filterDataFinalFieldRef.value;
  }

  getFilterValue(): IFilterFormState | null {
    return this.filter.value;
  }

  clearFilter() {
    this.setFilter(null);
  }

  clearHTMLNodeReferences() {
    this.setFilterDataInicialFieldReference(null);
    this.setFilterDataFinalFieldReference(null);
  }
}