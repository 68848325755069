<div class="ListActions MobileShortcutsWithListDesign">
  <ng-container *ngIf="!(this.possuiPerfilLocatario || this.possuiPerfilProprietario)">
      <div
        class="ListActions_Item"
        *ngIf="((customer$ | async).comodidadesParaVoce.exibir) && this.stateService.possuiAcessoFuncionalidadeGeral(828)"
      >
        <a (click)='handleClickComodidades()'>
          <div class="ListActions_Item_Ico">
            <i class="apsa-icon-outline-beneficios-pra-voce"></i>
          </div>
    
          <div class="ListActions_Item_Label">
            <strong>Comodidades para você!</strong>
          </div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>

      <div
        class="ListActions_Item"
        *ngIf="stateService.possuiAcessoFuncionalidadeGeral(853)"
      >
        <a routerLink="/minhas-solicitacoes">
          <div class="ListActions_Item_Ico">
            <i class="fas fa-tasks"></i>
          </div>
    
          <div class="ListActions_Item_Label">
            <strong>Minhas solicitações</strong>
          </div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>

      <div *ngIf="this.possuiPerfilSindico" class="ListActions_Item __is-new">
        <a routerLink="/seguros" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="far fa-building"></i>
        
            <div class="overlayed-icon">
              <i class="fas fa-heart"></i>
            </div>
          </div>

          <div class="ListActions_Item_Label">Seguros obrigatórios</div>

          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>

      <div *ngIf="
        stateService.possuiPerfilSindico() || 
        stateService.possuiPerfilConselheiro() || 
        stateService.possuiPerfilSubSindico() || 
        stateService.possuiPerfilProprietario() || 
        stateService.possuiPerfilLocatario() ||
        stateService.possuiPerfilCondomino()" 
        class="ListActions_Item __is-new"
      >
        <a (click)="stateService.redirectAlugarVenderPorPerfil()">
          <div class="ListActions_Item_Ico">
            <i class="far fa-building"></i>
          </div>

          <div class="ListActions_Item_Label">Quero alugar/vender</div>

          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
      
      <div *ngIf="this.possuiPerfilSindico" class="ListActions_Item __is-new">
        <a (click)="stateService.openExternalLink('https://www.revistasindico.com.br/guia')">
          <div class="ListActions_Item_Ico">
            <i class="fas fa-tools"></i>
          </div>

          <div class="ListActions_Item_Label">Fornecedores</div>

          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>

      <div
        class="ListActions_Item"
        *ngIf="ehGestaoTotal && possuiPermissaoAcesso(292)" 
      >
        <a routerLink="/achados-perdidos">
          <div class="ListActions_Item_Ico">
            <i class="fas fa-box-open"></i>
          </div>
    
          <div class="ListActions_Item_Label">
            Achados e perdidos
          </div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div
        *ngIf="this.stateService.permitirEntregaExpressa() != 0 && possuiPermissaoAcessoValidacaoCentralAtendimento(842)"
        class="ListActions_Item"
      >
        <a routerLink="/chame-moto" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="apsa-icon-solid-motorcycle"></i>
          </div>
    
          <div class="ListActions_Item_Label">Chame a Moto</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(287)" class=" ListActions_Item">
        <a routerLink="/checklist" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="apsa-icon-solid-check"></i>
          </div>
    
          <div class="ListActions_Item_Label">Checklist</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(293)" class="ListActions_Item">
        <a routerLink="/colaboradores" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="apsa-icon-solid-colaboradores"></i>
          </div>
    
          <div class="ListActions_Item_Label">Colaboradores</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>

      
      <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(305)" class="ListActions_Item">
        <a routerLink="/courrier" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="fas fa-business-time"></i>
          </div>

          <div class="ListActions_Item_Label">Courrier</div>

          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(822)" class="ListActions_Item">
        <a routerLink="/reserva-configuracao" routerLinkActive="active">
          <div class="ListActions_Item_Ico __is-small">
            <i class="fas fa-check"></i>
          </div>
    
          <div class="ListActions_Item_Label">Configuração de Reservas</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>

      <div
        *ngIf="stateService.permitirEntregaExpressa() === 0 && stateService.possuiAcessoFuncionalidade(206)"
        class="ListActions_Item"
      >
        <a routerLink="/calendario-visitas" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="far fa-calendar-check"></i>
          </div>

          <div class="ListActions_Item_Label">Calendário de visitas</div>

          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="possuiPermissaoAcesso(211)" class="ListActions_Item">
        <a routerLink="/condomino" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="apsa-icon-solid-building"></i>
          </div>
    
          <div class="ListActions_Item_Label">Condôminos</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>

      <div *ngIf="validaExibicaoContrato()" class="ListActions_Item">
        <a routerLink="/contratos" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="fas fa-file-signature" style="color: #ff6e0f"></i>
          </div>
    
          <div class="ListActions_Item_Label">Contratos</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>

      <div class="ListActions_Item" *ngIf="possuiPermissaoAcesso(214)">
        <a routerLink="/acompanhamento-orcamentario" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="fas fa-database"></i>
          </div>

          <div class="ListActions_Item_Label">Acompanhamento orçamentário</div>

          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="possuiPermissaoAcesso(219) || possuiPermissaoAcesso(220)" class="ListActions_Item">
        <a routerLink="/cotas" routerLinkActive="active">
          <div class="ListActions_Item_Ico __is-tiny">
            <i class="apsa-icon-solid apsa-icon-solid-cotas"></i>
          </div>
    
          <div
            *ngIf="this.stateService.possuiApenasPerfilCondomino(); then segundaVia else cotas"
          >
          </div>
    
          <ng-template #segundaVia>
            <div class="ListActions_Item_Label">2ª via de boletos</div>
          </ng-template>
    
          <ng-template #cotas>
            <div class="ListActions_Item_Label">Cotas</div>
          </ng-template>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
      
      <div class="ListActions_Item" *ngIf="possuiPermissaoAcesso(885)">
        <a routerLink="/cotas-inadimplentes" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="fas fa-tasks" style="color: #ff6e0f"></i>
          </div>

          <div class="ListActions_Item_Label">Relatório de Inadimplentes</div>

          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>

            
      <div class="ListActions_Item" *ngIf="possuiPermissaoAcesso(226)">
        <a routerLink="/receitas-previstas" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="fas fa-tasks" style="color: #ff6e0f"></i>
          </div>

          <div class="ListActions_Item_Label">Receitas previstas</div>

          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="possuiPermissaoAcesso(205)" class="ListActions_Item">
        <a routerLink="/dados-condominio" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="apsa-icon-solid-sobre-condominio-solid"></i>
          </div>
    
          <div class="ListActions_Item_Label">Dados do Condomínio</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="possuiPermissaoAcesso(811)" class="ListActions_Item">
        <a routerLink="/enquetes" routerLinkActive="active">
          <div class="ListActions_Item_Ico __is-small">
            <i class="apsa-icon-solid-enquete"></i>
          </div>
    
          <div class="ListActions_Item_Label">Enquetes</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="possuiPermissaoAcesso(306)" class="ListActions_Item">
        <a routerLink="/envio-documento" routerLinkActive="active">
          <div class="ListActions_Item_Ico __is-tiny">
            <i class="apsa-icon-solid-envio-documentos"></i>
          </div>
    
          <div class="ListActions_Item_Label">Envio de Documentos</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>

      <div *ngIf="stateService.pessoaPossuiAcessoFuncionalidade(856)" class="ListActions_Item">
        <a routerLink="/processamento-de-documentos">
          <div class="ListActions_Item_Ico __is-tiny">
            <i class="apsa-icon-solid-envio-documentos"></i>
          </div>
    
          <div class="ListActions_Item_Label">
            Documentos não processados
          </div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="possuiPermissaoAcesso(218)" class="ListActions_Item">
        <a routerLink="/extrato/sindico" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="apsa-icon-solid-extrato"></i>
          </div>
    
          <div class="ListActions_Item_Label">Extrato</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(819)" class="ListActions_Item">
        <a routerLink="/cadastro-de-espacos" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="apsa-icon-solid-espacos-solid"></i>
          </div>
    
          <div class="ListActions_Item_Label">Espaços</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(816)" class="ListActions_Item">
        <a routerLink="/ocorrencia" routerLinkActive="active">
          <div class="ListActions_Item_Ico __is-small">
            <i class="apsa-icon-solid-circle-talk"></i>
          </div>
    
          <div
            *ngIf="possuiPerfilGestorSindico; else faleComOsindico"
            class="ListActions_Item_Label"
          >
            Ocorrências
          </div>
    
          <ng-template #faleComOsindico>
            <div class="ListActions_Item_Label">
              Fale com o Síndico
            </div>
          </ng-template>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="possuiPermissaoAcesso(227)" class="ListActions_Item">
        <a routerLink="/funcionario" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="apsa-icon-solid-user"></i>
          </div>
    
          <div class="ListActions_Item_Label">Funcionários</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>

      <div *ngIf="possuiPermissaoAcesso(243)" class="ListActions_Item">
        <a routerLink="/lancamento-proventos-descontos" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="fas fa-file-invoice-dollar"></i>
          </div>

          <div class="ListActions_Item_Label">
            Lançamento de descontos e proventos
          </div>

          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>

      <div class="ListActions_Item" *ngIf="possuiPermissaoAcesso(276)">
        <a routerLink="/quadro-horarios" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="fas fa-business-time"></i>
          </div>

          <div class="ListActions_Item_Label">Jornada de Trabalho</div>
          
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(295)" class="ListActions_Item">
        <a routerLink="/disponibilidade" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="apsa-icon-solid-square-check"></i>
          </div>
    
          <div class="ListActions_Item_Label">Gerenciar Disponibilidade</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div class="ListActions_Item" *ngIf="ehGestaoTotal && possuiPermissaoAcesso(291)">
        <a routerLink="/muralGT" routerLinkActive="active">
          <div class="ListActions_Item_Ico __not-colorful __is-small">
            <i class="apsa-icon-solid-mural Card_Icon_SolidAsColorfull"></i>
          </div>
    
          <div class="ListActions_Item_Label">Mural</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div class="ListActions_Item" *ngIf="!ehGestaoTotal && possuiPermissaoAcesso(814)">
        <a routerLink="/mural" routerLinkActive="active">
          <div class="ListActions_Item_Ico __not-colorful __is-small">
            <i class="apsa-icon-solid-mural Card_Icon_SolidAsColorfull"></i>
          </div>
    
          <div class="ListActions_Item_Label">Mural</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="this.stateService.possuiFuncionalidadeCondominio(267)" class="ListActions_Item">
        <a routerLink="/funcionario/meus-dados" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="apsa-icon-solid-termos-uso-solid"></i>
          </div>
    
          <div class="ListActions_Item_Label">Meus Dados</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="possuiPermissaoAcesso(810)" class="ListActions_Item">
        <a routerLink="/documento" routerLinkActive="active">
          <div class="ListActions_Item_Ico __is-tiny">
            <i class="apsa-icon-solid-folder"></i>
          </div>
    
          <div class="ListActions_Item_Label">Normas e Documentos</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="possuiPermissaoAcesso(283)" class="ListActions_Item">
        <a routerLink="/prestacao-conta-digital" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="apsa-icon-outline-prestacao-de-contas-outline"></i>
          </div>
    
          <div class="ListActions_Item_Label">Pasta de prestação de contas digital</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>

      <div 
        class="ListActions_Item"
        *ngIf="stateService.possuiPerfilCentralAtendimento() || stateService.possuiPerfilSindico() || possuiPermissaoAcesso(881)"
      >
        <a routerLink="/pasta-dp" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="fas fa-folder-open" style="color: #ff6e0f"></i>
          </div>
    
          <div class="ListActions_Item_Label">Documentos Fiscais</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>

      <div class="ListActions_Item"  *ngIf="ehGestaoTotal && possuiPermissaoAcesso(303)">
        <a routerLink="/inventario" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="fas fa-tasks" style="color: #ff6e0f"></i>
          </div>
    
          <div class="ListActions_Item_Label">Relatório de Inventário</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>

      <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(299)" class="ListActions_Item">
        <a routerLink="/relatorio-ocorrencias" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="fas fa-tasks" style="color: #ff6e0f"></i>
          </div>
    
          <div class="ListActions_Item_Label">Relatório de Ocorrências</div>
        
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>

      <div class="ListActions_Item" *ngIf="possuiPermissaoAcesso(215) || possuiPermissaoAcesso(216) || possuiPermissaoAcesso(217)">
        <a routerLink="/prestacao-contas" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="fas fa-coins" style="color: #ff6e0f"></i>
          </div>
    
          <div class="ListActions_Item_Label">Prestação de contas</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(285)" class="ListActions_Item">
        <a routerLink="/ocorrenciaGT" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="apsa-icon-solid-circle-talk"></i>
          </div>
    
          <div
            *ngIf="possuiPerfilGestorSindico; else faleComOsindico"
            class="ListActions_Item_Label"
          >
            Ocorrências
          </div>
    
          <ng-template #faleComOsindico>
            <div class="ListActions_Item_Label">Fale com o Síndico</div>
          </ng-template>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="this.ehContaPool && this.stateService.possuiAcessoFuncionalidadeGeral(835)" class="ListActions_Item">
        <a routerLink="/quitacao-anual" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="apsa-icon-solid-solid-informe-quitacao"></i>
          </div>
    
          <div class="ListActions_Item_Label">Quitação Anual</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div
        *ngIf="this.ehGestaoTotal && (possuiPermissaoAcesso(286) ||possuiPermissaoAcesso(294)||possuiPermissaoAcesso(304))"
        class="ListActions_Item"
      >
        <a routerLink="/reserva" routerLinkActive="active">
          <div class="ListActions_Item_Ico __is-small">
            <i class="apsa-icon-solid-square-check"></i>
          </div>
    
          <div class="ListActions_Item_Label">Reservas</div>
    
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
    
      <div *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(823)" class="ListActions_Item">
        <a routerLink="/reserva-conv" routerLinkActive="active">
          <div class="ListActions_Item_Ico">
            <i class="apsa-icon-solid-square-check"></i>
          </div>
    
          <div class="ListActions_Item_Label">Reservas</div>
        
          <div class="ListActions_Item_Action">
            <i class="fas fa-caret-right"></i>
          </div>
        </a>
      </div>
  </ng-container>

  <ng-container *ngIf="this.possuiPerfilLocatario">
    <div
      class="ListActions_Item __is-new "
      *ngIf="this.stateService.possuiPerfilCondomino()"
    >
      <a routerLink="/cotas/visualizacaoIndividual">
        <div class="ListActions_Item_Ico">
          <img
            src="../../../../assets/icon/segunda-via-boletos-primary.svg"
            alt="logo segunda via de boletos"
          />
        </div>

        <div class="ListActions_Item_Label">
          <strong>2ª via de boletos</strong>
        </div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>
    <div
      class="ListActions_Item"
      *ngIf=" (customer$ | async).comodidadesParaVoce.exibir && this.stateService.possuiAcessoFuncionalidadeGeral(828)"
    >
      <a (click)="handleClickComodidades()">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-outline-beneficios-pra-voce"></i>
        </div>

        <div class="ListActions_Item_Label">
          <strong>Comodidades para você!</strong>
        </div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      class="ListActions_Item"
      *ngIf="stateService.possuiAcessoFuncionalidadeGeral(853)"
    >
      <a routerLink="/minhas-solicitacoes">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-tasks"></i>
        </div>

        <div class="ListActions_Item_Label">
          <strong>Minhas solicitações</strong>
        </div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div *ngIf="this.possuiPerfilSindico" class="ListActions_Item __is-new">
      <a routerLink="/seguros" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="far fa-building"></i>

          <div class="overlayed-icon">
            <i class="fas fa-heart"></i>
          </div>
        </div>

        <div class="ListActions_Item_Label">Seguros obrigatórios</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div *ngIf="this.possuiPerfilSindico" class="ListActions_Item __is-new">
      <a
        (click)="stateService.openExternalLink('https://imoveis.apsa.com.br/')"
      >
        <div class="ListActions_Item_Ico">
          <i class="far fa-building"></i>
        </div>

        <div class="ListActions_Item_Label">Aluguel Compra e Venda</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div *ngIf="this.possuiPerfilSindico" class="ListActions_Item __is-new">
      <a
        (click)="
          stateService.openExternalLink(
            'https://www.revistasindico.com.br/guia'
          )
        "
      >
        <div class="ListActions_Item_Ico">
          <i class="fas fa-tools"></i>
        </div>

        <div class="ListActions_Item_Label">Fornecedores</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      class="ListActions_Item"
      *ngIf="ehGestaoTotal && possuiPermissaoAcesso(292)"
    >
      <a routerLink="/achados-perdidos">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-box-open"></i>
        </div>

        <div class="ListActions_Item_Label">Achados e perdidos</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="
        this.stateService.permitirEntregaExpressa() != 0 &&
        possuiPermissaoAcessoValidacaoCentralAtendimento(842)
      "
      class="ListActions_Item"
    >
      <a routerLink="/chame-moto" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-solid-motorcycle"></i>
        </div>

        <div class="ListActions_Item_Label">Chame a Moto</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(287)"
      class="ListActions_Item"
    >
      <a routerLink="/checklist" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-solid-check"></i>
        </div>

        <div class="ListActions_Item_Label">Checklist</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(293)"
      class="ListActions_Item"
    >
      <a routerLink="/colaboradores" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-solid-colaboradores"></i>
        </div>

        <div class="ListActions_Item_Label">Colaboradores</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(305)"
      class="ListActions_Item"
    >
      <a routerLink="/courrier" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-business-time"></i>
        </div>

        <div class="ListActions_Item_Label">Courrier</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(822)"
      class="ListActions_Item"
    >
      <a routerLink="/reserva-configuracao" routerLinkActive="active">
        <div class="ListActions_Item_Ico __is-small">
          <i class="fas fa-check"></i>
        </div>

        <div class="ListActions_Item_Label">Configuração de Reservas</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="
        stateService.permitirEntregaExpressa() === 0 &&
        stateService.possuiAcessoFuncionalidade(206)
      "
      class="ListActions_Item"
    >
      <a routerLink="/calendario-visitas" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="far fa-calendar-check"></i>
        </div>

        <div class="ListActions_Item_Label">Calendário de visitas</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div *ngIf="possuiPermissaoAcesso(211)" class="ListActions_Item">
      <a routerLink="/condomino" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-solid-building"></i>
        </div>

        <div class="ListActions_Item_Label">Condôminos</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div *ngIf="validaExibicaoContrato()" class="ListActions_Item">
      <a routerLink="/contratos" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-file-signature" style="color: #ff6e0f"></i>
        </div>

        <div class="ListActions_Item_Label">Contratos</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div class="ListActions_Item" *ngIf="possuiPermissaoAcesso(214)">
      <a routerLink="/acompanhamento-orcamentario" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-database"></i>
        </div>

        <div class="ListActions_Item_Label">Acompanhamento orçamentário</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="possuiPermissaoAcesso(219) || possuiPermissaoAcesso(220)"
      class="ListActions_Item"
    >
      <a routerLink="/cotas" routerLinkActive="active">
        <div class="ListActions_Item_Ico __is-tiny">
          <i class="apsa-icon-solid apsa-icon-solid-cotas"></i>
        </div>

        <div
          *ngIf="
            this.stateService.possuiApenasPerfilCondomino();
            then segundaVia;
            else cotas
          "
        ></div>

        <ng-template #segundaVia>
          <div class="ListActions_Item_Label">2ª via de boletos</div>
        </ng-template>

        <ng-template #cotas>
          <div class="ListActions_Item_Label">Cotas</div>
        </ng-template>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div class="ListActions_Item" *ngIf="possuiPermissaoAcesso(885)">
      <a routerLink="/cotas-inadimplentes" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-tasks" style="color: #ff6e0f"></i>
        </div>

        <div class="ListActions_Item_Label">Relatório de Inadimplentes</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div class="ListActions_Item" *ngIf="possuiPermissaoAcesso(226)">
      <a routerLink="/receitas-previstas" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-tasks" style="color: #ff6e0f"></i>
        </div>

        <div class="ListActions_Item_Label">Receitas previstas</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div *ngIf="possuiPermissaoAcesso(205)" class="ListActions_Item">
      <a routerLink="/dados-condominio" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-solid-sobre-condominio-solid"></i>
        </div>

        <div class="ListActions_Item_Label">Dados do Condomínio</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div *ngIf="possuiPermissaoAcesso(811)" class="ListActions_Item">
      <a routerLink="/enquetes" routerLinkActive="active">
        <div class="ListActions_Item_Ico __is-small">
          <i class="apsa-icon-solid-enquete"></i>
        </div>

        <div class="ListActions_Item_Label">Enquetes</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div *ngIf="possuiPermissaoAcesso(306)" class="ListActions_Item">
      <a routerLink="/envio-documento" routerLinkActive="active">
        <div class="ListActions_Item_Ico __is-tiny">
          <i class="apsa-icon-solid-envio-documentos"></i>
        </div>

        <div class="ListActions_Item_Label">Envio de Documentos</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="stateService.pessoaPossuiAcessoFuncionalidade(856)"
      class="ListActions_Item"
    >
      <a routerLink="/processamento-de-documentos">
        <div class="ListActions_Item_Ico __is-tiny">
          <i class="apsa-icon-solid-envio-documentos"></i>
        </div>

        <div class="ListActions_Item_Label">Documentos não processados</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div *ngIf="possuiPermissaoAcesso(218)" class="ListActions_Item">
      <a routerLink="/extrato/sindico" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-solid-extrato"></i>
        </div>

        <div class="ListActions_Item_Label">Extrato</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(819)"
      class="ListActions_Item"
    >
      <a routerLink="/cadastro-de-espacos" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-solid-espacos-solid"></i>
        </div>

        <div class="ListActions_Item_Label">Espaços</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(816)"
      class="ListActions_Item"
    >
      <a routerLink="/ocorrencia" routerLinkActive="active">
        <div class="ListActions_Item_Ico __is-small">
          <i class="apsa-icon-solid-circle-talk"></i>
        </div>

        <div
          *ngIf="possuiPerfilGestorSindico; else faleComOsindico"
          class="ListActions_Item_Label"
        >
          Ocorrências
        </div>

        <ng-template #faleComOsindico>
          <div class="ListActions_Item_Label">Fale com o Síndico</div>
        </ng-template>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div *ngIf="possuiPermissaoAcesso(227)" class="ListActions_Item">
      <a routerLink="/funcionario" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-solid-user"></i>
        </div>

        <div class="ListActions_Item_Label">Funcionários</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div *ngIf="possuiPermissaoAcesso(243)" class="ListActions_Item">
      <a routerLink="/lancamento-proventos-descontos" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-file-invoice-dollar"></i>
        </div>

        <div class="ListActions_Item_Label">
          Lançamento de descontos e proventos
        </div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div class="ListActions_Item" *ngIf="possuiPermissaoAcesso(276)">
      <a routerLink="/quadro-horarios" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-business-time"></i>
        </div>

        <div class="ListActions_Item_Label">Jornada de Trabalho</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(295)"
      class="ListActions_Item"
    >
      <a routerLink="/disponibilidade" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-solid-square-check"></i>
        </div>

        <div class="ListActions_Item_Label">Gerenciar Disponibilidade</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      class="ListActions_Item"
      *ngIf="ehGestaoTotal && possuiPermissaoAcesso(291)"
    >
      <a routerLink="/muralGT" routerLinkActive="active">
        <div class="ListActions_Item_Ico __not-colorful __is-small">
          <i class="apsa-icon-solid-mural Card_Icon_SolidAsColorfull"></i>
        </div>

        <div class="ListActions_Item_Label">Mural</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      class="ListActions_Item"
      *ngIf="!ehGestaoTotal && possuiPermissaoAcesso(814)"
    >
      <a routerLink="/mural" routerLinkActive="active">
        <div class="ListActions_Item_Ico __not-colorful __is-small">
          <i class="apsa-icon-solid-mural Card_Icon_SolidAsColorfull"></i>
        </div>

        <div class="ListActions_Item_Label">Mural</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="this.stateService.possuiPerfilFuncionario()"
      class="ListActions_Item"
    >
      <a routerLink="/funcionario/meus-dados" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-solid-termos-uso-solid"></i>
        </div>

        <div class="ListActions_Item_Label">Meus Dados</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div *ngIf="possuiPermissaoAcesso(810)" class="ListActions_Item">
      <a routerLink="/documento" routerLinkActive="active">
        <div class="ListActions_Item_Ico __is-tiny">
          <i class="apsa-icon-solid-folder"></i>
        </div>

        <div class="ListActions_Item_Label">Normas e Documentos</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div *ngIf="possuiPermissaoAcesso(283)" class="ListActions_Item">
      <a routerLink="/prestacao-conta-digital" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-outline-prestacao-de-contas-outline"></i>
        </div>

        <div class="ListActions_Item_Label">
          Pasta de prestação de contas digital
        </div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      class="ListActions_Item"
      *ngIf="
        stateService.possuiPerfilCentralAtendimento() ||
        stateService.possuiPerfilSindico() ||
        possuiPermissaoAcesso(881)
      "
    >
      <a routerLink="/pasta-dp" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-folder-open" style="color: #ff6e0f"></i>
        </div>

        <div class="ListActions_Item_Label">Documentos Fiscais</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      class="ListActions_Item"
      *ngIf="ehGestaoTotal && possuiPermissaoAcesso(303)"
    >
      <a routerLink="/inventario" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-tasks" style="color: #ff6e0f"></i>
        </div>

        <div class="ListActions_Item_Label">Relatório de Inventário</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(299)"
      class="ListActions_Item"
    >
      <a routerLink="/relatorio-ocorrencias" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-tasks" style="color: #ff6e0f"></i>
        </div>

        <div class="ListActions_Item_Label">Relatório de Ocorrências</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      class="ListActions_Item"
      *ngIf="
        possuiPermissaoAcesso(215) ||
        possuiPermissaoAcesso(216) ||
        possuiPermissaoAcesso(217)
      "
    >
      <a routerLink="/prestacao-contas" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-coins" style="color: #ff6e0f"></i>
        </div>

        <div class="ListActions_Item_Label">Prestação de contas</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(285)"
      class="ListActions_Item"
    >
      <a routerLink="/ocorrenciaGT" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-solid-circle-talk"></i>
        </div>

        <div
          *ngIf="possuiPerfilGestorSindico; else faleComOsindico"
          class="ListActions_Item_Label"
        >
          Ocorrências
        </div>

        <ng-template #faleComOsindico>
          <div class="ListActions_Item_Label">Fale com o Síndico</div>
        </ng-template>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="
        this.ehContaPool &&
        this.stateService.possuiAcessoFuncionalidadeGeral(835)
      "
      class="ListActions_Item"
    >
      <a routerLink="/quitacao-anual" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-solid-solid-informe-quitacao"></i>
        </div>

        <div class="ListActions_Item_Label">Quitação Anual</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="
        this.ehGestaoTotal &&
        (possuiPermissaoAcesso(286) ||
          possuiPermissaoAcesso(294) ||
          possuiPermissaoAcesso(304))
      "
      class="ListActions_Item"
    >
      <a routerLink="/reserva" routerLinkActive="active">
        <div class="ListActions_Item_Ico __is-small">
          <i class="apsa-icon-solid-square-check"></i>
        </div>

        <div class="ListActions_Item_Label">Reservas</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(823)"
      class="ListActions_Item"
    >
      <a routerLink="/reserva-conv" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-solid-square-check"></i>
        </div>

        <div class="ListActions_Item_Label">Reservas</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>
  </ng-container>

  <ng-container *ngIf="this.possuiPerfilLocatario">
    <div
      *ngIf="(customer$ | async)?.comodidadesParaVoce.exibir && this.stateService.possuiAcessoFuncionalidadeGeral(828)"
      class="ListActions_Item __is-highlighted"
    >
      <a (click)="handleClickComodidades()" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-outline-beneficios-pra-voce"></i>
        </div>

        <div class="ListActions_Item_Label">Comodidades para você!</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      class="ListActions_Item"
      *ngIf="stateService.possuiAcessoFuncionalidadeGeral(853)"
    >
      <a routerLink="/minhas-solicitacoes">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-tasks"></i>
        </div>

        <div class="ListActions_Item_Label">
          <strong>Minhas solicitações</strong>
        </div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div class="ListActions_Item">
      <a routerLink="/pagamentos" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-solid-pagamento-locatario"></i>
        </div>

        <div class="ListActions_Item_Label">Pagamentos</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>
  </ng-container>

  <ng-container *ngIf="this.possuiPerfilProprietario">
    <div
      *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(828)"
      class="ListActions_Item __is-highlighted"
    >
      <a (click)="handleClickComodidades()" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-outline-beneficios-pra-voce"></i>
        </div>

        <div class="ListActions_Item_Label">Comodidades para você!</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      class="ListActions_Item"
      *ngIf="stateService.possuiAcessoFuncionalidadeGeral(853)"
    >
      <a routerLink="/minhas-solicitacoes">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-tasks"></i>
        </div>

        <div class="ListActions_Item_Label">
          <strong>Minhas solicitações</strong>
        </div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div class="ListActions_Item">
      <a routerLink="/dados-imovel" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <div class="Item_Current_Icon">
            <i class="apsa-icon-solid-dados-imovel"></i>
          </div>
        </div>

        <div class="ListActions_Item_Label">Dados do Imóvel</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div class="ListActions_Item">
      <a routerLink="/extrato/proprietario" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <div class="Item_Current_Icon">
            <i class="apsa-icon-solid-extrato"></i>
          </div>
        </div>

        <div class="ListActions_Item_Label">Extrato</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(606)"
      class="ListActions_Item"
    >
      <a routerLink="/relacao-de-devedores" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-gavel"></i>
        </div>

        <div class="ListActions_Item_Label">Relação de Devedores</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>

    <div
      *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(604)"
      class="ListActions_Item"
    >
      <a routerLink="/processos-juridicos" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-gavel"></i>
        </div>

        <div class="ListActions_Item_Label">Processos Jurídicos</div>

        <div class="ListActions_Item_Action">
          <i class="fas fa-caret-right"></i>
        </div>
      </a>
    </div>
  </ng-container>
</div>
