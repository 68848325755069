<div class="contact-page" *ngIf="contatos && contatos.length > 0; else erro" > 
  <div class="header">
    <h4 class="header-title">Entre em contato com a {{ customerService.getCustomer().nomeApp === 'Apsa' ? 'APSA' : customerService.getCustomer().nomeApp.replace('by Apsa', '') }}</h4>
  </div>

  <div class="content">
      <div class="phones-area" >
        <div class="left-side">
          <ng-container *ngFor="let contato of contatos;let i = index" >
            <ng-container *ngIf="i <= 2">
              <p>{{ contato?.lugar }}</p>
              <a [href]="formatarTelefone(contato?.telefone)" >{{ contato?.telefone }}</a>
            </ng-container>
          </ng-container>
        </div>
        <div class="right-side">
          <ng-container *ngFor="let contato of contatos;let i = index" >
            <ng-container *ngIf="i >= 3">
              <p>{{ contato?.lugar }}</p>
              <a [href]="formatarTelefone(contato?.telefone)" >{{ contato?.telefone }}</a>
            </ng-container>
          </ng-container>
        </div>
        <!-- <div class="left-side">
          <p>Rio de Janeiro:</p>
          <a href="tel: +552132333000" >(21) 3233-3000</a>

          <p>Salvador:</p>
          <a href="tel: +557138784000" >(71) 3878-4000</a>
      
          <p>Recife:</p>
          <a href="tel: +558134642800" >(81) 3464-2800</a>
        </div>

        <div class="right-side">
          <p>Belo Horizonte:</p>
          <a href="tel: +553130473112" >(31) 3047-3112</a>

          <p>Fortaleza:</p>
          <a href="tel: +558540050505" >(85) 4005-0505</a>
          
          <p>Maceió:</p>
          <a href="tel: +558232179919" >(82) 3217-9919</a>
        </div> -->
      </div>

      
    </div>
  </div>
  <ng-template #erro >
    <div class="ListEmpty">
      <div class="ListEmpty_Icon">
        <i class="fas fa-exclamation-circle"></i>
      </div>
      
      <div class="ListEmpty_Text">
        Não foi possível obter os contatos no momento. Tente novamente mais tarde.
      </div>
    </div>
  </ng-template>