import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { ItemBusca, ItensListaMenu } from "../utils/ItemsListaMenu";
import { StateService } from "./stateService";

type Historico = {
  rota: string
}
type Favorito = {
  rota: string,
  ordem: number
}

@Injectable()
export class AcessoRapidoService {
  public methods = environment.apiCondominioDigital.endpoints.methods.acessoRapido; 
  public host = environment.apiCondominioDigital.host;

  public static trocaFavoritos: Subject<ItemBusca[]> = new Subject<ItemBusca[]>();
  public static abrirModalEdicaoFavoritos: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    public stateService: StateService,
  ) {

  }
  
  private getHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.stateService.getTokenApiCondominioDigital()
    });
  }

  getHistorico(): Observable<Historico[]> {
    let idPessoa = this.stateService.getPessoaLogada().Id;
    let url = this.host + this.methods.historico;
    
    let params = new HttpParams().set('idPessoa', idPessoa);
    
    return this.http.get<Historico[]>(url, { headers: this.getHeader(), params } );
  }
  
  setItemHistorico(rota: string): Observable<any> {
    let idPessoa = this.stateService.getPessoaLogada().Id;
    let url = this.host + this.methods.historico;
    
    let body = { idPessoa, rota }
    
    return this.http.post(url, body, { headers: this.getHeader() } );
  }
  
  getFavoritos(): Observable<Favorito[]> {
    let idPessoa = this.stateService.getPessoaLogada().Id;
    let contaEmpresa = Number(this.stateService.getCondominioLogado().contaEmpresa)
    let url = this.host + this.methods.favoritos;
    
    let params = new HttpParams().set('idPessoa', idPessoa).set('contaEmpresa', contaEmpresa);
    
    return this.http.get<Favorito[]>(url, { headers: this.getHeader(), params } );
  }

  setFavoritos(rotas: string[]) {
    let idPessoa = this.stateService.getPessoaLogada().Id;
    let contaEmpresa = Number(this.stateService.getCondominioLogado().contaEmpresa)
    let url = this.host + this.methods.favoritos;
    
    let body = { idPessoa, contaEmpresa, rotas }
    
    return this.http.post(url, body, { headers: this.getHeader() } );
  }

  filtraListaBusca() {
    const lista = ItensListaMenu;
    let listaFiltrada = [];
    if(
      (this.stateService.getCondominioLogado() &&
        this.stateService.possuiAcessoFuncionalidadeGeral(828)) ||
      (this.stateService.possuiAcessoFuncionalidadeGeral(828) &&
        this.stateService.possuiPerfilLocatario) ||
      (this.stateService.possuiAcessoFuncionalidadeGeral(828) &&
        this.stateService.possuiPerfilProprietario)
    ) {
      listaFiltrada.push(lista.find(item => item.titulo === 'Comodidades para você!'));
    }

    if(this.stateService.possuiAcessoFuncionalidade(219) || this.stateService.possuiAcessoFuncionalidade(220)) {
      let itemCotas = lista.find(item => item.rota === '/cotas');

      this.stateService.possuiApenasPerfilCondomino() || this.stateService.possuiPerfilProcuradorParaEmissaoBoleto() ?
        itemCotas.titulo = '2ª via de boletos' : 'Visualizar cotas';
      
      listaFiltrada.push(itemCotas);
    }

    if(this.stateService.possuiAcessoFuncionalidade(218)) {
      listaFiltrada.push(lista.find(item => item.rota === '/extrato/sindico'));
    }
    
    if(this.stateService.possuiAcessoFuncionalidade(810)) {
      listaFiltrada.push(lista.find(item => item.rota === '/documento'));
    }
    
    listaFiltrada.push(lista.find(item => item.titulo?.includes('Quero alugar/vender')));
    
    if(this.stateService.possuiAcessoFuncionalidade(215) || this.stateService.possuiAcessoFuncionalidade(216) || this.stateService.possuiAcessoFuncionalidade(217)) {
      listaFiltrada.push(lista.find(item => item.rota === '/prestacao-contas'));
    }
    
    if( this.stateService.isCondominioGT() && (this.stateService.possuiAcessoFuncionalidade(286) || this.stateService.possuiAcessoFuncionalidade(294) || this.stateService.possuiAcessoFuncionalidade(304))) {
      listaFiltrada.push(lista.find(item => item.rota === '/reserva'));
    }
    
    if(!this.stateService.isCondominioGT() && this.stateService.possuiAcessoFuncionalidade(823)) {
      listaFiltrada.push(lista.find(item => item.rota === '/reserva-conv'));
    }

    if(!this.stateService.isCondominioGT() && this.stateService.possuiAcessoFuncionalidade(816)) {
      let itemOcorrencia = lista.find(item => item.rota === '/ocorrencia');
      this.stateService.possuiPerfilGestor() || this.stateService.possuiPerfilSindico() ? itemOcorrencia.titulo = 'Ocorrências' : itemOcorrencia.titulo = 'Fale com o Síndico';
      if(!this.stateService.possuiPerfilGestor() && !this.stateService.possuiPerfilSindico()) itemOcorrencia.subItem = itemOcorrencia.subItem.replace('Ocorrências','Fale com o Síndico');
      listaFiltrada.push(itemOcorrencia);
    }
    
    if(this.stateService.isCondominioGT() && this.stateService.possuiAcessoFuncionalidade(285)) {
      let itemOcorrencia = lista.find(item => item.rota === '/ocorrenciaGT');
      this.stateService.possuiPerfilGestor() || this.stateService.possuiPerfilSindico() ? itemOcorrencia.titulo = 'Ocorrências' : itemOcorrencia.titulo ='Fale com o Síndico';
      if(!this.stateService.possuiPerfilGestor() && !this.stateService.possuiPerfilSindico()) itemOcorrencia.subItem = itemOcorrencia.subItem.replace('Ocorrências','Fale com o Síndico');
      listaFiltrada.push(itemOcorrencia);
    }
    
    if(this.stateService.possuiAcessoFuncionalidade(811)) {
      listaFiltrada.push(lista.find(item => item.rota === '/enquetes'));
    }
    
    if(!this.stateService.isCondominioGT() && this.stateService.possuiAcessoFuncionalidade(814)) {
      listaFiltrada.push(lista.find(item => item.rota === '/mural'));
    }
    
    if(this.stateService.possuiAcessoFuncionalidade(211)) {
      listaFiltrada.push(lista.find(item => item.rota === '/condomino'));
    }
    
    if(this.stateService.possuiAcessoFuncionalidade(227)) {
      listaFiltrada.push(lista.find(item => item.rota === '/funcionario'));
    }
    
    if(this.stateService.possuiAcessoFuncionalidade(205)) {
      listaFiltrada.push(lista.find(item => item.rota === '/dados-condominio'));
    }
    
    if(this.stateService.possuiPerfilCondomino() && !this.stateService.possuiApenasPerfilCondomino()) {
      listaFiltrada.push(lista.find(item => item.rota === '/cotas/visualizacaoIndividual'));
    }
    
    if(this.stateService.possuiAcessoFuncionalidade(306)) {
      listaFiltrada.push(lista.find(item => item.rota === '/envio-pagamentos'));
      listaFiltrada.push(lista.find(item => item.rota === '/envio-documento'));
    }
    
    if(this.stateService.permitirEntregaExpressa() != 0) {
      if(this.stateService.visaoCentralAtendimento()) {
        if(this.stateService.possuiAcessoFuncionalidadeGeral(842)) listaFiltrada.push(lista.find(item => item.rota === '/chame-moto'));
      } else {
        if(this.stateService.possuiAcessoFuncionalidade(842)) listaFiltrada.push(lista.find(item => item.rota === '/chame-moto'))
      }
    }
    
    if(this.stateService.possuiPerfilSindico || this.stateService.possuiPerfilConselheiro || this.stateService.possuiPerfilSubSindico || this.stateService.possuiPerfilProprietario || this.stateService.possuiPerfilLocatario) {
      listaFiltrada.push(lista.find(item => item.titulo === 'Quero contratar um seguro'));
    }

    if(this.stateService.possuiPerfilSindico || this.stateService.possuiPerfilSubSindico || this.stateService.possuiPerfilConselheiro) {
      listaFiltrada.push(lista.find(item => item.titulo === 'Fornecedores'));
    }
    
    if(this.stateService.validaExibicaoContrato()) {
      listaFiltrada.push(lista.find(item => item.rota === '/contratos'));
    }
    
    if(this.stateService.isCondominioGT() && this.stateService.possuiAcessoFuncionalidade(287)) {
      listaFiltrada.push(lista.find(item => item.rota === '/checklist'));
    }
    
    if((this.stateService.isCondominioGT() && this.stateService.possuiAcessoFuncionalidade(293)) || this.stateService.possuiPerfilGerencial()) {
      listaFiltrada.push(lista.find(item => item.rota === '/colaboradores'));
    }
    
    if(!this.stateService.isCondominioGT() && this.stateService.possuiAcessoFuncionalidade(819)) {
      listaFiltrada.push(lista.find(item => item.rota === '/cadastro-de-espacos'));
    }
    
    if(this.stateService.possuiAcessoFuncionalidade(267)) {
      listaFiltrada.push(lista.find(item => item.rota === '/funcionario/meus-dados'));
    }
    
    if(this.stateService.isCondominioGT() && this.stateService.possuiAcessoFuncionalidade(299)) {
      listaFiltrada.push(lista.find(item => item.rota === '/relatorio-ocorrencias'));
    }
    
    if(this.stateService.possuiAcessoFuncionalidadeGeral(873) &&
    ((this.stateService.getCondominioLogado()?.prime === 1 && this.stateService.isCondominioGT()) ||
    this.stateService.condominioPodeVisualizarRelatAcoes())
    ) {
      listaFiltrada.push(lista.find(item => item.rota === '/relatorios-gerenciais/acoes'));
    }
    
    if(this.stateService.getCondominioLogado()?.prime === 1 && this.stateService.possuiAcessoFuncionalidadeGeral(874)) {
      listaFiltrada.push(lista.find(item => item.rota === '/relatorios-gerenciais/financeiro'));
    }
    
    if(this.stateService.getCondominioLogado()?.prime === 1 && this.stateService.possuiAcessoFuncionalidadeGeral(875)) {
      listaFiltrada.push(lista.find(item => item.rota === '/relatorios-gerenciais/visitas-dp'));
    }
    
    if(this.stateService.isCondominioGT() && this.stateService.possuiAcessoFuncionalidade(303)) {
      listaFiltrada.push(lista.find(item => item.rota === '/inventario'));
    }

    if(this.stateService.possuiPerfilLocatario()) {
      listaFiltrada.push(lista.find(item => item.rota === '/pagamentos'));
    }

    if(this.stateService.possuiPerfilProprietario()) {
      listaFiltrada.push(lista.find(item => item.rota === '/dados-imovel'));
    }
    
    if(this.stateService.possuiPerfilProprietario() && this.stateService.possuiAcessoFuncionalidadeGeral(606)) {
      listaFiltrada.push(lista.find(item => item.rota === '/relacao-de-devedores'));
    }
    
    if(this.stateService.possuiAcessoFuncionalidade(207)) {
      listaFiltrada.push(lista.find(item => item.rota === '/processos-juridicos'));
    }

    if(this.stateService.possuiPerfilProprietario()) {
      listaFiltrada.push(lista.find(item => item.rota === '/extrato/proprietario'));
    }

    if(this.stateService.possuiAcessoFuncionalidade(214)) {
      listaFiltrada.push(lista.find(item => item.rota === '/acompanhamento-orcamentario'));
    }

    if(this.stateService.possuiAcessoFuncionalidade(214)) {
      listaFiltrada.push(lista.find(item => item.rota === '/acompanhamento-orcamentario'));
    }

    if(this.stateService.possuiAcessoFuncionalidade(283)) {
      listaFiltrada.push(lista.find(item => item.rota === '/prestacao-conta-digital'));
    }

    if(this.stateService.possuiAcessoFuncionalidade(885)) {
      listaFiltrada.push(lista.find(item => item.rota === '/cotas-inadimplentes'));
    }

    if(this.stateService.possuiAcessoFuncionalidade(226)) {
      listaFiltrada.push(lista.find(item => item.rota === '/receitas-previstas'));
    }
    
    if(this.stateService.getCondominioLogado()?.ehContaPool && ((this.stateService.possuiAcessoCentralAtendimento() && this.stateService.possuiAcessoFuncionalidadeGeral(835)) ||  this.stateService.possuiAcessoFuncionalidade(835))) {
      listaFiltrada.push(lista.find(item => item.rota === '/quitacao-anual'));
    }

    if(this.stateService.possuiAcessoFuncionalidade(243)) {
      listaFiltrada.push(lista.find(item => item.rota === '/lancamento-proventos-descontos'));
    }

    if(this.stateService.possuiAcessoFuncionalidade(276)) {
      listaFiltrada.push(lista.find(item => item.rota === '/quadro-horarios'));
    }

    if(this.stateService.possuiPerfilCentralAtendimento() || this.stateService.possuiPerfilSindico() || this.stateService.possuiAcessoFuncionalidade(881)) {
      listaFiltrada.push(lista.find(item => item.rota === '/pasta-dp'));
    }

    if(this.stateService.possuiAcessoFuncionalidadeGeral(853)) {
      listaFiltrada.push(lista.find(item => item.rota === '/minhas-solicitacoes'));
    }

    if(this.stateService.permitirEntregaExpressa() === 0 && this.stateService.possuiAcessoFuncionalidade(206)) {
      listaFiltrada.push(lista.find(item => item.rota === '/calendario-visitas'));
    }
    
    if(this.stateService.isCondominioGT() && this.stateService.possuiAcessoFuncionalidade(292)) {
      listaFiltrada.push(lista.find(item => item.rota === '/achados-perdidos'));
    }

    if(!this.stateService.isCondominioGT() && this.stateService.possuiAcessoFuncionalidade(292)) {
      listaFiltrada.push(lista.find(item => item.rota === '/achados-perdidos-geral'));
    }
    
    if(this.stateService.possuiAcessoFuncionalidade(305)) {
      listaFiltrada.push(lista.find(item => item.rota === '/courrier'));
    }

    if(this.stateService.possuiAcessoFuncionalidade(295)) {
      listaFiltrada.push(lista.find(item => item.rota === '/disponibilidade'));
    }

    if(!this.stateService.isCondominioGT() && this.stateService.possuiAcessoFuncionalidade(822)) {
      listaFiltrada.push(lista.find(item => item.rota === '/reserva-configuracao'));
    }

    if(this.stateService.isCondominioGT() && this.stateService.possuiAcessoFuncionalidade(291)) {
      listaFiltrada.push(lista.find(item => item.rota === '/muralGT'));
    }
    
    if(this.stateService.possuiPerfilSindico() || this.stateService.possuiPerfilGerencial() || this.stateService.possuiPerfilConselheiro()) {
      listaFiltrada.push(lista.find(item => item.rota === '/arquivo-digital'));
    }

    if((this.stateService.visaoCentralAtendimento() && this.stateService.possuiAcessoFuncionalidadeGeral(833)) || this.stateService.possuiAcessoFuncionalidade(833) ) {
      listaFiltrada.push(lista.find(item => item.rota === '/relatorio-pagamentos'));
    }
    
    if((this.stateService.possuiAcessoFuncionalidade(853) && this.stateService.possuiAcessoFuncionalidade(854)) || this.stateService.possuiPerfilGerencial()) {
      listaFiltrada.push(lista.find(item => item.rota === '/minhas-solicitacoes/cadastro/38'));
    }

    if((this.stateService.possuiAcessoFuncionalidade(853) && this.stateService.possuiAcessoFuncionalidade(854)) || this.stateService.possuiPerfilGerencial()) {
      listaFiltrada.push(lista.find(item => item.rota === '/minhas-solicitacoes/cadastro/35'));
    }

    if((this.stateService.possuiAcessoFuncionalidade(853) && this.stateService.possuiAcessoFuncionalidade(854)) || this.stateService.possuiPerfilGerencial()) {
      listaFiltrada.push(lista.find(item => item.rota === '/minhas-solicitacoes/cadastro/36'));
    }

    if(this.stateService.possuiAcessoFuncionalidade(243) || (this.stateService.possuiPerfilFuncionario()) || this.stateService.possuiPerfilGerencial()) {
      listaFiltrada.push(lista.find(item => item.rota === '/lancamento-proventos-descontos'));
    }
    
    if((this.stateService.possuiAcessoFuncionalidade(853) && this.stateService.possuiAcessoFuncionalidade(854)) || this.stateService.possuiPerfilGerencial()) {
      listaFiltrada.push(lista.find(item => item.rota === '/minhas-solicitacoes/cadastro/39'));
    }

    if((this.stateService.possuiAcessoFuncionalidade(853) && this.stateService.possuiAcessoFuncionalidade(854)) || this.stateService.possuiPerfilGerencial()) {
      listaFiltrada.push(lista.find(item => item.rota === '/minhas-solicitacoes/cadastro/34'));
    }

    if((this.stateService.possuiAcessoFuncionalidade(853) && this.stateService.possuiAcessoFuncionalidade(854)) || this.stateService.possuiPerfilGerencial()) {
      listaFiltrada.push(lista.find(item => item.rota === '/minhas-solicitacoes/cadastro/40'));
    }

    if((this.stateService.possuiAcessoFuncionalidade(853) && this.stateService.possuiAcessoFuncionalidade(854)) || this.stateService.possuiPerfilGerencial()) {
      listaFiltrada.push(lista.find(item => item.rota === '/minhas-solicitacoes/cadastro/37'));
    }
    
    if((this.stateService.getCondominioLogado() && this.stateService.getCondominioLogado().ehContaPool) && this.stateService.possuiAcessoFuncionalidade(306)) {
      listaFiltrada.push(lista.find(item => item.rota === '/centralpagamentos'));
    }
    
    listaFiltrada.push(lista.find(item => item.rota === '/seguros'));
    listaFiltrada.push(lista.find(item => item.titulo === 'Atendimento virtual'));

    return listaFiltrada.filter(item => item);
  }

}