<ng-container *ngIf="tipoExibicao === 'gestor'" >
  <a
    class="cotas-com-vencimento-no-mes"
    *ngIf="(stateService.possuiAcessoFuncionalidade(877) && cotasMensais !== null) || (stateService.possuiAcessoFuncionalidade(878) && cotasDevidas !== null)"
  >
    <ng-container *ngIf="stateService.possuiAcessoFuncionalidade(877) && cotasMensais !== null">
      <h1>
        Cotas com vencimento no mês
      </h1>
    
      <div class="arrecadacao arrecadacao--labels">
        <div class="arrecadao__description">Arrecadação</div>
        <div class="arrecadao__value">Valor (R$)</div>
      </div>
    
      <div class="arrecadacao">
        <div class="arrecadao__description">
          Cotas recebidas até {{ cotasMensais.dataPagto }}<br>
          Quantidade de cotas pagas: {{ cotasMensais.qttCotasPaga }}
        </div>
    
        <div class="arrecadao__value">
          {{ cotasMensais.qttCotasPaga }}
        </div>
      </div>
    
      <div class="arrecadacao">
        <div class="arrecadao__description">
          Cotas a receber<br>
          Cotas em débito: {{ cotasMensais.qttCotasReceber }}
        </div>
    
        <div class="arrecadao__value">
          {{ cotasMensais.valorCotasReceber }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="stateService.possuiAcessoFuncionalidade(878) && cotasDevidas !== null">
      <h1>
        Cotas devidas
      </h1>
    
      <div class="warning">
        <i class="fas fa-exclamation-triangle"></i>
    
        <p class="description">
          Valor total de cotas<br>
          devidas acumuladas:
        </p>
    
        <p class="value __is-danger">
          R$ {{ cotasDevidas.valorTotal }}
        </p>
      </div>
      
      <ds-button class="segunda-via" routerLink="/cotas" [full]="true" >Visualizar cotas</ds-button>
    </ng-container>
  </a>
</ng-container>

<ng-container *ngIf="tipoExibicao === 'condomino'" >
  <a
    class="cotas-com-vencimento-no-mes custom-padding-top"
  > 
    <div class="title-area">
      <!-- <img src="../../../assets/icon/segunda-via-boletos-primary.svg" /> -->
      <svg width="28" height="21" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="27.2108" height="20.05" rx="0.465775" fill="#FF6E0F"/>
        <rect x="3.20951" y="2.93364" width="2.77571" height="12.7496" fill="white" stroke="white" stroke-width="0.139733"/>
        <rect x="20.7022" y="2.93364" width="2.77571" height="12.7496" fill="white" stroke="white" stroke-width="0.139733"/>
        <rect x="7.58232" y="2.93364" width="1.31799" height="10.9082" fill="white" stroke="white" stroke-width="0.139733"/>
        <rect x="14.8709" y="2.93364" width="1.31799" height="10.9082" fill="white" stroke="white" stroke-width="0.139733"/>
        <rect x="17.7864" y="2.93364" width="1.31799" height="10.9082" fill="white" stroke="white" stroke-width="0.139733"/>
        <rect x="10.4978" y="2.93364" width="2.77571" height="10.9082" fill="white" stroke="white" stroke-width="0.139733"/>
      </svg>
        
      <h1>
        2ª via de Boletos
      </h1>
    </div>

    <img class="boleto-img" [src]="customerService.getCustomer().nomeApp === 'Apsa' ? '../../../assets/img/boleto_apsa.png' : '../../../assets/img/Boleto_sem_logo.png'">
    
    <p class="descricao" >Acesse agora a área de segunda via de boletos da {{ customerService.getCustomer().nomeApp === 'Apsa' ? 'APSA' : customerService.getCustomer().nomeApp.replace('by Apsa', '')  }} e facilite o seu dia a dia.</p>
    <ds-button class="segunda-via" routerLink="/cotas/visualizacaoIndividual" [full]="true" >Acessar meus boletos</ds-button>
  </a>
</ng-container>