import { LightBoxesService } from './../lightboxes/lightboxes.services';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { CondominioService } from '../../services/condominio.service';
import { ICotasDevidas } from '../../services/DTO/response/ICotasDevidas';
import { ICotasMensais } from '../../services/DTO/response/ICotasMensais';
import { StateService } from '../../services/stateService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cotas-com-vencimento-no-mes',
  templateUrl: './cotas-com-vencimento-no-mes.component.html',
  styleUrls: ['./cotas-com-vencimento-no-mes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CotasComVencimentoNoMesComponent implements OnInit, OnDestroy {
  public cotasMensais: ICotasMensais | null = null;
  public cotasDevidas: ICotasDevidas | null = null;
  public subscription: Subscription;

  @Input() tipoExibicao: 'condomino' | 'gestor' = 'condomino';

  constructor(
    private router: Router,
    private condominioService: CondominioService,
    public stateService: StateService,
    public lightBoxesService: LightBoxesService
  ) { }

  ngOnInit() {
    this.fetchAPIData();
    this.subscription = CondominioService.trocaCondominio.subscribe(_ => {
      this.fetchAPIData();
    });
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  fetchAPIData() {
    if(this.stateService.possuiAcessoFuncionalidade(877) || this.stateService.possuiApenasPerfilCondomino())
      this.fetchCotasComVencimentoNoMes();

    if(this.stateService.possuiAcessoFuncionalidade(878) || this.stateService.possuiApenasPerfilCondomino())
      this.fetchCotasDevidas();
  }

  fetchCotasComVencimentoNoMes() {
    this.condominioService.getCotasMensal().subscribe(
      response => {
        this.cotasMensais = response;
      }, 
      err => {
        this.cotasMensais = null;
      },
    );
  }

  fetchCotasDevidas() {
    this.condominioService.getCotasDevidas().subscribe(
      response => {
        this.cotasDevidas = response;
      },
      err => {
        this.cotasDevidas = null;
      },
    )
  }

}
