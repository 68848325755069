<div class="Lightbox">
  <div class="Popup Popup_ContratacaoCliente">
    <div class="Popup_Header">
      <div class="Popup_Header_Title">Titulo genérico</div>
    </div>
    <div class="Popup_Content">
      <div class="Popup_Content_MsgDefault">
        Aqui entrará o nome
      </div>

      <div class="Popup_Content_MsgDefault Popup_ContratacaoCliente_Cpf">
        Aqui entrará o CPF
      </div>

      <div class="WarningMessageContainer">
        <div class="Popup_Content_MsgDefault">
          Atenção: Ao confirmar, um email será enviado ao cliente com o token
          de acesso para o site de implantação. Não será possível editar o ID informado!
        </div>
      </div>
    </div>

    <div class="FormGroup" [formGroup]="form" [ngClass]="setClassError('mxmId')">
      <div class="FormGroup_Input">
        <input type="number" formControlName="mxmId">
      </div>

      <div class="FormGroup_Erro">
        Este campo é obrigatório.
      </div>
    </div>

    <div class="Popup_Footer">
      <div class="Popup_Footer_Buttons">
        <button class="Button Button_Primary Button_Outline __is-full" (click)="close()">
          <div class="Button_Content">
            <div class="Button_Content_Label">
              Cancelar
            </div>
          </div>
        </button>

        <button
          class="Button Button_Primary Button_Solid __is-full"
          (click)="secondClick ? confirmar() : primeiroClick()"
          [ngClass]="{
            '__is-inactive': form.invalid,
            '__pulse-text': secondClick
          }"
        >
          <div class="Button_Content">
            <div class="Button_Content_Label">
              {{secondClick ? 'Tem certeza?' : 'Confirmar envio'}}
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
