import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EntregaExpressaState } from '../entrega-expressa.state';

@Injectable()
export class EntregaExpressaDeactivator  {
  public constructor(
    private entregaExpressaState: EntregaExpressaState,
  ) {}

  public canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState ? : RouterStateSnapshot): boolean {
    this.entregaExpressaState.clearFilter();
    this.entregaExpressaState.clearHTMLNodeReferences();
    return true;
  }
}