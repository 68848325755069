<div class="Lightbox TextAreaWithConfirmationPopup">
  <div class="Popup Popup_ComunicacaoGenerica">
    <div class="Popup_Header">
      <div class="Popup_Header_Title">Titulo genérico</div>
    </div>

    <div class="Popup_Content">
      <div class="Popup_Content_MsgDefault">
        Aqui entrará qualquer texto
      </div>
    </div>
    
    <div class="FormGroup" [ngClass]="{ '__is-error': this.getErrorClass() }" >
      <div class="FormGroup_Input">
        <textarea
          rows="4"
          [formControl]="textInputValue"
        >
        </textarea>
      </div>
      <div class="FormGroup_Erro">{{ getErrorMessage('textInputValue') }}</div>
      <!-- errors: {{ getErrorMessage('textInputValue') }}
      <ng-container *ngIf="textInputValue.errors">
        <div class="FormGroup_Erro" *ngIf="textInputValue.errors.minlength">
          A descrição deve ter no mínimo 20 caracteres.
        </div>

        <div class="FormGroup_Erro" *ngIf="textInputValue.errors.required">
          Este campo é obrigatório.
        </div>
      </ng-container> -->
    </div>

    <div class="Popup_Footer">
      <div class="Popup_Footer_Buttons">
        <button class="Button Button_Primary Button_Outline __is-full" (click)="close()">
          <div class="Button_Content">
            <div class="Button_Content_Label">
              Cancelar
            </div>
          </div>
        </button>

        <button class="Button Button_Primary Button_Solid __is-full" 
        [ngClass]="{ '__is-inactive': this.textInputValue.invalid }"
        (click)="!this.textInputValue.invalid && confirmar()">
          <div class="Button_Content">
            <div class="Button_Content_Label">
              Confirmar envio
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
