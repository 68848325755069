<div class="TermosAdesaoCondicoesUsoTextComponent">
  <div [innerHTML]="termos"></div>
  <!-- <article>
    <h1>
      TERMOS E CONDIÇÕES DE USO
    </h1>
    
    <h2>1. CONSIDERAÇÕES INICIAIS</h2>
    <p>
      1.1 O presente documento tem como objetivo regular o acesso e utilização das funcionalidades adiante especificadas, disponibilizadas pela APSA em seu SITE - ÁREA DO CLIENTE, bem como especificar a forma de coleta, tratamento e descarte dos DADOS de USUÁRIOS, de acordo com a Política de Privacidade.<br><br>
      1.2 Todos os pontos especificados nos Termos e Condições de Uso deverão ser interpretados conjuntamente com o que consta da Política de Privacidade disponibilizada neste instrumento.<br><br>
      1.3 O cadastro no SITE – ÁREA DO CLIENTE implica a aceitação do USUÁRIO ao presente instrumento. Todavia, se o USUÁRIO discordar de algum dos termos constantes do presente instrumento, que são de adesão obrigatória e vinculados à utilização das funcionalidades que especifica, não deverá fazer USO do SITE - ÁREA DO CLIENTE.<br><br>
      1.4 Qualquer necessidade de esclarecimento, sugestão ou reclamação quanto ao presente documento poderá ser enviada pelo link “https://www.apsa.com.br/contato”.
    </p>

    <h2>2. DEFINIÇÕES</h2>
    <p>
      APSA: É a pessoa jurídica de direito privado identificada como APSA – Administração Predial e Negócios Imobiliários S. A., com sede na Travessa do Ouvidor, n.º 32, nesta cidade, inscrita no CNPJ sob o número 28.350.338/0001-92, sendo proprietária do SITE - ÁREA DO CLIENTE https://areaexclusiva.apsa.com.br/<br><br>
      CONDOMÍNIO: É o termo usado para caracterizar o condomínio que celebrou contrato de prestação de serviço com a APSA, cujo objeto é serviço auxiliar de administração e/ou gestão condominial;<br><br>
      CONTEÚDO PUBLICADO POR USUÁRIO(S): O termo "conteúdo publicado por usuário" abrange DADOS e/ou INFORMAÇÕES disponibilizados no SITE - ÁREA DO CLIENTE mediante atuação de USUÁRIO(S);<br><br>
      DADOS: O termo "dados" envolve qualquer conteúdo, incluindo documentos e INFORMAÇÕES, fornecido ao SITE - ÁREA DO CLIENTE ou obtido a partir do SITE - ÁREA DO CLIENTE;<br><br>
      INFORMAÇÕES: O termo "informações" envolve fatos, ações e todo conteúdo que se relacione a você, fornecidos ao SITE - ÁREA DO CLIENTE ou obtidos a partir do SITE - ÁREA DO CLIENTE;<br><br>
      INTERNET: É a rede mundial de computadores que comporta uma série de protocolos que possibilitam, de uma forma geral, a comunicação entre indivíduos a partir de plataformas virtuais. O SITE – ÁREA DO CLIENTE é acessado pela INTERNET;<br><br>
      PÁGINA: cada uma das “páginas” que integra o SITE - ÁREA DO CLIENTE;<br><br>
      USO: O termo "uso" significa a prática de um ou mais dos seguintes atos: consultar, acessar, usar, executar, copiar, agir ou expor publicamente, enviar, distribuir, modificar, traduzir e/ou criar trabalhos derivados;<br><br>
      USUÁRIO: é toda e qualquer pessoa, física ou jurídica, que, a partir do cadastramento e efetivação do login por conta de acesso, faz USO ou está cadastrado para fazer USO do SITE - ÁREA DO CLIENTE, enquadrando-se em ao menos uma das modalidades de usuário (conforme cláusula 3);<br><br>
      SITE - ÁREA DO CLIENTE: O termo "SITE - ÁREA DO CLIENTE" abrange: (i) o lugar na INTERNET, formado por uma ou várias PÁGINAS interligadas em https://areaexclusiva.apsa.com.br/, de propriedade da APSA, ocupada(s) com conteúdos de qualquer natureza (a exemplo de textos, fotos, animações gráficas, sons, vídeos, etc.), acessível por login e senha, e (ii) a aplicação chamada “APSA DIGITAL”, disponível para dispositivos portáteis com acesso à Internet, acessível por login e senha.
    </p>

    <h2>
      3. MODALIDADES DE USUÁRIO
    </h2>
    <p>
      3.1 Cada USUÁRIO enquadra-se em, no mínimo, uma modalidade de usuário.<br><br>
      3.2 Cada modalidade de usuário autoriza aos USUÁRIOS que lhe são vinculados o exercício de um conjunto de poderes relativos ao USO do SITE - ÁREA DO CLIENTE, a partir do login por conta de acesso.<br><br>
      3.3 Em consonância ao disposto nas cláusulas 3.1 e 3.2, o exercício dos poderes autorizados a cada USUÁRIO quanto ao USO do SITE - ÁREA DO CLIENTE, a partir do login por conta de acesso, dependerá diretamente da(s) modalidade(s) à(s) qual(is) que ele se enquadra, conforme critérios exclusivamente definidos pela APSA.<br><br>
      3.4 Os poderes inerentes a uma modalidade de usuário, em critério comparativo com as demais modalidades de usuário, podem: (i) ser diferentes e/ou (ii) ter alcances diferentes.<br><br>
      3.5 Os poderes inerentes a uma modalidade de usuário variarão, em critério comparativo com as demais modalidades de usuário, de acordo com a relação entre usuários àquela vinculados e o CONDOMÍNIO e/ou a APSA.<br><br>
      3.6 Cada USUÁRIO deve comunicar a APSA, mediante o e-mail atendimento@apsa.com.br, sobre qualquer alteração de modalidade de usuário, relacionada a si ou a outrem, que venha a ter ciência e considere indevida ou inadequada, sendo o cumprimento de tal obrigação fundamental aos objetivos aos quais o SITE - ÁREA DO CLIENTE se destina.
    </p>

    <h2>
      4. FUNCIONALIDADES DISPONIBILIZADAS E UTILIZAÇÃO
    </h2>
    <p>
      4.1 O SITE - ÁREA DO CLIENTE disponibiliza aos USUÁRIOS determinadas funcionalidades, conforme orientações disponíveis no mesmo.<br><br>
      4.2 Através do SITE - ÁREA DO CLIENTE os USUÁRIOS, a depender da modalidade em que se enquadram, podem, a título exemplificativo: (i) ter acesso e consultar conteúdos informativos, referentes ao(s) CONDOMÍNIO(S), de caráter financeiro, orçamentário, empregatício, normativo, deliberativo, administrativo, estrutural e de gestão; (ii) verificar o adimplemento de obrigações condominiais; (iii) emitir boletos de pagamento referentes a cotas condominiais e/ou penalidades; (iv) criar  taxas e cotas condominiais extras; (v) fazer simulações (vi) convocar e/ou confirmar comparecimento em assembleias de condomínio; (vii) sugerir, aprovar e/ou desaprovar medidas referentes à administração condominial; (viii) efetuar questionamentos, avisos e/ou notificações à APSA e/ou a outro(s) USUÁRIO(S); (ix) receber respostas, avisos e/ou notificações da APSA e/ou de outro(s) USUÁRIO(S); (x) indicar contratação de empresa(s) ao(s) CONDOMÍNIO(S).<br><br>
      4.3 O USUÁRIO declara estar ciente e concordar que:<br><br>
      a) As funcionalidades especificadas somente poderão ser utilizadas por pessoas civilmente capazes, conforme previsto na legislação brasileira vigente;<br><br>
      b) Aceita a exposição de conteúdo pessoal seu no SITE - ÁREA DO CLIENTE;<br><br>
      c) Fica a critério exclusivo da APSA aceitar, ou não, seu cadastro no SITE - ÁREA DO CLIENTE, podendo inclusive excluí-lo, suspendê-lo, bem como cancelá-lo sem necessidade de aviso prévio, indenização ou compensação;<br><br>
      d) A APSA presta serviços de administração e/ou gestão condominial;<br><br>
      e) INFORMAÇÕES e DADOS seus, fornecidos ao SITE - ÁREA DO CLIENTE, por si ou por outrem, poderão ser consultados, de forma parcial ou total, por outros USUÁRIOS, a depender das modalidades de usuário em que estes se enquadram.<br><br>
      f) INFORMAÇÕES e DADOS seus, fornecidos ao SITE - ÁREA DO CLIENTE, por si ou por outrem, poderão ser consultados de forma total pela APSA.<br><br>
      4.5 As funcionalidades disponíveis no SITE – ÁREA DO CLIENTE podem variar, em forma, apresentação, quantidade e mesmo disponibilidade, de acordo com o tipo de acesso (se realizado por computador ou dispositivo móvel).
    </p>

    <h2>
      5. MANUTENÇÃO DO CONTEÚDO DISPONÍVEL NA PLATAFORMA
    </h2>
    <p>
      5.1. O acesso ao SITE - ÁREA DO CLIENTE será disponibilizado, aos USUÁRIOS, 24 (vinte e quatro) horas por dia e 7 (sete) dias por semana.<br><br>
      5.2 Não obstante o disposto no item antecedente, o acesso ao portal poderá estar temporariamente indisponível, a qualquer momento, em caso de interrupções necessárias em razão de serviços de manutenção de qualquer natureza, ou falhas na operação dos servidores, das empresas fornecedoras de energia elétrica, empresas prestadoras de serviços de telecomunicações, casos fortuitos, força maior, ou ações de terceiros que não sejam do controle da APSA.<br><br>
      5.3 A APSA compromete-se a atualizar o conteúdo do SITE - ÁREA DO CLIENTE, disponível aos USUÁRIOS, de forma célere, objetivando sua perfeita adequação às condições fáticas, sendo a colaboração dos USUÁRIOS fundamental nesse sentido.<br><br>
      5.4 A obrigação imputada à APSA prevista na Cláusula 5.3 cessa e perde efeitos caso ocorra encerramento do(s) contrato(s) de prestação de serviço que tenha(m) como objeto a administração condominial, celebrado(s) entre a APSA e o(s) CONDOMÍNIO(S) ao(s) qual(is) está vinculado o USUÁRIO que faz USO desse SITE - ÁREA DO CLIENTE.<br><br>
      5.5 A APSA pode, a seu exclusivo critério, suprimir ou modificar funcionalidades e conteúdos antes disponibilizados no SITE – ÁREA DO CLIENTE, podendo, inclusive, extinguir o SITE – ÁREA DO CLIENTE, não representando tais atos qualquer violação legal ou contratual.
    </p>

    <h2>
      6. DO SITE - ÁREA DO CLIENTE
    </h2>
    <p>
      6.1 O SITE - ÁREA DO CLIENTE representa uma das ferramentas utilizadas pela APSA para consecução de sua atividade empresarial de administração condominial. A gestão, disponibilidade, apresentação, criação, inclusão e exclusão de funcionalidades, e todos os atos sobre a permanência e funcionamento do SITE – ÁREA DO CLIENTE são exclusivos da APSA e independem de qualquer manifestação, aviso ou interpelação.<br><br>
      6.2 Como ferramenta de propriedade da APSA, o acesso do SITE – ÁREA DO CLIENTE poderá ocorrer mediante pagamento, a critério exclusivo da APSA. Em tal hipótese o USUÁRIO será previamente avisado e poderá não aderir à contratação com custo. Nesta hipótese, o USO do SITE – ÁREA DO CLIENTE poderá ser limitado ou descontinuado.
    </p>

    <h2>
      7. OBRIGAÇÕES E RESPONSABILIDADES
    </h2>
    <p>
      7.1 A APSA atua tão somente como responsável pela administração e/ou gestão condominial do(s) CONDOMÍNIO(S) de acordo com a legislação aplicável às suas atividades, eximindo-se de responsabilidade por quaisquer danos que não sejam estritamente decorrentes do exercício dessas atividades em relação ao USO do SITE - ÁREA DO CLIENTE pelos USUÁRIOS.<br><br>
      7.2 O USUÁRIO é responsável por todo e qualquer ato praticado no SITE - ÁREA DO CLIENTE mediante o login por sua conta de acesso no mesmo.<br><br>
      7.3 O USUÁRIO compromete-se a não infringir quaisquer direitos de terceiros ao fazer USO do SITE - ÁREA DO CLIENTE, responsabilizando de forma direta e pessoal caso viole a obrigação estabelecida nessa cláusula. Caso o USUÁRIO faça USO do SITE - ÁREA DO CLIENTE na qualidade de representante de pessoa jurídica ou CONDOMÍNIO, a responsabilidade pela violação de direito de terceiros pode ainda recair sobre os representados.<br><br>
      7.4 O USUÁRIO é responsável por toda e qualquer assinatura digital, autorização e/ou visualização de pagamento, criação de cotas de quaisquer outros atos realizados a partir de login em sua conta de acesso no SITE - ÁREA DO CLIENTE, assim como por qualquer documento que o USUÁRIO anexe.<br><br>
      7.5 O USUÁRIO não pode ceder ou transferir o presente termo sem o consentimento prévio e por escrito da APSA, não produzindo qualquer efeito sem o consentimento da mesma.<br><br>
      7.6 O USUÁRIO deve respeitar toda e qualquer restrição de USO do SITE - ÁREA DO CLIENTE.<br><br>
      7.7 O USUÁRIO obriga-se a comunicar à APSA, de forma imediata a partir de sua ciência, sobre sua vinculação ou desvinculação referente a qualquer das seguintes funções ou status ligadas a CONDOMÍNIO: assessor de síndico, auxiliar de conselheiro, auxiliar de subsíndico, condômino, conselheiro, funcionário, procurador (com poderes de representação relativos a qualquer matéria que afete ou possa afetar, de forma direta ou indireta, o CONDOMÍNIO e/ou sua administração), síndico e/ou subsíndico. Tal comunicação deve ser efetuada mediante envio de e-mail para atendimento@apsa.com.br. <br><br>
      7.8 O USUÁRIO reconhece e concorda que eventuais danos que o SITE - ÁREA DO CLIENTE cause ao dispositivo pelo qual o mesmo é acessado, não acarretará à APSA qualquer responsabilidade, muito menos ressarcimento de qualquer custo despendido devido ao USO do SITE - ÁREA DO CLIENTE. <br><br>
      7.9 O USUÁRIO compromete-se a não utilizar o SITE - ÁREA DO CLIENTE para cometer e/ou tentar cometer atos que tenham como objetivo: (a) obter acesso não autorizado a outro computador, servidor, ou rede; (b) interromper serviço, servidores, ou rede de computadores através de qualquer método ilícito; (c) burlar qualquer sistema de autenticação ou de segurança; (d) vigiar secretamente terceiros; (e) acessar conteúdos confidenciais, de qualquer natureza, tais como nome de usuários (login) e senhas de acesso de outro usuário da Internet que esteja vulnerável, bem como documentos cuja restrição ocorre em virtude da modalidade de USUÁRIO em que se enquadra; (f) obter dados de outros USUÁRIOS a que não tenha direito para regular exercício de direito.<br><br>
      7.10 O USUÁRIO será único responsável por todo e qualquer dano decorrente de violação às restrições de USO do SITE - ÁREA DO CLIENTE.<br><br>
      7.11 O USUÁRIO compromete-se a respeitar todas as condições estabelecidas no presente instrumento.
    </p>

    <h2>
      8. RESCISÃO
    </h2>
    <p>
      8.1 Não obstante o disposto nas cláusulas 5.3 e 5.4, os presentes Termos e Condições de Uso terão vigência por prazo indeterminado.
    </p>

    <h2>
      9. DISPOSIÇÕES GERAIS DOS TERMOS E CONDIÇÕES DE USO
    </h2>
    <p>
      9.1 Toda comunicação, entre a APSA e o USUÁRIO, realizada fora do âmbito do SITE - ÁREA DO CLIENTE somente será considerada válida se for feita por meio de um funcionário da APSA.<br><br>
      9.2 Todas as senhas de acesso ao SITE - ÁREA DO CLIENTE fornecidas ao USUÁRIO, ou por ele implantadas, são pessoais e intransferíveis, de uso exclusivo do USUÁRIO, que assume integral responsabilidade pela sua guarda e sigilo, bem como pela utilização indevida por terceiros. A senha, quando fornecida ao USUÁRIO, deve ser por este modificada no primeiro acesso, sendo de sua integral responsabilidade a modificação, guarda e sigilo da senha.<br><br>
      9.3 Para maior segurança, a APSA recomenda a periódica alteração da senha de acesso, pelo USUÁRIO, a fim de dificultar qualquer utilização indevida de seu nome de usuário no SITE - ÁREA DO CLIENTE. Ainda para a segurança do USUÁRIO, a APSA recomenda a não utilização de senhas de fácil acerto, tais como datas de aniversários, placas de veículos, números de telefone, nomes próprios ou de familiares, número de documentos, endereços, entre outros.<br><br>
      9.4 É vedada a utilização do SITE - ÁREA DO CLIENTE para finalidade a qual o mesmo não se destina, bem como sua cópia e reprodução, ficando os infratores sujeitos às sanções civis e criminais, nos termos da legislação vigente.<br><br>
      9.5 Estes Termos e Condições de Uso, e suas eventuais alterações, produzem todos os efeitos a partir do momento da disponibilização no SITE - ÁREA DO CLIENTE.<br><br>
      9.6 A APSA se reserva o direito de, a seu exclusivo critério, a qualquer tempo, e sem aviso prévio, alterar estes Termos e Condições de Uso, razão pela qual é recomendável ao USUÁRIO a verificação rotineira e habitual deste documento. Em caso de alteração, as modificações produzem todos os efeitos a partir do momento da disponibilização no SITE - ÁREA DO CLIENTE.<br><br>
      9.7 A APSA poderá, a qualquer tempo, alterar a forma e conteúdo do SITE - ÁREA DO CLIENTE, por razões de ordem técnica e operacional, sempre visando a melhor interação do USUÁRIO com o SITE - ÁREA DO CLIENTE, bem como a facilitação da utilização do SITE - ÁREA DO CLIENTE pelo USUÁRIO.<br><br>
      9.8 A declaração judicial de nulidade ou ineficácia de qualquer cláusula do presente termo não interferirá nos efeitos das demais.<br><br>
      9.9 A APSA se reserva o direito de pré-selecionar, rever, marcar, filtrar, comentar, questionar, recusar ou remover todo e qualquer conteúdo publicado por USUÁRIO(S) ou enviado por USUÁRIO(S) através do SITE - ÁREA DO CLIENTE.<br><br>
      9.10 A tolerância do eventual descumprimento pelo USUÁRIO de quaisquer das cláusulas e condições dos presentes Termos e Condições de Uso não constituirá novação das obrigações aqui estipuladas e tampouco impedirá ou inibirá a exigibilidade das mesmas a qualquer tempo pela APSA<br><br>
      9.11 Ao utilizar o SITE - ÁREA DO CLIENTE, o USUÁRIO estará expressamente aderindo e se vinculando à Política de Privacidade abaixo prevista.
    </p>
  </article> -->
</div>
