import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '../../services/stateService';

@Component({
  selector: 'app-popup-avaliacao',
  templateUrl: './popup-avaliacao.component.html',
  styleUrls: ['./popup-avaliacao.component.scss'],
})
export class PopupAvaliacaoComponent implements OnInit {
  @Input() isOpen: boolean = false
  @Input() dadosLoja: {linkLoja: string, nomeLoja: string}

  constructor(private stateService: StateService) { }

  ngOnInit() {
  }

  recusar() {
    // this.isOpen = false
    this.stateService.exibirModalDeAvaliacaoMobile(false)
  }
  
  confirmar() {
    this.stateService.openExternalLink(this.dadosLoja.linkLoja)
    this.stateService.exibirModalDeAvaliacaoMobile(false)
    // this.isOpen = false
  }

}
