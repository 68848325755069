import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';

//service
import { OnsignalService } from './shared/services/onsignal.service';
import { environment } from 'src/environments/environment';
import { StateService } from './shared/services/stateService';
import { LightBoxesService } from './shared/components/lightboxes';
import { register } from 'swiper/element/bundle';
import { StatusBar, Style } from '@capacitor/status-bar';
import { MobileAccessibility } from '@awesome-cordova-plugins/mobile-accessibility/ngx';
import { CUSTOMERS } from 'src/customers';
import { Observable, Subscription } from 'rxjs';
import CustomerService from './shared/services/customer.service';

register();
type Customer = typeof CUSTOMERS.Apsa;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public customer$ = new Observable<Customer>();
  public subscriptions: Subscription[] = [];
  public environment = environment;
  public isLocalHost = window.location.hostname === 'localhost';
  constructor(
    private lightboxService: LightBoxesService,
    public platform: Platform,
    private customerService: CustomerService,
    // private splashScreen: SplashScreen,
    // private statusBar: StatusBar,
    private onsignalService: OnsignalService,
    private acessibilidade: MobileAccessibility,
    public stateService: StateService,
  ) {
    this.setCustomer();
    this.initializeApp();
    this.setAndroidBackButtonBehavior();
    console.log('enviroment',environment)
  }

  private setAndroidBackButtonBehavior(): void {
    if (this.platform.is('android')) {
      this.platform.backButton.subscribe(() => {
        if (window.location.pathname == "/login" || window.location.pathname == "/")
          this.lightboxService.open(
            'custom-mural-cadastrado', {
            title: 'Sair',
            text: 'Deseja sair do aplicativo?'
          }, 0
          ).then(event => {
            if (event) {
              navigator['app'].exitApp()
            }
          })

        else
          navigator['app'].backButton();
      });
    }
  }

  private setCustomer() {
    this.customerService.initialize();
    this.customer$ = this.customerService.getCustomerObservable();
    if(window.location.hostname.includes('-doitt') || window.location.hostname.includes('app.doitt')) {
      this.customerService.forceCustomer('Doitt');
      if(this.platform.is('desktop') || this.platform.is('mobileweb')) this.customerService.setFavicon();
    }
    
    if(window.location.hostname.includes('-hh') || window.location.hostname.includes('hhcondominios')) {
      this.customerService.forceCustomer('homehunters by Apsa')
      if(this.platform.is('desktop') || this.platform.is('mobileweb')) this.customerService.setFavicon();
    }

    // const subscriptionWebchat = this.customer$.subscribe(customer => {
    //   if(customer.webchat.exibir) {
    //     this.removeZenviaWebChat();
    //     this.startZenviaWebChatOnDesktop(customer);
    //   }
    //   else
    //     this.removeZenviaWebChat();
    // });

    // this.subscriptions.push(subscriptionWebchat);
    
  }

  initializeApp() {
    if(this.stateService.platformIsDeviceMobile()) {
      StatusBar.setStyle({ style: Style.Light });
      StatusBar.setBackgroundColor({ color: '#ffffff' });
      StatusBar.setOverlaysWebView({ overlay: false })
    }
    this.platform.ready().then((data) => {
      if (environment.production) {
        if(this.stateService.platformIsDevice()) {
          this.acessibilidade.updateTextZoom();
          this.acessibilidade.usePreferredTextZoom(false);
          this.acessibilidade.setTextZoom(100) 
          this.onsignalService.initMobile();
          
        }
        else {
          this.onsignalService.init(); // desktop
        }
      };
      // this.onsignalService.init(); // desktop
    });
  }

  // oneSignalInit(): void {
  //   OneSignal.initialize(environment.config.onesignalId);
  //   let myClickListener = async function(event) {
  //         let notificationData = JSON.stringify(event);
  //     };
  //   OneSignal.Notifications.addEventListener("click", myClickListener);
  //   OneSignal.Notifications.requestPermission(true)
  // }


}
