import {Injectable} from '@angular/core'
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { AdesaoDeBoletoDigitalService } from 'src/app/shared/services/adesao-de-boleto-digital.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { StateService } from 'src/app/shared/services/stateService';
import { LightBoxesService } from '../../lightboxes';

@Injectable({ providedIn: 'root' })
export class AdesaoBoletoButton {
    
  constructor(
      public stateService: StateService,
      private adesaoService: AdesaoDeBoletoDigitalService,
      private lightBoxesService: LightBoxesService,
      private router: Router,
      private modalService: ModalService
  ){}

  cadastraEdita(condominios, imoveis, origem) {
    const pessoaLogada = this.stateService.getPessoaLogada();
    const body = {
      idPessoa: pessoaLogada.Id,
      cpfCnpj: pessoaLogada.CpfCnpj,
      condominios: condominios,
      imoveis: imoveis
    }
    this.adesaoService.cadastrarEditarAdesao(body).subscribe(item => {
      // console.log('item: ', item);
      // if(item) {
        sessionStorage.setItem('listaCondominiosAdesaoBoleto', JSON.stringify(condominios));
        sessionStorage.setItem('listaImoveisAdesaoBoleto', JSON.stringify(imoveis));
        console.log('Setou os dados na session');
        
        this.lightBoxesService.open(
          'custom-generico', {
            title: 'Sucesso!',
            text: 'A forma de envio dos boletos foi alterada.'
          }, 5000)
        // }
        this.close()
    },
    err => {
      this.lightBoxesService.open(
        'custom-generico', {
          title: 'Atenção',
          text: err.error.message
        }, 5000)
    })
  }
    
  close() {
    sessionStorage.setItem('exibirModalAdesaoBoletoDigital', JSON.stringify(false));

    if (
      !this.stateService.possuiPerfilLocatario() &&
      !this.stateService.possuiPerfilProprietario() &&
      !this.stateService.possuiPerfilGerencial() &&
      (this.stateService.platformIsDevice() || window.innerWidth < 800)
    ) {
      const quantidadeDeCondominios = this.stateService.getUserCondominiums()?.length || 0;
      
      if(quantidadeDeCondominios === 1) {
        this.router.navigate(['/atalhos']);
        return;
      }
    }

    this.router.navigate(['/home']);
  }
}