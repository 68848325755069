import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-notificacoes-modal',
  templateUrl: './notificacoes-modal.component.html',
  styleUrls: ['./notificacoes-modal.component.scss'],
})
export class NotificacoesModalComponent implements OnInit, OnDestroy {
  @Input() open: boolean = false;
  @Output() eventClose = new EventEmitter();

  constructor(
    private modalService: ModalService,
  ) { }

  ngOnDestroy() {
    this.modalService.removeHasModalClassFromContainerDiv();
  }

  ngOnInit() {}

  close() {
    this.eventClose.emit();
    const container = document.querySelectorAll('.Container');
    for (let i = 0; i < container.length; i++) {
      container[i].classList.remove('__has-modal')
    }
  }
}
