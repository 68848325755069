import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { Platform } from '@ionic/angular';
import { StateService } from '../services/stateService';

@Injectable({
    providedIn: 'root'
})

export class AnalyticsGuardService  {

  constructor(
    public platform: Platform,
    private stateService: StateService,
  ) { }

  canActivate(_, state: RouterStateSnapshot): boolean {
    this.platform.ready()
      .then(() => this.sendPageViewAnalyticsAccordingToPlatform(state))
      .catch(err => console.error(err));

    return true;
  }

  private sendPageViewAnalyticsAccordingToPlatform(state: RouterStateSnapshot) {
    // this.sendPageViewAnalyticsIfWebPlatform(state);
    this.sendPageViewAnalyticsIfMobilePlatform(state);
  }
  
  private sendPageViewAnalyticsIfWebPlatform(state: RouterStateSnapshot) {
    if(!this.isMobile())
      if(window['ga']) window['ga']('send', 'pageview', `/app${state.url}`);
  }

  private sendPageViewAnalyticsIfMobilePlatform(state: RouterStateSnapshot) {
    if(this.isMobile())
      if(window['ga']) window['ga']('send', 'pageview', state.url);
  }

  private isMobile(): boolean {
    return this.stateService.platformIsDeviceMobile();
  }
}
