import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { distinctUntilChanged, Subscription } from "rxjs";
import { AcessoRapidoService } from "../../services/acesso-rapido.service";
import { CondominioService } from "../../services/condominio.service";
import { StateService } from "../../services/stateService";
import { ItemBusca } from "../../utils/ItemsListaMenu";
import { LightBoxesService } from "../lightboxes";
import CustomerService from "../../services/customer.service";
import { Route, Router } from "@angular/router";

@Component({
    selector: 'app-modal-edicao-favoritos',
    templateUrl: './modal-edicao-favoritos.component.html',
    styleUrls: ['./modal-edicao-favoritos.component.scss']
})
export class ModalEdicaoFavoritosComponent implements OnInit, OnDestroy {

    @Input() abrirModal: boolean = false;

    public itensBusca: ItemBusca[];
    public favoritos: ItemBusca[] = [];
    public inativos: ItemBusca[];
    public busca: string = '';
    public resultadoBusca: ItemBusca[];
    public placeholders: any[]= [];
    public semMudancas: boolean = true;
    public subscription: Subscription;
    public subscription2: Subscription;
    public textoAdicionarRemover: string = '';
    public showToast: boolean = false;
    public innerWidth: number = window.innerWidth;

    @Input() mudancaFavoritos: number = 0;

    constructor(
        private stateService: StateService, 
        private acessoRapidoService: AcessoRapidoService, 
        private lightBoxesService: LightBoxesService,
        public customerService: CustomerService,
        private router: Router
    ) {}
    
    ngOnInit(): void {
        this.itensBusca = this.acessoRapidoService.filtraListaBusca();
        if(this.router.url === '/home' || this.router.url === '/atalhos') this.getFavoritos();
        
        this.subscription = AcessoRapidoService.trocaFavoritos.pipe(distinctUntilChanged()).subscribe(_ => {
            this.getFavoritos();
        });
        
        this.subscription2 = CondominioService.trocaCondominio.pipe(distinctUntilChanged()).subscribe(_ => {
            setTimeout(() => {
                this.itensBusca = this.acessoRapidoService.filtraListaBusca();
                this.getFavoritos();
            }, 500)
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.subscription2.unsubscribe();
    }

    getNomeContaCondominio(): string {
        let nomeCondominio = this.stateService.getCondominioLogado()?.nome;
        let agenciaConta = `(${this.stateService.getCondominioLogado()?.agencia}-${this.stateService.getCondominioLogado()?.contaEmpresa})`;
        return `${nomeCondominio} ${agenciaConta}`;
    }
    
    getNomePerfisCondLogado(): string {
    return this.stateService.getCondominioLogado()?.nomePerfil?.join(', ');
    }

    getTipoImg(img: string): number {
        if(img?.includes('span') || img?.includes('<div')) {
            return 2;
        }

        if(img?.includes('fa-')) {
            return 1;
        }

        if(img?.includes('../')) {
            return 3;
        }
    }

    atualizarBusca() {
        this.resultadoBusca = this.inativos.filter(item => item?.titulo.toUpperCase().indexOf(this.busca.toUpperCase().trim()) !== -1 || item?.subItem?.toUpperCase().startsWith(this.busca.toUpperCase().trim()));
        this.resultadoBusca = this.resultadoBusca.filter(item => item);
    }

    async fecharModal() {
        if(this.semMudancas) {
            AcessoRapidoService.abrirModalEdicaoFavoritos.next(false);
            // let modal: Element = document.getElementsByClassName('modal-edicao-favoritos')[0];
            // if(modal) modal.classList.remove('__display');
        } else {
            const res: boolean = await this.lightBoxesService.openYesOrNoPopup(
                'Você ainda não salvou seus favoritos', 
                'Todas as mudanças feitas até o momento serão perdidas. Deseja fechar a janela mesmo assim?'
            )
            
            if(res) {
                AcessoRapidoService.abrirModalEdicaoFavoritos.next(false);
                // let modal: Element = document.getElementsByClassName('modal-edicao-favoritos')[0];
                // if(modal) modal.classList.remove('__display');
                this.getFavoritos();
                this.semMudancas = true;
            }
        }
        // this.semMudancas = true;
    }

    removerFavorito(index: number) {
        let favoritoRemocao = this.favoritos[index];
        this.favoritos = this.favoritos.filter(favorito => favorito.rota !== favoritoRemocao?.rota || favorito?.click !== favoritoRemocao?.click);
        this.getInativos();
        this.getPlaceholders();
        this.semMudancas = false;
        this.textoAdicionarRemover = 'Favorito removido com sucesso';
        this.showToast = true;
        setTimeout(() => this.showToast = false, 5000)
    }

    adicionarFavorito(id: number | string, busca: boolean = false) {
        let inativoSelecionado: ItemBusca;
        inativoSelecionado = this.inativos.find(item => item?.rota === id || item?.click === id);
        this.busca = '';
        this.favoritos.push(inativoSelecionado);
        this.getInativos();
        this.getPlaceholders();
        this.semMudancas = false;
        this.textoAdicionarRemover = 'Favorito adicionado com sucesso';
        this.showToast = true;
        setTimeout(() => this.showToast = false, 5000)
    }

    getFavoritos() {
        this.acessoRapidoService.getFavoritos().subscribe({
            next: res => {
                if(res && res.length > 0) {
                    this.favoritos = [];
                    for(let favorito of res) {
                        let itemLista = this.itensBusca.find(item => item?.rota ? item.rota === favorito.rota : item.click === favorito.rota);
                        if(itemLista) this.favoritos.push(itemLista);
                    }
                    this.favoritos = [...new Set(this.favoritos)]
                    this.getInativos();
                    this.getEquivalentes(res);
                    this.getPlaceholders();
                }
            },
            error: err => console.log('falha ao obter favoritos')
        })
    }

    getInativos() {
        let itensFiltrados = this.itensBusca;
        for(let favorito of this.favoritos) {
            if(favorito?.rota) itensFiltrados = itensFiltrados.filter(item => item?.rota !== favorito?.rota);
            if(favorito?.click) itensFiltrados = itensFiltrados.filter(item => item?.click !== favorito?.click);
        }
        this.inativos = [...new Set(itensFiltrados)];
    }

    getPlaceholders() {
        if(this.favoritos.length < 5) {
            switch(this.favoritos.length) {
                case 4: this.placeholders = [1];
                    break;
                case 3: this.placeholders = [1,2];
                    break;
                case 2: this.placeholders = [1,2,3];
                    break;
                case 1: this.placeholders = [1,2,3,4];
                    break;
                default: this.placeholders = [];
            }
        } else {
            this.placeholders = [];
        }
    }

    async salvarFavoritos() {
        let listaRotas: string[] = [];
        for(let favorito of this.favoritos) {
            if(favorito?.rota) listaRotas.push(favorito.rota);
            if(favorito?.click) listaRotas.push(favorito.click);
        }

        listaRotas = [...new Set(listaRotas)];
        
        this.acessoRapidoService.setFavoritos(listaRotas).subscribe({
            next: res => {
                AcessoRapidoService.trocaFavoritos.next(this.favoritos);
                this.lightBoxesService.openOkPopup('', 'Configuração de favoritos feita com sucesso!')
                this.semMudancas = true;
            }, 
            error: err => {
                this.lightBoxesService.openOkPopup('Atenção!', 'Não foi possível salvar a configuração de favoritos no momento. Tente novamente mais tarde')
                this.fecharModal()
            }
        })
    }

    getEquivalentes(favoritos: any[]) {
        if(favoritos.find(item => (item?.rota as string)?.startsWith('/ocorrencia') && !this.favoritos.find(item => item?.rota?.startsWith('/ocorrencia')))) {
            this.favoritos.push(this.itensBusca.find(item => (item?.rota as string)?.startsWith('/ocorrencia')));
        }

        if(favoritos.find(item => (item?.rota as string)?.startsWith('/mural') && !this.favoritos.find(item => item?.rota?.startsWith('/mural')))) {
            this.favoritos.push(this.itensBusca.find(item => (item?.rota as string)?.startsWith('/mural')));
        }

        if(favoritos.find(item => (item?.rota as string)?.startsWith('/reserva') && !this.favoritos.find(item => item?.rota?.startsWith('/reserva')))) {
            this.favoritos.push(this.itensBusca.find(item => (item?.rota as string)?.startsWith('/reserva') && (item?.rota as string) !== '/reserva-configuracao'));
        }
    }

    handleInputFocus() {
        if(window.innerWidth <= 800) {
            let areaAtalhos = document.getElementsByClassName('area-atalhos')[0];

            areaAtalhos.scrollIntoView({behavior: "smooth", block: 'start'});
        }
    }
    
}