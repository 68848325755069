import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class LightBoxesService {
  private modals: any[] = [];

  add(modal: any) {
    //console.log(modal)
    // add modal to array of active modals
    this.modals.push(modal);
  }

  remove(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter(x => x.id !== id);
  }

  open(id: string, mensagem: { title: string, text: string }, time: number) {

    //console.log(this.modals)
    // open modal specified by id
    const modal = this.modals.find(x => x.id === id);
    return modal.open(mensagem, time);
  }

  close(id: string) {
    // close modal specified by id
    const modal = this.modals.find(x => x.id === id);
    modal.close();
  }
}
