import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { AcessoRapidoService } from "../../services/acesso-rapido.service";
import { StateService } from "../../services/stateService";
import { ItemBusca } from "../../utils/ItemsListaMenu";
@Component({
    selector: 'app-busca-lista-historico',
    styleUrls: ['./busca-itens-historico.component.scss'],
    templateUrl: './busca-itens-historico.component.html'
})
export class BuscaItensHistoricoComponent implements OnInit, OnChanges {

    @ViewChild('inputBusca') inputBusca;

    @Input() estilo: 'atalhos' | 'menu-sidebar' = 'menu-sidebar';
    @Input() focoInput: boolean = false;

    public busca: string = '';
    public itensBusca: ItemBusca[] = [];
    public resultadoBusca: ItemBusca[] = [];
    public exibirResultados: boolean = false;
    public historico: ItemBusca[] = [];

    constructor(private stateService: StateService, private router: Router, private acessoRapidoService: AcessoRapidoService) {}

    ngOnChanges(changes: SimpleChanges): void {
      if(changes.focoInput.currentValue) {

        let areaBusca = document.getElementsByClassName('area-busca')[0];

        if(areaBusca) setTimeout(() => areaBusca.getElementsByTagName('input')[0].focus(), 100);

        // this.inputBusca?.nativeElement.focus();
      }
    }

    ngOnInit(): void {
        this.itensBusca = this.acessoRapidoService.filtraListaBusca();
        this.getHistorico();
    }

    atualizarBusca() {
      this.resultadoBusca = this.itensBusca.filter(
        item => item?.titulo?.toUpperCase().indexOf(this.busca.toUpperCase().trim()) !== -1 || item?.subItem?.toUpperCase().startsWith(this.busca.toUpperCase().trim())
      );
      this.resultadoBusca = this.resultadoBusca.filter(item => item);
    }

    handleClickBusca(item: ItemBusca) {
      if(item) this.setItemHistorico(item?.rota);
      
      if(item?.rota) {
        this.router.navigate([item.rota]);
      }

      if(item?.click) {
        if(item.click === 'comodidades') {
          this.stateService.abrirLinkDeComodidadesDeAcordoComOPerfil()
        }
  
        if(item.click === 'alugar/vender') {
          this.stateService.redirectAlugarVenderPorPerfil();
        }
  
        if(item.click === 'fornecedores') {
          this.stateService.openExternalLink("https://www.revistasindico.com.br/guia");
        }
      }
    }

    setItemHistorico(item: string) {
      this.acessoRapidoService.setItemHistorico(item).subscribe({
        next: res => {},
        error: err => console.log('erro ao setar historico', err)
      })
    }
    
    getHistorico() {
      this.acessoRapidoService.getHistorico().subscribe({
        next: res => {
          if(res && res.length > 0) {
            for(let itemHistorico of res) {
              let itemBusca = this.itensBusca.find(item => item?.rota === itemHistorico?.rota);
              if(itemBusca) this.historico.push(itemBusca);
            }
          }
        },
        error: err => console.log('erro ao recuperar historico', err)
      })
    }

    getTextoDestacado(texto: string, destaque: boolean = false): string {
      let textoBusca = this.busca;

        if(texto.toLowerCase().startsWith(textoBusca)) {
          if(destaque) {
            return textoBusca[0].toUpperCase() + textoBusca.slice(1);
          } else {
            return  texto.slice(textoBusca.length);
          }
        } else {
          if(destaque) {
            return '';
          } else {
            return texto;
          }
        }
    }

    timeOutExibicao() {
      setTimeout(() => { this.exibirResultados = false }, 100)
    }

}