import { StateService } from './../../services/stateService';
import { CondominioService } from 'src/app/shared/services/condominio.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cotas-devidas',
  templateUrl: './cotas-devidas.component.html',
  styleUrls: ['./cotas-devidas.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CotasDevidasComponent implements OnInit {
  public quantidadeCotasEmAberto: number = 0;
  public valorTotal: number;
  public isCollapseOpen: boolean = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private condominioService: CondominioService,
    public stateService: StateService,
  ) {
    this.activatedRoute.params.subscribe(() => {
      this.quantidadeCotasEmAberto = 0;
      this.ngOnInit();
    });
  }

  ngOnInit() {
    if(this.stateService.possuiAcessoFuncionalidadeGeral(848))
    this.CotasDevidas();
  }

  CotasDevidas() {
    this.condominioService.getCotasDevidas().subscribe((obj) => {
      if (obj) {
        this.quantidadeCotasEmAberto = obj.qntdCotasEmAberto;
        this.valorTotal = obj.valorTotal;
      }
      else{
        this.quantidadeCotasEmAberto = 0;
      }
    }, err => {
      this.quantidadeCotasEmAberto = 0;
    });
  }

  toggle() {
    this.isCollapseOpen = !this.isCollapseOpen;
  }

  redirectCotas(){
    this.router.navigate(['/cotas']);
  }
}
