<div class="AdesaoDeBoletoDigitalSelecaoDeUnidade">
  <form>
    <div *ngIf="!todasAsUnidadesAceitas">
      <p class="Paragraph __is-bold">
        Selecione quais unidades deseja aderir ao boleto digital.
      </p>

      <ng-container *ngIf="this.stateService.getPerfisUnidades()">
        <div class="condominios-imoveis__titulo">
          Condomínios
        </div>
  
        <div
          *ngFor="let condominio of listaCondominios; let i = index"
          class="CollapseLight"
          [id]="condominio.contaCondominio"
          [ngClass]="{
            '__is-open': listaCondominios.length === 1 || condominio.isOpen
          }"
        >
          <div
            class="Collapse_Header"
            (click)="toggleCollapse(condominio)"
   
            [ngStyle]="!condominio.isOpen && {'border-radius': '4px'}"
          >
            <div class="Collapse_Header_Title">
              {{ condominio.nomeCondominio }}
            </div>
  
            <div
              class="Collapse_Header_Icon"
              *ngIf="!condominioSelecionado.includes(condominio.contaCondominio)"
            >
              <!-- <i class="fas fa-caret-down"></i> -->
              <!-- <i class="fa-solid fa-angle-right"></i> -->
              <i class="fas fa-chevron-right"></i>
            </div>
          </div>
          <div
            *ngIf="!condominioSelecionado.includes(condominio.contaCondominio)"
            class="Collapse_Content"
 
            [ngStyle]="!condominio.isOpen && {'border': 'none'}"
          >
            <table class="Table_Condominio">
              <tr class="Table_header">
                <th>Apartamento</th>
                <th>Aderir</th>
                <th>Não aderir</th>
              </tr>

              <tr class="Table_content" *ngFor="let unidade of condominio.unidades; let i = index">
                <td>{{ unidade.descricaoUnidade }}</td>
                <td>
                  <input
                    type="radio"
                    class="radio"
                    [checked]="unidade.unidadeAderida"
                    (change)="aderirUnidade(
                      condominio.contaCondominio,
                      unidade.idUnidade
                    )"
                  >
                </td>

                <td>
                  <input
                    type="radio"
                    class="radio"
                    [checked]="!unidade.unidadeAderida"
                    (change)="desaderirUnidade(
                      condominio.contaCondominio,
                      unidade.idUnidade
                    )"
                  >
                </td>
              </tr>

              <tfoot class="Table_footer">
                <tr>
                  <td></td>
                  <td>
                    <a 
                      class="Table_footer_action"
                      (click)='aderirTodas(condominio.contaCondominio)'
                    >
                      marcar todos
                    </a>
                  </td>

                  <td>
                    <a
                      class="Table_footer_action"
                      (click)='naoAderir(condominio.contaCondominio)'
                    >
                      desmarcar todos
                    </a>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </ng-container>
      
      <ng-container *ngIf="this.stateService.tipoPerfilLocatario()">
        <div class="condominios-imoveis">
          <!-- <div class="condominios-imoveis__titulo">
            Imóveis
          </div> -->
          
          <div class="condominios-imoveis__content"
          >
            <table class="Table_Imoveis ">
              <tr class="Table_header">
                <th>Imóveis</th>
                <th>Aderir</th>
                <th>Não aderir</th>
              </tr>

              <!-- Loop vai aqui no TR -->
              <tr class="Table_content" *ngFor="let imovel of imoveisAgrupadosPorContrato; let i = index">
                <td>
                  <p><strong>Contrato de locação: </strong>{{imovel.contratoLocacao}}</p>
                 <p  *ngFor="let endereco of imovel.enderecoImovel"> {{ endereco | titlecase}} </p>
                </td>
                <td>
                  <input
                    type="radio"
                    class="radio"
                    [checked]="imovel.aderido"
                    [name]="imovel.enderecoImovel"
                    (change)="aderirDesaderirImovel(imovel.contratoLocacao, imovel.enderecoImovel, true)"
                  >
                </td>

                <td>
                  <input
                    type="radio"
                    class="radio"
                    [name]="imovel.enderecoImovel"
                    [checked]="!imovel.aderido"
                    (change)="aderirDesaderirImovel(imovel.contratoLocacao, imovel.enderecoImovel, false)"
                  >
                </td>
              </tr>

              <tfoot class="Table_footer">
                <tr>
                  <td></td>
                  <td>
                    <a 
                      class="Table_footer_action"
                      (click)='aderirDesaderirTodosImoveis(true)'
                    >
                      marcar todos
                    </a>
                  </td>

                  <td>
                    <a
                      class="Table_footer_action"
                      (click)='aderirDesaderirTodosImoveis(false)'
                    >
                      desmarcar todos
                    </a>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </ng-container>
      
    </div>
  </form>
</div>