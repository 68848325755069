import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-contatos',
  templateUrl: './contatos.component.html',
  styleUrls: ['./contatos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContatosComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
