import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StateService } from 'src/app/shared/services/stateService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdesaoIptuService {
  private host = environment.apiCondominioDigital.host;
  private methods = environment.apiCondominioDigital.endpoints.methods.optinsUsuario;

  constructor(
    public stateService: StateService,
    private http: HttpClient,
  ) { }

  cadastrarFormaPagamento(payload: any) {
    const url = this.host + this.methods.cadastrarAdesaoIptu;
    return this.http.post(url, payload, { headers: this.getHeader() });
  }

  getFormaDePagamentoIptu() {
    const url = this.host + this.methods.escolhaFormaPagamentoIptu;

    return this.http.get<any>(url, { headers: this.getHeader() });
  }
  
  private getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.stateService.getTokenApiCondominioDigital()
    });
  }
}
