<ion-header *ngIf="stateService.platformIsDeviceMobile() && stateService.platformIsIos() && minHeightIos(); else headerAndroidWeb" >
  <ion-toolbar>
    <header class="Header" >
      <div class="Header_Voltar" (click)="backButton()">
        <i class="apsa-icon-solid apsa-icon-solid-arrow-back"></i>
      </div>

      <!-- <div class="Header_Logo">
        <a routerLink="/home" routerLinkActive="active">
          <img
            src='assets/img/logo_{{caminhoLogoHeader}}.png'
            alt="APSA"
            class="Header_Logo_Mobile"
            *ngIf="this.stateService.getTemaAcessibilidade() != 'Theme_Dark'"
          >
    
          <img
            src='assets/img/logo_branco_{{caminhoLogoHeader}}.png'
            alt="APSA"
            class="Header_Logo_Mobile"
            *ngIf="this.stateService.getTemaAcessibilidade() == 'Theme_Dark'"
          >
        </a>
      </div> -->
      <div class="Header_Logo">
        <a routerLink="/home" routerLinkActive="active">
          <img
            [src]="caminhoLogoHeader"
            [alt]="(customer$ | async)?.nomeApp"
            class="Header_Logo_Mobile"
          >
        </a>
      </div>

      <div
      class="Header_Atalho Header_Atalho_Perfil"
      *ngIf="habilitarIconeModalPessoa()"
    >
      <div class="Header_Atalho_Action " (click)="abrirModalPessoa()">
        <div class="Atalho_Configuracoes_Icon">
          <div class="EmailAdvice" *ngIf="this.stateService.exibirInfoEmailNaoConfirmado()">
            <i class="fas fa-exclamation"></i>
          </div>
  
          <img
            class="user-image"
            [src]="stateService.getUserImageSrcAttribute()"
          >
  
          <!-- <i class="apsa-icon-solid apsa-icon-solid-user"></i> -->
        </div>
  
        <div class="Atalho_Configuracoes_Name">
          {{nomePessoa}}
        </div>
  
        <div class="Atalho_Configuracoes_Arrow">
          <i class="fas fa-chevron-down"></i>
        </div>
      </div>
  
      <div class="Header_Atalho_Popup Header_Atalho_Popup_AlwaysVisible __is-right" *ngIf="menuDoUsuarioAberto">
        <div class="Atalho_Popup_Close" (click)="abrirModalPessoa()">
          <i class="fas fa-times"></i>
        </div>
  
        <div class="Atalho_Popup_Header">
          <div class="Popup_Header_Title">Perfil</div>
  
          <div class="Popup_Header_Subtitle">
            Acesse os principais dados da sua conta
          </div>
        </div>
  
        <app-lista-dados-conta
          (onOpenModalAvaliacao)="abrirModalAvaliacao()"
          [trocaDeCondominio]="trocaDeCondominio"
        >
        </app-lista-dados-conta>
      </div>
    </div>



    </header>
  </ion-toolbar>
</ion-header>

<ng-template #headerAndroidWeb>
  <header class="Header" [ngClass]="{
    'sidebar--open': constant.statusMenu,
    'sidebar--closed': !constant.statusMenu
  }">
    <div class="Header_Voltar" (click)="backButton()">
      <i class="apsa-icon-solid apsa-icon-solid-arrow-back"></i>
    </div>
  
    <!-- <div class="Header_Logo">
      <a routerLink="/home" routerLinkActive="active">
        <img
          src='assets/img/logo_{{caminhoLogoHeader}}.png'
          alt="APSA"
          class="Header_Logo_Mobile"
          *ngIf="this.stateService.getTemaAcessibilidade() != 'Theme_Dark'"
        >
  
        <img
          src='assets/img/logo_branco_{{caminhoLogoHeader}}.png'
          alt="APSA"
          class="Header_Logo_Mobile"
          *ngIf="this.stateService.getTemaAcessibilidade() == 'Theme_Dark'"
        >
      </a>
    </div> -->
    <div class="Header_Logo">
      <a routerLink="/home" routerLinkActive="active">
        <img
          [src]="caminhoLogoHeader"
          [alt]="(customer$ | async)?.nomeApp"
          class="Header_Logo_Mobile"
        >
      </a>
    </div>
    <div
      class="Header_Atalho Header_Atalho_Perfil"
      *ngIf="habilitarIconeModalPessoa()"
    >
      <div class="Header_Atalho_Action" (click)="abrirModalPessoa()">
        <div class="Atalho_Configuracoes_Icon">
          <div class="EmailAdvice" *ngIf="this.stateService.exibirInfoEmailNaoConfirmado()">
            <i class="fas fa-exclamation"></i>
          </div>
  
          <img
            class="user-image"
            [src]="stateService.getUserImageSrcAttribute()"
          >
  
          <!-- <i class="apsa-icon-solid apsa-icon-solid-user"></i> -->
        </div>
  
        <div class="Atalho_Configuracoes_Name">
          {{nomePessoa}}
        </div>
  
        <div class="Atalho_Configuracoes_Arrow">
          <i class="fas fa-chevron-down"></i>
        </div>
      </div>
  
      <div class="Header_Atalho_Popup Header_Atalho_Popup_AlwaysVisible __is-right" *ngIf="menuDoUsuarioAberto">
        <div class="Atalho_Popup_Close" (click)="abrirModalPessoa()">
          <i class="fas fa-times"></i>
        </div>
  
        <div class="Atalho_Popup_Header">
          <div class="Popup_Header_Title">Perfil</div>
  
          <div class="Popup_Header_Subtitle">
            Acesse os principais dados da sua conta
          </div>
        </div>
  
        <app-lista-dados-conta
          (onOpenModalAvaliacao)="abrirModalAvaliacao()"
          [trocaDeCondominio]="trocaDeCondominio"
        >
        </app-lista-dados-conta>
      </div>
    </div>
  
    <div
      class="Header_Atalho Header_Tutorial line-from-center"
      *ngIf="rotaAtual === '/home' && stateService.permitirEntregaExpressa() === 0 "
      routerLink="/tutorial-desktop"
      
    >
      <i class="fas fa-chalkboard-teacher"></i>
      <div class="Header_Atalho_Popup_Hover_Labels_Tutorial" [ngClass]="{'custom-left': !stateService.getCondominioLogado()}" >

        <div class="Just_Label">
          Tutorial
        </div>

      </div>
    </div>
  
    <div class="Header_Atalho Header_Avaliacao line-from-center" (click)="abrirModalAvaliacao()">
      <i class="fas fa-star"></i>
      <div class="Header_Atalho_Popup_Hover_Labels_Avaliacao" [ngClass]="{'custom-left': !stateService.getCondominioLogado()}" >

        <div class="Just_Label">
          Avaliação
        </div>

      </div>
    </div>
  
    <div
      class="Header_Atalho Header_Shortcut Header_PGestao"
      *ngIf="this.stateService.usuarioPossuiAcessoARedeDeParcerias()"
    >
      <div
        class="Header_Atalho_Action line-from-center"
       
        (click)="this.stateService.redirecionarRedeDeParcerias()"
      >
        <div class="Header_Shortcut_Icon">
          <img src="../../../../assets/svg/dashboard-parcerias-header.svg" />
        </div>
      </div>
      <div class="Header_Atalho_Popup_Hover_Labels_PGestão" [ngClass]="{'custom-left': !stateService.getCondominioLogado()}" >

        <div class="Just_Label">
          Painel de gestão
        </div>

      </div>
    </div>
    
  
    <div
      class="Header_Atalho Header_Shortcut Header_Atalhos"
      *ngIf="this.stateService.exibeOpcaoMenuAtalhoHeader()"
    >
      <div
        class="Header_Atalho_Action line-from-center"
        
        (click)="abrirAtalho();validaIsOpen()"
      >
        <div class="Header_Shortcut_Icon">
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
              fill-rule="evenodd" clip-rule="evenodd" d="M2 4C3.10457 4 4 3.10457 4 2C4 0.895431 3.10457 0 2 0C0.895431 0 0 0.895431 0 2C0 3.10457 0.895431 4 2 4ZM10 4C11.1046 4 12 3.10457 12 2C12 0.895431 11.1046 0 10 0C8.89543 0 8 0.895431 8 2C8 3.10457 8.89543 4 10 4ZM20 2C20 3.10457 19.1046 4 18 4C16.8954 4 16 3.10457 16 2C16 0.895431 16.8954 0 18 0C19.1046 0 20 0.895431 20 2ZM2 12C3.10457 12 4 11.1046 4 10C4 8.89543 3.10457 8 2 8C0.895431 8 0 8.89543 0 10C0 11.1046 0.895431 12 2 12ZM4 18C4 19.1046 3.10457 20 2 20C0.895431 20 0 19.1046 0 18C0 16.8954 0.895431 16 2 16C3.10457 16 4 16.8954 4 18ZM19.9538 18.2703C20.8853 16.9939 21.4383 15.4207 21.4383 13.7176C21.4383 9.45438 17.9832 6 13.7191 6C9.45505 6 6 9.45438 6 13.7176C6 17.9809 9.45505 21.4352 13.7191 21.4352C15.4225 21.4352 16.996 20.8861 18.2727 19.9511L22.9709 24.6521C23.4348 25.1159 24.1882 25.1159 24.6521 24.6521C25.116 24.1884 25.116 23.4351 24.6521 22.9713L19.9538 18.2703ZM15.7642 18.6539C15.1158 18.9224 14.4209 19.0606 13.7191 19.0606C13.0173 19.0606 12.3224 18.9224 11.6741 18.6539C11.0257 18.3854 10.4366 17.9918 9.94034 17.4957C9.4441 16.9995 9.05047 16.4105 8.7819 15.7623C8.51334 15.114 8.37512 14.4193 8.37512 13.7176C8.37512 13.016 8.51334 12.3212 8.7819 11.673C9.05047 11.0247 9.4441 10.4357 9.94034 9.93957C10.4366 9.44343 11.0257 9.04987 11.6741 8.78136C12.3224 8.51285 13.0173 8.37465 13.7191 8.37465C14.4209 8.37465 15.1158 8.51285 15.7642 8.78136C16.4126 9.04987 17.0017 9.44343 17.4979 9.93957C17.9942 10.4357 18.3878 11.0247 18.6563 11.673C18.9249 12.3212 19.0631 13.016 19.0631 13.7176C19.0631 14.4193 18.9249 15.114 18.6563 15.7623C18.3878 16.4105 17.9942 16.9995 17.4979 17.4957C17.0017 17.9918 16.4126 18.3854 15.7642 18.6539Z" 
              
            />
          </svg>
          <!-- <img class="atalhos-busca" src="../../../../assets/svg/atalhos-busca.svg" alt="atalhos"> -->
          <!-- <i class="apsa-icon-solid-menu-funcionalidades"></i> -->
        </div>
      </div>
  
      <div class="Header_Atalho_Popup __is-left Header_Shortcut_Popup">
        <div
          class="Atalho_Popup_Close"
          (click)="stateService.openCloseComponent('.Header_Shortcut_Popup'); IconIsOpen(false)"
        >
          <i class="fas fa-times"></i>
        </div>
  
        <div class="Atalho_Popup_Header">
          <div class="Popup_Header_Title">Atalhos</div>
        </div>
  
        <div class="Atalho_Popup_Content Shortcut_Popup_Content">
          <app-lista-menu-mobile
            [possuiPerfilGestorSindico]="possuiPerfilGestorSindico"
            [possuiPerfilProprietario]="possuiPerfilProprietario"
            [ehGestaoTotal]="ehGestaoTotal"
            [possuiPerfilLocatario]="possuiPerfilLocatario"
            [possuiPerfilSindico]="this.possuiPerfilSindico"
            [possuiPerfilSubSindico]="possuiPerfilSubSindico"
            [possuiPerfilConselheiro]="possuiPerfilConselheiro"
            [possuiPerfilCondomino]="possuiPerfilCondomino"
            class="Popup_Content_Menu"
          >
          </app-lista-menu-mobile>
        </div>
      </div>
      <div class="Header_Atalho_Popup_Hover_Labels_Atalhos" *ngIf="this.isOpen === false" [ngClass]="{'custom-left': !stateService.getCondominioLogado()}" >

        <div class="Just_Label">
          Atalhos
        </div>

      </div>
    </div>
  
    <div class="Header_Atalho Header_Atalho_Acessibilidade">
      <div
        class="Header_Atalho_Action line-from-center"
        (click)="stateService.openCloseComponent('.Header_Atalho_Acessibilidade'); validaIsOpen()"
        
      >
        <i *ngIf="(customer$ | async).podeTrocarTemaDeCores" class="Atalho_Action_FakeIcon">Aa</i>
        <i *ngIf="!(customer$ | async).podeTrocarTemaDeCores" class="fas fa-palette"></i>
      </div>
  
      <div class="Header_Atalho_Popup __is-left">
        <div
          class="Atalho_Popup_Close"
          (click)="stateService.openCloseComponent('.Header_Atalho_Acessibilidade'); IconIsOpen(false)"
        >
          <i class="fas fa-times"></i>
        </div>
  
        <div class="Atalho_Popup_Header">
          <div class="Popup_Header_Title">
            Acessibilidade
          </div>
  
          <div class="Popup_Header_Subtitle">
            Ajustes as definições de visualização do sistema
          </div>
        </div>
  
        <div class="Atalho_Popup_Content __has-padding">
          <app-acessibilidade></app-acessibilidade>
        </div>
      </div>
      <div class="Header_Atalho_Popup_Hover_Labels_Acessibilidade" *ngIf="this.isOpen === false" [ngClass]="{'custom-left': !stateService.getCondominioLogado()}" >

        <div class="Just_Label">
          Acessibilidade
        </div>

      </div>
    </div>
    
    <div class="Header_Atalho Header_Atalho_PerguntasRespostas">
      <a routerLink="/perguntas-respostas" routerLinkActive="active">
        <div
          class="Header_Atalho_Action line-from-center"      
          
        >
        <i class="far fa-question-circle" style="font-size: 24px;"></i>
        </div>
      </a>

      
      <div class="Header_Atalho_Popup_Hover_Labels_Perguntas" routerLink="/perguntas-respostas" [ngClass]="{'custom-left': !stateService.getCondominioLogado()}" >

        <div class="Just_Label">
          Perguntas e Respostas
        </div>

      </div>

      <!-- <div class="Header_Atalho_Popup __is-left">
        <div class="Atalho_Popup_Header">
          <div class="Popup_Header_Title">
            Perguntas e Respostas
          </div>        
        </div>
      </div> -->
    </div>
    
    <!-- (click)="stateService.openCloseComponent('.Header_Atalho_Seguros')" -->
    <ng-container *ngIf="customer$ && (customer$ | async)?.exibirFaleComASeguradora">
      <div class="Header_Atalho Header_Atalho_Seguros">
        <div
          class="Header_Atalho_Action"
          routerLink="/seguros"
        >
          <div class="tag-new"></div>
  
          <i
            class="far fa-building"
          >
    
            <div class="overlayed-icon">
              <i class="fas fa-heart"></i>
            </div>
          </i>
        </div>
  
  
        <div class="Header_Atalho_Popup_Hover_Arrow">
          <img 
            *ngIf="stateService.getTemaAcessibilidade() !== 'Theme_Dark'; else triangleDark" 
            src="../../../../assets/img/triangle.png" alt="contrate seu seguro"
          >
          <ng-template #triangleDark>
            <img src="../../../../assets/img/triangle_dark.png" alt="contrate seu seguro">
          </ng-template>
        </div>
  
        <div class="Header_Atalho_Popup_Hover __is-new" routerLink="/seguros" [ngClass]="{'custom-left': !stateService.getCondominioLogado()}" >
          <img 
            *ngIf="stateService.getTemaAcessibilidade() !== 'Theme_Dark'; else imgDark" 
            src="../../../../assets/img/seguros_popup_hover.png" alt="contrate seu seguro"
          >
          
          <ng-template #imgDark>
            <img src="../../../../assets/img/seguros_popup_hover_dark.png" alt="contrate seu seguro">
          </ng-template>
  
          <div class="Header_Atalho_Popup_Hover_Title">
            <h3><strong>Contrate seu seguro!</strong></h3>
          </div>
  
          <div class="Header_Atalho_Popup_Hover_Content">
            <p>Conheça nossos seguros feitos sob medida para o que você precisa!</p>
          </div>
  
        </div>
    
        <!-- <div 
          class="Header_Atalho_Popup __is-left"
          #faleComSeguradora 
        >
          <div
            class="Atalho_Popup_Close"
            (click)="stateService.openCloseComponent('.Header_Atalho_Seguros')"
          >
            <i class="fas fa-times"></i>
          </div>
    
          <div class="Atalho_Popup_Content" >
            <app-fale-com-a-seguradora></app-fale-com-a-seguradora>
          </div>
        </div> -->
      </div>
    </ng-container>
  
    <!--Nova area de contato-->
    <div class="Header_Atalho Header_Atalho_Contato" >
      <div
        class="Header_Atalho_Action line-from-center"
        (click)="stateService.openCloseComponent('.Header_Atalho_Contato'); validaIsOpen()"
        
      >
        <i
          class="fas fa-phone fa-2x"
        >
        </i>
      </div>
  
      <div 
        class="Header_Atalho_Popup __is-left" 
      >
        <div
          class="Atalho_Popup_Close"
          (click)="stateService.openCloseComponent('.Header_Atalho_Contato'); IconIsOpen(false)"
        >
          <i class="fas fa-times"></i>
        </div>
  
        <app-contatos></app-contatos>
  
      </div>
      <div class="Header_Atalho_Popup_Hover_Contato" *ngIf="this.isOpen === false" [ngClass]="{'custom-left': !stateService.getCondominioLogado()}" >

        <div class="Just_Label">
          Contato
        </div>

      </div>
    </div>
    
    <div
      class="Header_Atalho Header_Atalho_CentralAtendimento"
      *ngIf="constant.condominioImovelAtivo"
    >
      <div
        class="Header_Atalho_Action line-from-center"
        (click)="abrirCentralAtendimento(); validaIsOpen()"
        
      >
        <i
          class="apsa-icon-outline apsa-icon-outline-mail"
          (click)="openFaleComAApsa = true"
        >
        </i>
      </div>
  
      <div 
        class="Header_Atalho_Popup __is-left" 
        [ngClass]="{ '__is-sindico': possuiPerfilSindico }"
      >
        <div
          class="Atalho_Popup_Close"
          (click)="stateService.openCloseComponent('.Header_Atalho_CentralAtendimento');IconIsOpen(false)"
        >
          <i class="fas fa-times"></i>
        </div>
  
        <div class="Atalho_Popup_Header">
          <div class="Popup_Header_Title">Fale com a {{ customerService.getCustomer().nomeApp === 'Apsa' ? 'APSA' : customerService.getCustomer().nomeApp.replace('by Apsa', '')  }}</div>
        </div>
  
        <div class="Atalho_Popup_Content">
          <app-fale-com-a-apsa
            #faleComApsa
            [listaAssuntos]="listAssuntos"
            [load]="this.isCentralAtendimentoLoading"
          >
          </app-fale-com-a-apsa>
        </div>
      </div>
      <div class="Header_Atalho_Popup_Hover_Labels_FaleApsa" *ngIf="this.isOpen === false" [ngClass]="{'custom-left': !stateService.getCondominioLogado()}" >

        <div class="Just_Label">
          Fale com a {{ customerService.getCustomer().nomeApp === 'Apsa' ? 'APSA' : customerService.getCustomer().nomeApp.replace('by Apsa', '')  }}
        </div>

      </div>
    </div>
  
    <div
      class="Header_Atalho Header_Atalho_Notificacoes"
      *ngIf="this.stateService.getCondominioLogado()"
    >
      <div
        class="Header_Atalho_Action line-from-center"
        (click)="carregarAlertas(); validaIsOpen()"
        
      >
        <span class="Header_Notificacoes_Contador" *ngIf="totalAlertas > 0">
          {{totalAlertas}}
        </span>
        
        <i class="apsa-icon-outline apsa-icon-outline-bell"></i>
      </div>
  
      <div class="Header_Atalho_Popup __is-left">
        <div
          class="Atalho_Popup_Close"
          (click)="stateService.openCloseComponent('.Header_Atalho_Notificacoes');IconIsOpen(false)"
        >
          <i class="fas fa-times"></i>
        </div>
  
        <div class="Atalho_Popup_Header">
          <div class="Popup_Header_Title">Alertas</div>
        </div>
  
        <div class="Atalho_Popup_Content">
          <app-notificacoes [listNotifications]="listNotifications"></app-notificacoes>
        </div>
      </div>
      <div class="Header_Atalho_Popup_Hover_Labels_Alertas" *ngIf="this.isOpen === false" [ngClass]="{'custom-left': !stateService.getCondominioLogado()}" >

        <div class="Just_Label">
          Alertas
        </div>

      </div>
    </div>
  
    <div class="Header_Exit Header_Atalho" (click)="sairSimOuNao()" >
      <div class="Header_Atalho_Action line-from-center Header_Exit_Icon">
        <i class="apsa-icon-outline-logout"></i>
      </div>
      <div class="Header_Atalho_Popup_Hover_Labels_Exit" [ngClass]="{'custom-left': !stateService.getCondominioLogado()}" >

        <div class="Just_Label">
          Sair
        </div>

      </div>
    </div>
  </header>
</ng-template>
