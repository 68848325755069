import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-floating-whatsapp-button',
  templateUrl: './floating-whatsapp-button.component.html',
  styleUrls: ['./floating-whatsapp-button.component.scss'],
})
export class FloatingWhatsappButtonComponent implements OnInit {
  private subscription: Subscription;
  private routesInWhichItIsVisible = [
    '/login',
    '/quero-me-cadastrar',
  ];
  public isVisible = true;

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.subscription = this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        const route = event.url;
        this.isVisible = this.shouldAppear(route);
      }
    });
  }
  
  ngOnDetroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }
  
  private shouldAppear(currentRoute: string) {
    for(let i = 0; i < this.routesInWhichItIsVisible.length; i++)
      if(currentRoute.startsWith(this.routesInWhichItIsVisible[i])) 
        return true;      

    return false || currentRoute === '/';
  }
}