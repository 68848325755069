<div class="modal-edicao-favoritos" [ngClass]="{'__display': abrirModal}">
    <div class="overlay">
        <div class="content">
            <div class="header">
                <div class="text">
                    <i class="fas fa-edit"></i>
                    Editar favoritos
                </div>
                <div class="close" (click)="fecharModal()">
                    <i class="fa-solid fa-xmark"></i>
                    <div class="voltar">
                        <i class="fas fa-chevron-left"></i>
                        voltar
                    </div>
                </div>
            </div>

            <div class="info-salvar-wrapper">
                <div class="info-condominio">
                    <p class="nome-conta"> {{ getNomeContaCondominio() }} </p>
                    <p class="perfis">{{ getNomePerfisCondLogado() }}</p>
                </div>
                <div (click)="salvarFavoritos()" [ngClass]="{'__disabled': semMudancas}" class="salvar">Salvar</div>
            </div>

            <div class="favoritos-atalhos-wrapper">
                <div class="area-favoritos">
                    <p class="descricao">Escolha até 5 itens favoritos para sua tela de Acesso Rápido. Exclua um atalho para adicionar um novo de outro serviço.</p>
                    <div class="favoritos">
                        <ng-container *ngFor="let favorito of favoritos; let i = index" >
                            <div class="card-favorito">
                                <div class="img-wrapper" >
                                    <ng-container *ngIf="getTipoImg(favorito?.img) === 2 || getTipoImg(favorito?.img) === 3; else icone" >
                                        <div *ngIf="getTipoImg(favorito?.img) === 2" [innerHTML]="favorito?.img" ></div>
                                        <img *ngIf="getTipoImg(favorito?.img) === 3" [src]="favorito?.img" alt="logo">
                                    </ng-container>
        
                                    <ng-template #icone >
                                        <i [class]="favorito?.img" ></i>
                                    </ng-template>
                                </div>
                                <p class="titulo" [ngClass]="{'__small': favorito?.titulo?.length > 28}" > 
                                    {{ favorito?.titulo?.length > 42 ? favorito?.titulo?.slice(0,42) : favorito?.titulo }}{{favorito?.titulo?.length > 42 ? '...' : '' }} 
                                </p>
                                <div *ngIf="this.favoritos.length > 1" (click)="removerFavorito(i)" class="edit delete"><i class="fa-solid fa-minus"></i></div>
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let placeholder of placeholders">
                            <div class="card-placeholder">
                                <p class="titulo">Adicione um atalho</p>
                            </div>
                        </ng-container>
                    </div>
                </div>
    
                <div class="area-atalhos">
                    <p class="titulo">Atalhos</p>
                    <section class="search-area">
                        <i class="fas fa-search"></i>
                        <input type="text" (focusin)="handleInputFocus()" placeholder="Buscar atalho" [(ngModel)]="busca" (ngModelChange)="atualizarBusca()" >
                        <i *ngIf="this.busca" (click)="this.busca = ''" class="fa-solid fa-xmark"></i>
                    </section>
                    <div class="inativos" [ngClass]="{'justify-busca': busca && resultadoBusca.length < 5}" >
                        <ng-container *ngIf="this.busca; else listaInativos" >
                            <ng-container *ngIf="resultadoBusca.length > 0; else semResultados" >
                                <ng-container *ngFor="let inativo of resultadoBusca" >
                                    <div class="card-favorito" [ngClass]="{'__cliente-apsa': customerService.getCustomer().nomeApp === 'Apsa'}" >
                                        <div class="img-wrapper" >
                                            <ng-container *ngIf="getTipoImg(inativo?.img) === 2 || getTipoImg(inativo?.img) === 3; else icone" >
                                                <div *ngIf="getTipoImg(inativo?.img) === 2" [innerHTML]="inativo?.img" ></div>
                                                <img *ngIf="getTipoImg(inativo?.img) === 3" [src]="inativo?.img" alt="logo">
                                            </ng-container>
                
                                            <ng-template #icone >
                                                <i [class]="inativo?.img" ></i>
                                            </ng-template>
                                        </div>
                                        <p class="titulo" [ngClass]="{'__small': inativo?.titulo?.length > 28}" > 
                                            {{ inativo?.titulo?.length > 42 ? inativo?.titulo?.slice(0,42).trim() : inativo?.titulo }}{{inativo?.titulo?.length > 42 ? '...' : '' }} 
                                        </p>
                                        <div *ngIf="this.favoritos.length < 5" (click)="adicionarFavorito(inativo?.rota ? inativo.rota : inativo.click, true)" class="edit add"><i class="fa-solid fa-plus"></i></div>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <ng-template #semResultados >
                                <div class="ListEmpty">
                                    <div class="ListEmpty_Icon">
                                      <i class="fas fa-exclamation-circle"></i>
                                    </div>
                                    
                                    <div class="ListEmpty_Text">
                                      Nenhum item encontrado
                                    </div>
                                  </div>
                            </ng-template>

                        </ng-container>
        
                        <ng-template #listaInativos>
                            <ng-container *ngFor="let inativo of inativos;let i = index" >
                                <div class="card-favorito"  [ngClass]="{'__cliente-apsa': customerService.getCustomer().nomeApp === 'Apsa'}" >
                                    <div class="img-wrapper" >
                                        <ng-container *ngIf="getTipoImg(inativo?.img) === 2 || getTipoImg(inativo?.img) === 3; else icone" >
                                            <div *ngIf="getTipoImg(inativo?.img) === 2" [innerHTML]="inativo?.img" ></div>
                                            <img *ngIf="getTipoImg(inativo?.img) === 3" [src]="inativo?.img" alt="logo">
                                        </ng-container>
            
                                        <ng-template #icone >
                                            <i [class]="inativo?.img" ></i>
                                        </ng-template>
                                    </div>
                                    <p class="titulo" [ngClass]="{'__small': inativo?.titulo?.length > 28}" >
                                        {{ inativo?.titulo?.length > 42 ? inativo?.titulo?.slice(0,42).trim() : inativo?.titulo }}{{inativo?.titulo?.length > 42 ? '...' : '' }} 
                                    </p>
                                    <div *ngIf="this.favoritos.length < 5" class="edit add" (click)="adicionarFavorito(inativo?.rota ? inativo.rota : inativo?.click)"><i class="fa-solid fa-plus"></i></div>
                                </div>
                            </ng-container>
                        </ng-template>
                    </div>
                </div>
            </div>

            <div *ngIf="innerWidth < 800" class="toast-add-remove mobile" [ngClass]="{'__show': showToast}" >
                <div class="icon-area" [ngClass]="{'__bg-green':textoAdicionarRemover.includes('adicionado'), '__bg-red':textoAdicionarRemover.includes('removido')}" >
                    <i [ngClass]="{'fa-solid fa-plus': textoAdicionarRemover.includes('adicionado'), 'fa-solid fa-minus': textoAdicionarRemover.includes('removido') }" ></i>
                </div>
                <div class="text-area">
                    {{ textoAdicionarRemover }}
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="innerWidth > 800" class="toast-add-remove desktop" [ngClass]="{'__show': showToast}" >
        <div class="icon-area" [ngClass]="{'__bg-green':textoAdicionarRemover.includes('adicionado'), '__bg-red':textoAdicionarRemover.includes('removido')}" >
            <i [ngClass]="{'fa-solid fa-plus': textoAdicionarRemover.includes('adicionado'), 'fa-solid fa-minus': textoAdicionarRemover.includes('removido') }" ></i>
        </div>
        <div class="text-area">
            {{ textoAdicionarRemover }}
        </div>
    </div>

</div>