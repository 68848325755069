import { Platform } from '@ionic/angular';
import { AvaliacaoLojaComponent } from './../avaliacao-loja-app/avaliacao.component';
import { Component, OnInit, ViewEncapsulation, EventEmitter, AfterViewChecked, Output, ViewChild, ElementRef, OnChanges, SimpleChanges, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from '../../services/constants.service';
import { StateService } from '../../services/stateService';
import { ModalService } from '../../services/modal.service';
import { CondominioService } from '../../services/condominio.service';
import { LightBoxesService } from '../lightboxes';
import { HomeService } from 'src/app/modules/home/services/home.service';
// import { Device } from '@ionic-native/device/ngx';
import { LoginService } from 'src/app/modules/login/services/login.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../services/user.service';
import { ProfilePictureFacade } from '../../facades/profile-picture.facade';
import { Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { Device } from '@capacitor/device';
import { BiometryService } from '../../services/biometry.service';
import CustomerService from '../../services/customer.service';



@Component({
  selector: 'app-lista-dados-conta',
  templateUrl: './lista-dados-conta.component.html',
  styleUrls: ['./lista-dados-conta.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListaDadosContaComponent implements OnInit, OnChanges, OnDestroy {

  @Input() trocaDeCondominio;
  @Output() abreModalAdesao = new EventEmitter();
  @Output() abreModalAdesaoIPTU = new EventEmitter();
  @Output() onOpenModalAvaliacao = new EventEmitter();
  @ViewChild('fileInput') fileInputEl: ElementRef;

  public exibirWpp: boolean = false;
  public showFingerprintItem: boolean = false;
  public isFingerprintEnabled$ = this.BiometryService.isBiometryEnabledByUserAsObservable();
  public condominioValidado: boolean
  public anoVigenciaIPTU!:string
  public subscription?: Subscription;
  public exibirCartaoDeCreditoLocatario: boolean = false;
  public descricaoRecorrenciaCartaoDeCreditoLocacao: string = '';
  public descricaoRecorrenciaCartaoDeCreditoCondominio: string = '';
  public customer = this.customerService.getCustomer();
  public customer$ = this.customerService.getCustomerObservable();
  
  constructor(
    private router: Router,
    public constant: ConstantsService,
    public stateService: StateService,
    public modalService: ModalService,
    public condominioService: CondominioService,
    public lightboxesService: LightBoxesService,
    private homeService: HomeService,
    // private device: Device,
    private loginService: LoginService,
    public avaliacaoLoja: AvaliacaoLojaComponent,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    public profilePictureFacade: ProfilePictureFacade,
    public platform: Platform,
    private lightBoxesService: LightBoxesService,
    private BiometryService: BiometryService,
    public customerService: CustomerService,
  ) {
   }

  async ngOnInit() {
    this.condominioValidado = this.validarCondominio();
    this.anoVigenciaIPTU = JSON.parse(sessionStorage.getItem('anoVigenciaIPTU'))
    this.definirSeDeveExibirAFuncaoDeBiometriaOuNao();
    this.subscription = this.constant.getCondominioImovelAtivoAsObservable().pipe(throttleTime(100)).subscribe(menu => {
      if(menu) {
        this.exibirCartaoDeCreditoLocatario = this.stateService.possuiPerfilLocatarioEmAlgumImovel();
  
        if(this.stateService.possuiAcessoFuncionalidadeGeral(872) && this.exibirCartaoDeCreditoLocatario) {
          this.descricaoRecorrenciaCartaoDeCreditoLocacao = 'Pagamento automático em cartão de crédito (Locação)';
          this.descricaoRecorrenciaCartaoDeCreditoCondominio = 'Pagamento automático em cartão de crédito (Condominial)';
        }
        else {
          this.descricaoRecorrenciaCartaoDeCreditoLocacao = 'Pagamento automático em cartão de crédito';
          this.descricaoRecorrenciaCartaoDeCreditoCondominio = 'Pagamento automático em cartão de crédito';
        }
      }
      else {
        this.exibirCartaoDeCreditoLocatario = false;
      }
    });
  }

  ngOnDestroy(): void {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.trocaDeCondominio.currentValue) {
      this.condominioValidado = this.validarCondominio();
      this.definirSeDeveExibirAFuncaoDeBiometriaOuNao();
    }
  }
  
  async definirSeDeveExibirAFuncaoDeBiometriaOuNao() {
    this.showFingerprintItem = await this.BiometryService.isBiometryAvaiable();
  }

  validarCondominio(): boolean{
    return this.stateService.hasCondominioSelecionado()
  }

  get obterLojaApp() {
    const linkLoja: string = this.platform.is('ios') ? 
      'https://apps.apple.com/br/app/apsa/id1171170928' 
      : 'https://play.google.com/store/apps/details?id=com.apsa&hl=pt_BR'

    const nomeLoja: string = this.platform.is('ios') ? 'App Store' : "Google Play Store"

    return {
      linkLoja,
      nomeLoja
    }
  }

  avaliacaoApp(){
    if(this.stateService.platformIsDeviceMobile()) {
      this.stateService.exibirModalDeAvaliacaoMobile(true)
      return
    }
    this.stateService.exibirModalDeAvaliacao()
  }

  exibirOpcaoIPTU(){
    return JSON.parse(sessionStorage.getItem('possuiImoveisIPTU')) === true && !this.stateService.hasCondominioSelecionado() && !this.stateService.visaoCentralAtendimento();
  }

  sair() {
    sessionStorage.clear();
    this.constant.condominioImovelAtivo = null;
    this.router.navigate(['/login', { 'exitFromLogout': true }]);
    return;
  }

  redirect() {
    if (this.router.url !== '/descontos-parceiros') {
     // this.router.navigate(['/descontos-parceiros']);
     this.stateService.abrirLinkDeComodidadesDeAcordoComOPerfil()
    }
    else {
      this.modalService.close('app-dados-conta');
    }
  }

  redirectEditUserData() {
    this.router.navigate(['/usuario/edicao-dados']);
    this.stateService.openCloseComponent('.Header_Atalho_Perfil');
  }
  
  // handleWhatsappOptionClick() {
  //   this.stateService.openExternalLink('https://wa.me/5521987862462');
  // }

  handleWhatsappOptionClick() {
    const customer = this.customerService.getCustomer();

    var nome = this.stateService.getPessoaLogada().Nome;
    this.stateService.openExternalLink(customer.atendimentoViaWhatsApp.url);
  }

  handleFornecedoresOptionClick() {
    this.stateService.openExternalLink('https://www.revistasindico.com.br/guia');
  }

  handleTutorial(){
    this.router.navigate(['/home'], {queryParams: {'verTutorial': true}});
  }

  permitirEditarDadosUsuario(): boolean {
    if (this.stateService.visaoCentralAtendimentoPessoa()) {
      if (sessionStorage.getItem('pessoaLogadaCA') == sessionStorage.getItem('pessoaLogada')) {
        return false;
      } else {
        return this.stateService.possuiAcessoFuncionalidadeGeral(837);
      }
    } else {
      return true;
    }
  }

  exibeFuncaoAvalieApp() : boolean{
    return this.stateService.platformIsDevice() && this.stateService.hasCondominioSelecionado()
  }

  async enableFingerprintLoginFeature() {
    this.BiometryService.registerFingerprintBiometry();
  }

  async disableFingerprintLoginFeature() {
    this.BiometryService.removeFingerprintBiometry();
  }

  openFailurePopupAboutFingerprint(errorMessage: string) {
    this.lightboxesService.open(
      'custom-generico',
      {
        title: 'Atenção',
        text: errorMessage,
      },
      20_000,
    );
  }

  enviarEmailConfirmacao(){
    this.loginService.enviarEmailConfirmacao().subscribe(item =>{
      this.lightboxesService.open(
        'custom-generico',
        { title: 'E-mail enviado', text: item },
        50000
      );
    }, err=>{
      this.lightboxesService.open(
        'custom-generico',
        { title: 'E-mail enviado', text: err.error.message },
        50000
      );
    });
  }

  redirectAdesaoBoletoDigital() {
      if(this.stateService.platformIsDesktop()) {
        this.router.navigate(['/adesao-de-boleto-digital']);
      }
      else {
        this.abreModalAdesao.emit();
        
        var container = document.querySelectorAll('.Container');
        
        window.setTimeout(function () {
          for (var i = 0; i < container.length; i++) {
            container[i].classList.add('__has-modal')
          }
        }, 290);
      }
    }

  // redirectAdesaoIPTU() {
  //   if(this.stateService.platformIsDesktop()) {
  //     this.router.navigate(['/adesao-iptu']);
  //   }
  //   else {
  //     this.abreModalAdesaoIPTU.emit();
      
  //     var container = document.querySelectorAll('.Container');
      
  //     window.setTimeout(function () {
  //       for (var i = 0; i < container.length; i++) {
  //         container[i].classList.add('__has-modal')
  //       }
  //     }, 290);
  //   }
  // }
    
  routeToRecorrenciaCartaoCredito() {
    this.router.navigate(['/recorrencia-cartao-credito/gerenciar-adesao-recorrencia']);
    this.stateService.openCloseComponent('.Header_Atalho_Perfil');
  }

  routeToRecorrenciaCartaoCreditoLocatario() {
    this.router.navigate(['/pagamentos/gerenciar-recorrencia-locatario']);
    this.stateService.openCloseComponent('.Header_Atalho_Perfil');
  }

  platformIsDesktop(): boolean {
    return this.stateService.platformIsDesktop();
  }

  handleTrocarFotoDoPerfilClick() {
    const fileInput = this.fileInputEl.nativeElement as HTMLElement;

    fileInput.click();
  }

  handleTrocarFotoDoPerfilFileChange(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length > 0) {
      const photo = event.target.files[0];
      const fileName = this.getFileName(event.target.value);

      reader.readAsDataURL(photo);
      reader.onload = () => {
        const fileContent = reader.result.toString().split(',')[1];

        this.profilePictureFacade.change(fileName, fileContent);

        (event.target as HTMLInputElement).value = null;
      }
    }
  }

  private getFileName(fileNameGeneratedByBrowser: string) {
    const fileName = fileNameGeneratedByBrowser;
    const nameLength = fileNameGeneratedByBrowser.length;

    if(fileName.includes('fakepath'))
      return fileName.substring(fileName.lastIndexOf("\\") + 1, nameLength);
    else
      return fileName;
  }
}
