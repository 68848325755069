<div class="AdesaoDeBoletoDigital">
  <h1 class="title">Olá, {{ nome }}!</h1>

  <p class="Paragraph">
    Para sua comodidade, a {{ customerService.getCustomer().nomeApp === 'Apsa' ? 'APSA' : customerService.getCustomer().nomeApp.replace('by Apsa', '') }} está oferecendo a adesão ao boleto digital, uma
    maneira mais prática e sustentável de realizar o seu pagamento!
  </p>

  <p class="Paragraph">
    Ao aderir, não enviaremos o boleto impresso para a sua residência.
  </p>

  <adesao-de-boleto-digital-selecao-de-unidades
    [listaCondominios]="listaCondominios"
    [listaImoveis]="listaImoveis"
    [imoveisAgrupadosPorContrato]="imoveisAgrupadosPorContrato"
    (closeModal)='closeModal()'
    (listaImoveisEditada)="enviarListaEditada($event)"
  >
  </adesao-de-boleto-digital-selecao-de-unidades>

  <p class="Paragraph-switch-config">Você pode sempre alterar essas configurações <span (click)="openTutorial()"> seguindo esses passos.</span></p>


</div>
