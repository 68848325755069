import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { SaldoContasDTO } from 'src/app/modules/saldo-em-contas/services/DTO/response/SaldoContas.DTO';
import { SaldoEmContasService } from 'src/app/modules/saldo-em-contas/services/saldo-em-contas.service';
import { CondominioService } from '../../services/condominio.service';
import { StateService } from '../../services/stateService';

@Component({
  selector: 'app-saldo-em-contas-desktop',
  templateUrl: './saldo-em-contas-desktop.component.html',
  styleUrls: ['./saldo-em-contas-desktop.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SaldoEmContasDesktopComponent implements OnInit, OnDestroy {
  public saldoEmContas: SaldoContasDTO[];
  public subscription: Subscription;

  constructor(
    private saldoEmContasService: SaldoEmContasService,
    private condominioService: CondominioService,
    public stateService: StateService,
  ) { }

  ngOnInit() {
    this.fetchData();
    this.subscription = CondominioService.trocaCondominio.subscribe(_ => {
      this.fetchData();
    });
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  fetchData() {
    this.getSaldoEmContas();
  }
  
  getSaldoEmContas() {
    this.saldoEmContasService.getListaSaldoConta().subscribe(response => {
      this.saldoEmContas = response;
    });
  }

  
  possuiPermissaoAcesso(idFuncionalidade: number) {
    return this.stateService.possuiAcessoFuncionalidade(idFuncionalidade);
  }
}
