<div class="ListaMenuMobileComponent">

  <section class="search-area">
    <i class="fas fa-search"></i>
    <input type="text" placeholder="O que você está buscando?" [(ngModel)]="busca" (ngModelChange)="atualizarBusca()" >
    <i *ngIf="this.busca" (click)="this.busca = ''" class="fa-solid fa-xmark"></i>
  </section>


  <ng-container *ngIf="this.busca" >
    <div class="ListCards __is-grid">
      <ng-container *ngIf="resultadoBusca.length > 0; else semResultados">
        <div class="ListCards_Wrapper"  >
            <div
              *ngFor="let item of resultadoBusca"
              class="ListCards_Wrapper_Card __is-new "
              [ngClass]="{
                '__is-new': item?.rota == '/cotas/visualizacaoIndividual' || item?.titulo === 'Quero alugar/vender' ||
                item?.rota == '/envio-pagamentos' || item?.titulo === 'Quero contratar um seguro' || item?.titulo === 'Fornecedores',
                '__is-highlighted': item?.titulo === 'Comodidades para você!' || item?.rota === '/extrato/sindico' || item?.rota === '/extrato/proprietario' || 
                item?.rota === '/documento' || item?.rota === '/prestacao-contas' || item?.rota === '/dados-condominio' || item?.rota === '/minhas-solicitacoes'
              }" 
            >
              <a [routerLink]="item?.rota" routerLinkActive="active" (click)="handleClickBusca(item?.click)">
                <div class="Wrapper_Card_Icon" *ngIf="getTipoImg(item?.img) === 2 || getTipoImg(item?.img) === 3; else icone" >
                  <div *ngIf="getTipoImg(item?.img) === 2" [innerHTML]="item?.img" ></div>
                  <img *ngIf="getTipoImg(item?.img) === 3" [src]="item?.img" alt="logo">
                </div>
    
                <ng-template #icone>
                  <i [class]="item?.img" style="color: #fff; font-size: 24px" ></i>
                </ng-template>
        
                <div class="Wrapper_Card_Title" [innerHTML]="item?.titulo"></div>
              </a>
            </div>
        </div>
      </ng-container>

      <div class="voltar" (click)="this.busca = ''" *ngIf="this.resultadoBusca.length > 0" >
        <i class="fas fa-chevron-left"></i>
        <p>Voltar</p>
      </div>

      <ng-template #semResultados >
        <div class="ListEmpty">
          <div class="ListEmpty_Icon">
            <i class="fas fa-exclamation-circle"></i>
          </div>
          <div class="ListEmpty_Text">
            Nenhum item foi encontrado

            <div class="voltar" (click)="this.busca = ''" >
              <i class="fas fa-chevron-left"></i>
              <p>Voltar</p>
            </div>

          </div>
        </div>
      </ng-template>

    </div>
  </ng-container>

  <ng-container *ngIf="!this.busca" >
    <ng-container *ngLet="isListDesign$ | async as isListDesign">
      <div *ngIf="!isListDesign" class="ListCards __is-grid">
        <div class="ListCards_Wrapper" *ngIf="stateService.possuiApenasPerfilCondomino(); else naoEhCondomino">
          
          <div class="ListCards_Wrapper_Card __is-new ">
            <a routerLink="/cotas/visualizacaoIndividual" routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <img src="../../../../assets/icon/segunda-via-boletos-primary.svg" alt="logo segunda via boletos">
              </div>
  
              <div class="Wrapper_Card_Title">2ª via de boletos</div>
            </a>
          </div>
          
          <ng-container  *ngIf="(customer$ | async).comodidadesParaVoce.exibir">
            <div
              *ngIf="
                (this.stateService.getCondominioLogado() &&
                  this.stateService.possuiAcessoFuncionalidadeGeral(828)) ||
                (this.stateService.possuiAcessoFuncionalidadeGeral(828) &&
                  this.possuiPerfilLocatario) ||
                (this.stateService.possuiAcessoFuncionalidadeGeral(828) &&
                  this.possuiPerfilProprietario)
              "
              class="ListCards_Wrapper_Card __is-highlighted ComodidadesParaVoce"
            >
              <a (click)="handleClickComodidades()" routerLinkActive="active">
                <div class="Wrapper_Card_Icon">
                  <span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                  </span>
                </div>
    
                <div class="Wrapper_Card_Title">Comodidades para você!</div>
              </a>
            </div>
          </ng-container>
          
          <div
            *ngIf="possuiPermissaoAcesso(219) || possuiPermissaoAcesso(220)"
            class="ListCards_Wrapper_Card"
          >
            <a routerLink="/cotas" routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-cotas">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                  <span class="path5"></span>
                </span>
              </div>
              <div
                *ngIf="
                  this.stateService.possuiApenasPerfilCondomino() ||  this.stateService.possuiPerfilProcuradorParaEmissaoBoleto();
                  then segundaVia;
                  else cotas
                "
              ></div>
              <ng-template #segundaVia>
                <div class="Wrapper_Card_Title">2ª via de boletos</div>
              </ng-template>
              <ng-template #cotas>
                <div class="Wrapper_Card_Title">Visualizar cotas</div>
              </ng-template>
            </a>
          </div>
  
          <div
            *ngIf="possuiPermissaoAcesso(218)"
            class="ListCards_Wrapper_Card __is-highlighted"
          >
            <a routerLink="/extrato/sindico" routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-extrato">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                  <span class="path5"></span>
                  <span class="path6"></span>
                  <span class="path7"></span>
                  <span class="path8"></span>
                  <span class="path9"></span>
                </span>
              </div>
              <div class="Wrapper_Card_Title">Extrato</div>
            </a>
          </div>
  
          <div
            *ngIf="possuiPermissaoAcesso(810)"
            class="ListCards_Wrapper_Card __is-highlighted"
          >
            <a routerLink="/documento" routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-malotes">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </span>
              </div>
  
              <div class="Wrapper_Card_Title">Normas e Documentos</div>
            </a>
          </div>
  
          <div class="ListCards_Wrapper_Card __is-new AluguelCompraEVenda">
            <a
              (click)="stateService.redirectAlugarVenderPorPerfil()"
            >
              <div class="Wrapper_Card_Icon">
                <i
                  class="far fa-building"
                  style="color: #fff; font-size: 24px"
                ></i>
              </div>
  
              <div class="Wrapper_Card_Title">
                Quero<br />
                alugar/vender
              </div>
            </a>
          </div>
  
          <div
            class="ListCards_Wrapper_Card __is-highlighted"
            *ngIf="
              possuiPermissaoAcesso(215) ||
              possuiPermissaoAcesso(216) ||
              possuiPermissaoAcesso(217)
            "
          >
            <a routerLink="/prestacao-contas" routerLinkActive="active">
              <i class="fas fa-coins" style="color: #fff; font-size: 24px"></i>
              <div class="Wrapper_Card_Title">Prestação de contas</div>
            </a>
          </div>
  
          <div
            *ngIf="
              this.ehGestaoTotal &&
              (possuiPermissaoAcesso(286) ||
                possuiPermissaoAcesso(294) ||
                possuiPermissaoAcesso(304))
            "
            class="ListCards_Wrapper_Card"
          >
            <a routerLink="/reserva" routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-reservas1">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                </span>
              </div>
              <div class="Wrapper_Card_Title">Reservas</div>
            </a>
          </div>
  
          <div
            *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(823)"
            class="ListCards_Wrapper_Card"
          >
            <a routerLink="/reserva-conv" routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-reservas1">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                </span>
              </div>
              <div class="Wrapper_Card_Title">Reservas</div>
            </a>
          </div>
  
          <div
            *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(816)"
            class="ListCards_Wrapper_Card"
          >
            <a routerLink="/ocorrencia" routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-fale-com-gestor"
                  ><span class="path1"></span><span class="path2"></span
                  ><span class="path3"></span><span class="path4"></span
                  ><span class="path5"></span><span class="path6"></span
                  ><span class="path7"></span
                ></span>
              </div>
              <div
                *ngIf="possuiPerfilGestorSindico; else faleComOsindico"
                class="Wrapper_Card_Title"
              >
                Ocorrências
              </div>
              <ng-template #faleComOsindico>
                <div class="Wrapper_Card_Title">Fale com o Síndico</div>
              </ng-template>
            </a>
          </div>
  
          <div
            *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(285)"
            class="ListCards_Wrapper_Card"
          >
            <a routerLink="/ocorrenciaGT" routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-fale-com-gestor">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                  <span class="path5"></span>
                  <span class="path6"></span>
                  <span class="path7"></span>
                </span>
              </div>
              <div
                *ngIf="possuiPerfilGestorSindico; else faleComOsindico"
                class="Wrapper_Card_Title"
              >
                Ocorrências
              </div>
              <ng-template #faleComOsindico>
                <div class="Wrapper_Card_Title">Fale com o Síndico</div>
              </ng-template>
            </a>
          </div>
  
          <div *ngIf="possuiPermissaoAcesso(811)" class="ListCards_Wrapper_Card">
            <a routerLink="/enquetes" routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-enquete">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </span>
              </div>
              <div class="Wrapper_Card_Title">Enquetes</div>
            </a>
          </div>
  
          <div
            class="ListCards_Wrapper_Card"
            *ngIf="!ehGestaoTotal && possuiPermissaoAcesso(814)"
          >
            <a routerLink="/mural" routerLinkActive="active">
              <div class="Wrapper_Card_Icon __not-colorful">
                <i class="apsa-icon-solid-mural Card_Icon_SolidAsColorfull"></i>
              </div>
              <div class="Wrapper_Card_Title">Mural</div>
            </a>
          </div>
  
          <div *ngIf="possuiPermissaoAcesso(211)" class="ListCards_Wrapper_Card">
            <a routerLink="/condomino" routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-condominio-digital">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                  <span class="path5"></span>
                  <span class="path6"></span>
                  <span class="path7"></span>
                  <span class="path8"></span>
                  <span class="path9"></span>
                  <span class="path10"></span>
                  <span class="path11"></span>
                  <span class="path12"></span>
                  <span class="path13"></span>
                  <span class="path14"></span>
                </span>
              </div>
              <div class="Wrapper_Card_Title">Condôminos</div>
            </a>
          </div>
  
          <div *ngIf="possuiPermissaoAcesso(227)" class="ListCards_Wrapper_Card">
            <a routerLink="/funcionario" routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-funcionarios"
                  ><span class="path1"></span><span class="path2"></span
                  ><span class="path3"></span
                ></span>
              </div>
              <div class="Wrapper_Card_Title">Funcionário</div>
            </a>
          </div>
  
          <div
            *ngIf="possuiPermissaoAcesso(205)"
            class="ListCards_Wrapper_Card __is-highlighted"
          >
            <a routerLink="/dados-condominio" routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-sobre-condominio-colorido">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                  <span class="path5"></span>
                  <span class="path6"></span>
                  <span class="path7"></span>
                  <span class="path8"></span>
                  <span class="path9"></span>
                  <span class="path10"></span>
                  <span class="path11"></span>
                  <span class="path12"></span>
                  <span class="path13"></span>
                  <span class="path14"></span>
                  <span class="path15"></span>
                  <span class="path16"></span>
                  <span class="path17"></span>
                  <span class="path18"></span>
                  <span class="path19"></span>
                  <span class="path20"></span>
                  <span class="path21"></span>
                  <span class="path22"></span>
                  <span class="path23"></span>
                  <span class="path24"></span>
                  <span class="path25"></span>
                  <span class="path26"></span>
                  <span class="path27"></span>
                  <span class="path28"></span>
                  <span class="path29"></span>
                  <span class="path30"></span>
                  <span class="path31"></span>
                  <span class="path32"></span>
                </span>
              </div>
              <div class="Wrapper_Card_Title">Dados do Condomínio</div>
            </a>
          </div>
        </div>
  
        <ng-template #naoEhCondomino>
          <ng-container *ngIf="hasNoModuleSelected()">
            <div class="ListCards_Wrapper">
  
              <div *ngIf="stateService.possuiPerfilCondomino()" class="ListCards_Wrapper_Card __is-new ">
                <a routerLink="/cotas/visualizacaoIndividual" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <img src="../../../../assets/icon/segunda-via-boletos-default.svg" alt="logo segunda via boletos">
                  </div>
      
                  <div class="Wrapper_Card_Title">2ª via de boletos</div>
                </a>
              </div>
              <ng-container  *ngIf="(customer$ | async)?.comodidadesParaVoce.exibir">
                <div
                  *ngIf="
                    (this.stateService.getCondominioLogado() &&
                      this.stateService.possuiAcessoFuncionalidadeGeral(828)) ||
                    (this.stateService.possuiAcessoFuncionalidadeGeral(828) &&
                      this.possuiPerfilLocatario) ||
                    (this.stateService.possuiAcessoFuncionalidadeGeral(828) &&
                      this.possuiPerfilProprietario)
                  "
                  class="ListCards_Wrapper_Card __is-highlighted ComodidadesParaVoce"
                >
                  <a (click)="handleClickComodidades()" routerLinkActive="active">
                    <div class="Wrapper_Card_Icon">
                      <span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                        <span class="path4"></span>
                      </span>
                    </div>
    
                    <div class="Wrapper_Card_Title">Comodidades para você!</div>
                  </a>
                </div>
              </ng-container>
              
              <div
                *ngIf="possuiPermissaoAcesso(219) || possuiPermissaoAcesso(220)"
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/cotas" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-cotas">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                      <span class="path5"></span>
                    </span>
                  </div>
                  <div
                    *ngIf=" this.stateService.possuiPerfilProcuradorParaEmissaoBoleto();
                      then segundaVia;
                      else cotas
                    "
                  ></div>
                  <ng-template #segundaVia>
                    <div class="Wrapper_Card_Title">2ª via de boletos</div>
                  </ng-template>
                  <ng-template #cotas>
                    <div class="Wrapper_Card_Title">Visualizar cotas</div>
                  </ng-template>
                </a>
              </div>
  
              <div
                *ngIf="possuiPermissaoAcesso(306)"
                class="ListCards_Wrapper_Card EnvioDeDocumentos __is-new"
              >
                <a routerLink="/envio-pagamentos" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <img
                      src="../../../assets/img/envioDePagamentosAtalho.png"
                      alt="icone envio de pagamentos"
                    />
                    <!-- <span class="apsa-icon-colorido-envio-documentos">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                      <span class="path5"></span>
                      <span class="path6"></span>
                    </span> -->
                  </div>
                  <div class="Wrapper_Card_Title">Envio de Pagamentos</div>
                </a>
              </div>
  
              <div
                *ngIf="possuiPermissaoAcesso(306)"
                class="ListCards_Wrapper_Card EnvioDeDocumentos"
              >
                <a routerLink="/envio-documento" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-envio-documentos">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                      <span class="path5"></span>
                      <span class="path6"></span>
                    </span>
                  </div>
                  <div class="Wrapper_Card_Title">Envio de Documentos</div>
                </a>
              </div>
  
              <div
                *ngIf="
                  this.stateService.permitirEntregaExpressa() != 0 &&
                  possuiPermissaoAcessoValidacaoCentralAtendimento(842)
                "
                class="ListCards_Wrapper_Card ChameAMoto"
              >
                <a routerLink="/chame-moto" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-motorcycle">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                      <span class="path5"></span>
                      <span class="path6"></span>
                      <span class="path7"></span>
                      <span class="path8"></span>
                      <span class="path9"></span>
                      <span class="path10"></span>
                    </span>
                  </div>
                  <div class="Wrapper_Card_Title">Chame a Moto</div>
                </a>
              </div>
  
              <div
                *ngIf="
                  possuiPerfilSindico ||
                  possuiPerfilConselheiro ||
                  possuiPerfilSubSindico ||
                  possuiPerfilProprietario ||
                  possuiPerfilLocatario
                "
                class="ListCards_Wrapper_Card __is-new SegurosObrigatorios"
              >
                <a (click)="redirectSeguros()" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <i
                      class="far fa-building"
                      style="color: #fff; font-size: 24px"
                    ></i>
  
                    <div class="overlayed-icon">
                      <i class="fas fa-heart"></i>
                    </div>
                  </div>
  
                  <div class="Wrapper_Card_Title">Quero contratar um seguro</div>
                </a>
              </div>
  
              <div
                *ngIf="
                  stateService.possuiPerfilSindico() ||
                  stateService.possuiPerfilConselheiro() ||
                  stateService.possuiPerfilSubSindico() ||
                  stateService.possuiPerfilProprietario() ||
                  stateService.possuiPerfilLocatario() ||
                  stateService.possuiPerfilCondomino()
                "
                class="ListCards_Wrapper_Card __is-new AluguelCompraEVenda"
              >
                <a
                  (click)="stateService.redirectAlugarVenderPorPerfil()"
                >
                  <div class="Wrapper_Card_Icon">
                    <i
                      class="far fa-building"
                      style="color: #fff; font-size: 24px"
                    ></i>
                  </div>
  
                  <div class="Wrapper_Card_Title">
                    Quero<br />
                    alugar/vender
                  </div>
                </a>
              </div>
  
              <div
                *ngIf="
                  possuiPerfilSindico ||
                  possuiPerfilSubSindico ||
                  possuiPerfilConselheiro
                "
                class="ListCards_Wrapper_Card __is-new Fornecedores"
              >
                <a
                  (click)="
                    stateService.openExternalLink(
                      'https://www.revistasindico.com.br/guia'
                    )
                  "
                >
                  <div class="Wrapper_Card_Icon">
                    <i
                      class="fas fa-tools"
                      style="color: #fff; font-size: 24px"
                    ></i>
                  </div>
  
                  <div class="Wrapper_Card_Title">Fornecedores</div>
                </a>
              </div>
  
              <div
                *ngIf="this.validaExibicaoContrato()"
                class="ListCards_Wrapper_Card Contratos"
              >
                <a routerLink="/contratos" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <i
                      class="fas fa-file-signature"
                      style="color: #fff; font-size: 24px"
                    ></i>
                  </div>
  
                  <div class="Wrapper_Card_Title">Contratos</div>
                </a>
              </div>
  
              <div
                class="ListCards_Wrapper_Card Financeiro"
                *ngIf="
                  (possuiPermissaoAcesso(218) ||
                  possuiPermissaoAcesso(283) ||
                  possuiPermissaoAcesso(219) || possuiPermissaoAcesso(220) ||
                  possuiPermissaoAcesso(214) ||
                  possuiPermissaoAcesso(885) ||
                  (this.verificaContaPool() &&
                    this.possuiPermissaoAcessoValidacaoCentralAtendimento(835)) ||
                  possuiPermissaoAcesso(226) ||
                  possuiPermissaoAcesso(215) ||
                    possuiPermissaoAcesso(216) ||
                    possuiPermissaoAcesso(217) ||
                  this.possuiPerfilProprietario ||
                  (!this.ehGestaoTotal && possuiPermissaoAcesso(816)))
                "
              >
                <a (click)="selectModule('financeiro')">
                  <i class="SubgroupIcon fas fa-caret-right"></i>
  
                  <div class="Wrapper_Card_Icon CourrierIcon">
                    <span class="apsa-icon-colorido-financeiro">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                    </span>
                  </div>
  
                  <div class="Wrapper_Card_Title">Financeiro</div>
                </a>
              </div>
  
              <div
                class="ListCards_Wrapper_Card GestaoDePessoal"
                *ngIf="
                  possuiPermissaoAcesso(227) ||
                  possuiPermissaoAcesso(243) ||
                  possuiPermissaoAcesso(276)
                "
              >
                <a (click)="selectModule('gestaoDePessoal')">
                  <i class="SubgroupIcon fas fa-caret-right"></i>
  
                  <div class="Wrapper_Card_Icon CourrierIcon">
                    <span class="apsa-icon-colorido-funcionarios">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                    </span>
                  </div>
  
                  <div class="Wrapper_Card_Title">Gestão de pessoal</div>
                </a>
              </div>
  
              <div
                class="ListCards_Wrapper_Card Condominio"
                *ngIf="
                  possuiPermissaoAcesso(205) ||
                  possuiPermissaoAcesso(211) ||
                  possuiPermissaoAcesso(810) ||
                  stateService.possuiPerfilCentralAtendimento() ||
                    stateService.possuiPerfilSindico() ||
                    possuiPermissaoAcesso(881) ||
                  this.stateService.possuiAcessoFuncionalidadeGeral(207) ||
                  stateService.possuiAcessoFuncionalidadeGeral(853) ||
                  (!this.ehGestaoTotal && possuiPermissaoAcesso(816)) ||
                  (this.ehGestaoTotal && possuiPermissaoAcesso(285)) ||
                  (stateService.permitirEntregaExpressa() === 0 &&
                    stateService.possuiAcessoFuncionalidade(206))
                "
              >
                <a (click)="selectModule('condominio')">
                  <i class="SubgroupIcon fas fa-caret-right"></i>
  
                  <div class="Wrapper_Card_Icon CourrierIcon">
                    <span class="apsa-icon-colorido-condominio-digital">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                      <span class="path5"></span>
                      <span class="path6"></span>
                      <span class="path7"></span>
                      <span class="path8"></span>
                      <span class="path9"></span>
                      <span class="path10"></span>
                      <span class="path11"></span>
                      <span class="path12"></span>
                      <span class="path13"></span>
                      <span class="path14"></span>
                    </span>
                  </div>
  
                  <div class="Wrapper_Card_Title">Condomínio</div>
                </a>
              </div>
  
              <div
                class="ListCards_Wrapper_Card ReservasEFacilidades"
                *ngIf="
                  (this.ehGestaoTotal &&
                    (possuiPermissaoAcesso(286) ||
                      possuiPermissaoAcesso(294) ||
                      possuiPermissaoAcesso(304))) ||
                  (!this.ehGestaoTotal && possuiPermissaoAcesso(823)) ||
                  (ehGestaoTotal && possuiPermissaoAcesso(292)) ||
                  (this.ehGestaoTotal && possuiPermissaoAcesso(305)) ||
                  (this.ehGestaoTotal && possuiPermissaoAcesso(295)) ||
                  (!this.ehGestaoTotal && possuiPermissaoAcesso(822))
                "
              >
                <a (click)="selectModule('reservasEFacilidades')">
                  <i class="SubgroupIcon fas fa-caret-right"></i>
  
                  <div class="Wrapper_Card_Icon CourrierIcon">
                    <span class="apsa-icon-colorido-reservas1">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                    </span>
                  </div>
  
                  <div class="Wrapper_Card_Title">Reservas e facilidades</div>
                </a>
              </div>
  
              <div
                class="ListCards_Wrapper_Card Comunicacao"
                *ngIf="
                  (ehGestaoTotal && possuiPermissaoAcesso(291)) ||
                  (!ehGestaoTotal && possuiPermissaoAcesso(814)) ||
                  possuiPermissaoAcesso(811)
                "
              >
                <a (click)="selectModule('comunicacao')">
                  <i class="SubgroupIcon fas fa-caret-right"></i>
  
                  <div class="Wrapper_Card_Icon CourrierIcon">
                    <span class="apsa-icon-colorido-comunicacao">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                      <span class="path5"></span>
                      <span class="path6"></span>
                      <span class="path7"></span>
                      <span class="path8"></span>
                    </span>
                  </div>
  
                  <div class="Wrapper_Card_Title">Comunicação</div>
                </a>
              </div>
  
              <div
                *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(287)"
                class="ListCards_Wrapper_Card Checklist"
              >
                <a routerLink="/checklist" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-check">
                      <span class="path1"></span>
                      <span class="path2"></span>
                    </span>
                  </div>
                  <div class="Wrapper_Card_Title">Checklist</div>
                </a>
              </div>
  
              <div
                *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(293)"
                class="ListCards_Wrapper_Card Colaboradores"
              >
                <a routerLink="/colaboradores" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-colaboradores">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                      <span class="path5"></span>
                      <span class="path6"></span>
                    </span>
                  </div>
                  <div class="Wrapper_Card_Title">Colaboradores</div>
                </a>
              </div>
  
              <!-- <div
                *ngIf="stateService.pessoaPossuiAcessoFuncionalidade(856)"
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/processamento-de-documentos">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-envio-documentos">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                      <span class="path5"></span>
                      <span class="path6"></span>
                    </span>
                  </div>
      
                  <div class="Wrapper_Card_Title">
                    Documentos não tratados
                  </div>
                </a>
              </div> -->
  
              <div
                *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(819)"
                class="ListCards_Wrapper_Card Espacos"
              >
                <a routerLink="/cadastro-de-espacos" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-espacos-colorido">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                    </span>
                  </div>
                  <div class="Wrapper_Card_Title">Espaços</div>
                </a>
              </div>
      
              <div *ngIf="stateService.possuiFuncionalidadeCondominio(267)" class="ListCards_Wrapper_Card MeusDados">
                <a routerLink="/funcionario/meus-dados" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-funcionarios">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                    </span>
                  </div>
                  <div class="Wrapper_Card_Title">Meus Dados</div>
                </a>
              </div>
  
              <!-- <div *ngIf="this.stateService.condominioPodeExibirCartao() && possuiPermissaoAcessoValidacaoCentralAtendimento(833)" class="ListCards_Wrapper_Card">
                <a routerLink="/relatorio-pagamentos" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-cotas">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                      <span class="path5"></span>
                    </span>
                  </div>
                  <div class="Wrapper_Card_Title">Relatório de pagamentos</div>
                </a>
              </div> -->
  
              <div
                *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(299)"
                class="ListCards_Wrapper_Card RelatorioDeOcorrencias"
              >
                <a routerLink="/relatorio-ocorrencias" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <i
                      class="fas fa-copy"
                      style="color: #fff; font-size: 24px"
                    ></i>
                  </div>
                  <div class="Wrapper_Card_Title">Relatório de Ocorrências</div>
                </a>
              </div>
  
              <div
                *ngIf="
                  this.stateService.possuiAcessoFuncionalidadeGeral(873) &&
                  ((ehCondominioPrime() && this.ehGestaoTotal) ||
                    this.stateService.condominioPodeVisualizarRelatAcoes())
                "
                class="ListCards_Wrapper_Card RelatorioDeAcoes"
              >
                <a
                  routerLink="/relatorios-gerenciais/acoes"
                  routerLinkActive="active"
                >
                  <div class="Wrapper_Card_Icon">
                    <div class="relatorios-prime-icon">
                      <i class="fas fa-file-alt"></i>
                    </div>
                  </div>
                  <div class="Wrapper_Card_Title">Relatório de Ações</div>
                </a>
              </div>
              <div
                *ngIf="
                  ehCondominioPrime() &&
                  this.stateService.possuiAcessoFuncionalidadeGeral(874)
                "
                class="ListCards_Wrapper_Card RelatorioFinanceiro"
              >
                <a
                  routerLink="/relatorios-gerenciais/financeiro"
                  routerLinkActive="active"
                >
                  <div class="Wrapper_Card_Icon">
                    <div class="relatorios-prime-icon">
                      <i class="fas fa-file-alt"></i>
                    </div>
                  </div>
                  <div class="Wrapper_Card_Title">Relatório Financeiro</div>
                </a>
              </div>
              <div
                *ngIf="
                  ehCondominioPrime() &&
                  this.stateService.possuiAcessoFuncionalidadeGeral(875)
                "
                class="ListCards_Wrapper_Card RelatorioVisitasDP"
              >
                <a
                  routerLink="/relatorios-gerenciais/visitas-dp"
                  routerLinkActive="active"
                >
                  <div class="Wrapper_Card_Icon">
                    <div class="relatorios-prime-icon">
                      <i class="fas fa-file-alt"></i>
                    </div>
                  </div>
                  <div class="Wrapper_Card_Title">Relatório Visitas DP</div>
                </a>
              </div>
  
              <div
                *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(303)"
                class="ListCards_Wrapper_Card RelatorioDeInventario"
              >
                <a routerLink="/inventario" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <i
                      class="fas fa-copy"
                      style="color: #fff; font-size: 24px"
                    ></i>
                  </div>
  
                  <div class="Wrapper_Card_Title">Relatório de Inventário</div>
                </a>
              </div>
  
              <div
                class="ListCards_Wrapper_Card Pagamentos"
                *ngIf="this.possuiPerfilLocatario"
              >
                <a routerLink="/pagamentos" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-pagamento-locatario">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                    </span>
                  </div>
                  <div class="Wrapper_Card_Title">Pagamentos</div>
                </a>
              </div>
  
              <div
                class="ListCards_Wrapper_Card DadosDoImovel"
                *ngIf="this.possuiPerfilProprietario"
              >
                <a routerLink="/dados-imovel" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <div class="Item_Current_Icon">
                      <span class="apsa-icon-colorido-dados-imovel">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                        <span class="path4"></span>
                        <span class="path5"></span>
                      </span>
                    </div>
                  </div>
                  <div class="Wrapper_Card_Title">Dados do Imóvel</div>
                </a>
              </div>
  
              <div
                *ngIf="
                  this.possuiPerfilProprietario &&
                  this.stateService.possuiAcessoFuncionalidadeGeral(606)
                "
                class="ListCards_Wrapper_Card RelacaoDeDevedores"
              >
                <a routerLink="/relacao-de-devedores" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <i
                      class="fas fa-gavel"
                      style="color: #fff; font-size: 24px"
                    ></i>
                  </div>
  
                  <div class="Wrapper_Card_Title">Relação de Devedores</div>
                </a>
              </div>
  
              <div
                *ngIf="
                  this.possuiPerfilProprietario &&
                  this.stateService.possuiAcessoFuncionalidadeGeral(604)
                "
                class="ListCards_Wrapper_Card ProcessosJuridicos"
              >
                <a routerLink="/processos-juridicos" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <i
                      class="fas fa-gavel"
                      style="color: #fff; font-size: 24px"
                    ></i>
                  </div>
  
                  <div class="Wrapper_Card_Title">Processos Jurídicos</div>
                </a>
              </div>
            </div>
          </ng-container>
  
          <div class="SubModule" *ngIf="isModuleSelected('financeiro')">
            <div class="flex SubModuleTitle">
              <h2 class="SubModuleTitle_Text flex1">Financeiro</h2>
  
              <button class="flex SubModule__Back" (click)="deselectAllModules()">
                <i class="fas fa-chevron-left"></i>
                Voltar
              </button>
            </div>
  
            <div class="ListCards_Wrapper">
              <div
                *ngIf="possuiPermissaoAcesso(218)"
                class="ListCards_Wrapper_Card __is-highlighted"
              >
                <a routerLink="/extrato/sindico" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-extrato">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                      <span class="path5"></span>
                      <span class="path6"></span>
                      <span class="path7"></span>
                      <span class="path8"></span>
                      <span class="path9"></span>
                    </span>
                  </div>
                  <div class="Wrapper_Card_Title">Extrato</div>
                </a>
              </div>
  
              <div
                class="ListCards_Wrapper_Card __is-highlighted"
                *ngIf="this.possuiPerfilProprietario"
              >
                <a routerLink="/extrato/proprietario" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <div class="Item_Current_Icon">
                      <span class="apsa-icon-colorido-extrato">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                        <span class="path4"></span>
                        <span class="path5"></span>
                        <span class="path6"></span>
                        <span class="path7"></span>
                        <span class="path8"></span>
                        <span class="path9"></span>
                      </span>
                    </div>
                  </div>
                  <div class="Wrapper_Card_Title">Extrato</div>
                </a>
              </div>
  
              <div
                class="ListCards_Wrapper_Card"
                *ngIf="possuiPermissaoAcesso(214)"
              >
                <a
                  routerLink="/acompanhamento-orcamentario"
                  routerLinkActive="active"
                >
                  <div class="Wrapper_Card_Icon __not-colorful">
                    <i class="fas fa-database Card_Icon_SolidAsColorfull"></i>
                  </div>
  
                  <div class="Wrapper_Card_Title">
                    Acompanhamento Orçamentário
                  </div>
                </a>
              </div>
  
              <div
                *ngIf="possuiPermissaoAcesso(283)"
                class="ListCards_Wrapper_Card"
              >
                <a
                  routerLink="/prestacao-conta-digital"
                  routerLinkActive="active"
                >
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-financeiro">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                    </span>
                  </div>
  
                  <div class="Wrapper_Card_Title">
                    Pasta de prestação de contas digital
                  </div>
                </a>
              </div>
  
              <div
                *ngIf="possuiPermissaoAcesso(219) || possuiPermissaoAcesso(220)"
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/cotas" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-cotas">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                      <span class="path5"></span>
                    </span>
                  </div>
                  <div
                    *ngIf="
                      this.stateService.possuiApenasPerfilCondomino();
                      then segundaVia;
                      else cotas
                    "
                  ></div>
                  <ng-template #segundaVia>
                    <div class="Wrapper_Card_Title">2ª via de boletos</div>
                  </ng-template>
                  <ng-template #cotas>
                    <div class="Wrapper_Card_Title">Cotas</div>
                  </ng-template>
                </a>
              </div>
  
              <div
                class="ListCards_Wrapper_Card __is-highlighted"
                *ngIf="
                  possuiPermissaoAcesso(215) ||
                  possuiPermissaoAcesso(216) ||
                  possuiPermissaoAcesso(217)
                "
              >
                <a routerLink="/prestacao-contas" routerLinkActive="active">
                  <i
                    class="fas fa-coins"
                    style="color: #fff; font-size: 24px"
                  ></i>
                  <div class="Wrapper_Card_Title">Prestação de contas</div>
                </a>
              </div>
  
              <div
                class="ListCards_Wrapper_Card"
                *ngIf="possuiPermissaoAcesso(885)"
              >
                <a routerLink="/cotas-inadimplentes" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <i
                      class="fas fa-copy"
                      style="color: #fff; font-size: 24px"
                    ></i>
                  </div>
  
                  <div class="Wrapper_Card_Title">Relatório de Inadimplentes</div>
                </a>
              </div>
  
              <div
                class="ListCards_Wrapper_Card"
                *ngIf="possuiPermissaoAcesso(226)"
              >
                <a routerLink="/receitas-previstas" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <i
                      class="fas fa-copy"
                      style="color: #fff; font-size: 24px"
                    ></i>
                  </div>
  
                  <div class="Wrapper_Card_Title">Receitas previstas</div>
                </a>
              </div>
  
              <div
                *ngIf="
                  this.verificaContaPool() &&
                  this.possuiPermissaoAcessoValidacaoCentralAtendimento(835)
                "
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/quitacao-anual" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-colorido-informe-quitacao">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                      <span class="path5"></span>
                      <span class="path6"></span>
                      <span class="path7"></span>
                      <span class="path8"></span>
                    </span>
                  </div>
                  <div class="Wrapper_Card_Title">Quitação Anual</div>
                </a>
              </div>
            </div>
          </div>
  
          <div class="SubModule" *ngIf="isModuleSelected('gestaoDePessoal')">
            <div class="flex SubModuleTitle">
              <h2 class="SubModuleTitle_Text flex1">Gestão de pessoal</h2>
  
              <button class="flex SubModule__Back" (click)="deselectAllModules()">
                <i class="fas fa-chevron-left"></i>
                Voltar
              </button>
            </div>
  
            <div class="ListCards_Wrapper">
              <div
                *ngIf="(possuiPermissaoAcesso(853) && possuiPermissaoAcesso(854)) || stateService.possuiPerfilGerencial()"
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/minhas-solicitacoes/cadastro/38" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-funcionarios"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span
                    ></span>
                  </div>
                  <div class="Wrapper_Card_Title">Admissão de funcionário</div>
                </a>
              </div>

              <div
                *ngIf="(ehGestaoTotal && possuiPermissaoAcesso(293)) || stateService.possuiPerfilGerencial()"
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/colaboradores" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-funcionarios"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span
                    ></span>
                  </div>
                  <div class="Wrapper_Card_Title">Colaboradores</div>
                </a>
              </div>

              <div
                *ngIf="(ehGestaoTotal && possuiPermissaoAcesso(293)) || stateService.possuiPerfilGerencial()"
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/minhas-solicitacoes/cadastro/35" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-funcionarios"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span
                    ></span>
                  </div>
                  <div class="Wrapper_Card_Title">Concessão de Férias do Funcionário</div>
                </a>
              </div>

              <div
                *ngIf="possuiPermissaoAcesso(227)"
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/funcionario" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-funcionarios"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span
                    ></span>
                  </div>
                  <div class="Wrapper_Card_Title">Funcionário</div>
                </a>
              </div>

              <div
                *ngIf="(possuiPermissaoAcesso(853) && possuiPermissaoAcesso(854)) || stateService.possuiPerfilGerencial()"
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/minhas-solicitacoes/cadastro/36" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-funcionarios"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span
                    ></span>
                  </div>
                  <div class="Wrapper_Card_Title">Informação/Alteração de Horas Extras</div>
                </a>
              </div>

              <div
                *ngIf="possuiPermissaoAcesso(276)"
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/quadro-horarios" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <i
                      class="fas fa-business-time"
                      style="color: #fff; font-size: 24px"
                    ></i>
                  </div>
  
                  <div class="Wrapper_Card_Title">Jornada de Trabalho</div>
                </a>
              </div>
  
              <div
                *ngIf="possuiPermissaoAcesso(243)"
                class="ListCards_Wrapper_Card"
              >
                <a
                  routerLink="/lancamento-proventos-descontos"
                  routerLinkActive="active"
                >
                  <div class="Wrapper_Card_Icon __is-fake">
                    <i class="fas fa-file-invoice-dollar"></i>
                  </div>
  
                  <div class="Wrapper_Card_Title">
                    Lançamento de proventos e descontos
                  </div>
                </a>
              </div>

              <div
                *ngIf="(possuiPermissaoAcesso(853) && possuiPermissaoAcesso(854)) || stateService.possuiPerfilGerencial()"
                class="ListCards_Wrapper_Card"
              >
                <a
                  routerLink="/minhas-solicitacoes/cadastro/39"
                  routerLinkActive="active"
                >
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-funcionarios"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span
                    ></span>
                  </div>
  
                  <div class="Wrapper_Card_Title">
                    Recrutamento e Seleção de Funcionário com Sindicância
                  </div>
                </a>
              </div>

              <div
                *ngIf="(possuiPermissaoAcesso(853) && possuiPermissaoAcesso(854)) || stateService.possuiPerfilGerencial()"
                class="ListCards_Wrapper_Card"
              >
                <a
                  routerLink="/minhas-solicitacoes/cadastro/34"
                  routerLinkActive="active"
                >
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-funcionarios"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span
                    ></span>
                  </div>
  
                  <div class="Wrapper_Card_Title">
                    Simulação/Realização de Rescisão de Funcionário
                  </div>
                </a>
              </div>

              <div
                *ngIf="(possuiPermissaoAcesso(853) && possuiPermissaoAcesso(854)) || stateService.possuiPerfilGerencial()"
                class="ListCards_Wrapper_Card"
              >
                <a
                  routerLink="/minhas-solicitacoes/cadastro/40"
                  routerLinkActive="active"
                >
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-funcionarios"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span
                    ></span>
                  </div>
  
                  <div class="Wrapper_Card_Title">
                    Sindicância do Funcionário
                  </div>
                </a>
              </div>

              <div
                *ngIf="(possuiPermissaoAcesso(853) && possuiPermissaoAcesso(854)) || stateService.possuiPerfilGerencial()"
                class="ListCards_Wrapper_Card"
              >
                <a
                  routerLink="/minhas-solicitacoes/cadastro/37"
                  routerLinkActive="active"
                >
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-funcionarios"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span
                    ></span>
                  </div>
  
                  <div class="Wrapper_Card_Title">
                    Supressão de Horas Extras
                  </div>
                </a>
              </div>
  
            </div>
          </div>
  
          <div class="SubModule" *ngIf="isModuleSelected('condominio')">
            <div class="flex SubModuleTitle">
              <h2 class="SubModuleTitle_Text flex1">Condomínio</h2>
  
              <button class="flex SubModule__Back" (click)="deselectAllModules()">
                <i class="fas fa-chevron-left"></i>
                Voltar
              </button>
            </div>
  
            <div class="ListCards_Wrapper">
              <div
                *ngIf="possuiPermissaoAcesso(205)"
                class="ListCards_Wrapper_Card __is-highlighted"
              >
                <a routerLink="/dados-condominio" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-sobre-condominio-colorido">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                      <span class="path5"></span>
                      <span class="path6"></span>
                      <span class="path7"></span>
                      <span class="path8"></span>
                      <span class="path9"></span>
                      <span class="path10"></span>
                      <span class="path11"></span>
                      <span class="path12"></span>
                      <span class="path13"></span>
                      <span class="path14"></span>
                      <span class="path15"></span>
                      <span class="path16"></span>
                      <span class="path17"></span>
                      <span class="path18"></span>
                      <span class="path19"></span>
                      <span class="path20"></span>
                      <span class="path21"></span>
                      <span class="path22"></span>
                      <span class="path23"></span>
                      <span class="path24"></span>
                      <span class="path25"></span>
                      <span class="path26"></span>
                      <span class="path27"></span>
                      <span class="path28"></span>
                      <span class="path29"></span>
                      <span class="path30"></span>
                      <span class="path31"></span>
                      <span class="path32"></span>
                    </span>
                  </div>
                  <div class="Wrapper_Card_Title">Dados do Condomínio</div>
                </a>
              </div>
  
              <div
                *ngIf="possuiPermissaoAcesso(211)"
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/condomino" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-condominio-digital">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                      <span class="path5"></span>
                      <span class="path6"></span>
                      <span class="path7"></span>
                      <span class="path8"></span>
                      <span class="path9"></span>
                      <span class="path10"></span>
                      <span class="path11"></span>
                      <span class="path12"></span>
                      <span class="path13"></span>
                      <span class="path14"></span>
                    </span>
                  </div>
                  <div class="Wrapper_Card_Title">Condôminos</div>
                </a>
              </div>
  
              <div
                *ngIf="possuiPermissaoAcesso(810)"
                class="ListCards_Wrapper_Card __is-highlighted"
              >
                <a routerLink="/documento" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-malotes">
                      <span class="path1"></span>
                      <span class="path2"></span>
                    </span>
                  </div>
  
                  <div class="Wrapper_Card_Title">Normas e Documentos</div>
                </a>
              </div>
  
              <div
                class="ListCards_Wrapper_Card"
                *ngIf="
                  stateService.possuiPerfilCentralAtendimento() ||
                  stateService.possuiPerfilSindico() ||
                  possuiPermissaoAcesso(881)
                "
              >
                <a routerLink="/pasta-dp" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <i
                      class="fas fa-folder-open"
                      style="color: #fff; font-size: 24px"
                    ></i>
                  </div>
  
                  <div class="Wrapper_Card_Title">Documentos Fiscais</div>
                </a>
              </div>
  
              <div
                *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(207)"
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/processos-juridicos" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <i
                      class="fas fa-gavel"
                      style="color: #fff; font-size: 24px"
                    ></i>
                  </div>
  
                  <div class="Wrapper_Card_Title">Processos Jurídicos</div>
                </a>
              </div>
  
              <div
                *ngIf="stateService.possuiAcessoFuncionalidadeGeral(853)"
                class="ListCards_Wrapper_Card __is-highlighted"
              >
                <a routerLink="/minhas-solicitacoes" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon __is-fake">
                    <i class="fas fa-tasks"></i>
                  </div>
  
                  <div class="Wrapper_Card_Title">Minhas solicitações</div>
                </a>
              </div>
  
              <div
                *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(816)"
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/ocorrencia" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-fale-com-gestor"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span><span class="path4"></span
                      ><span class="path5"></span><span class="path6"></span
                      ><span class="path7"></span
                    ></span>
                  </div>
                  <div
                    *ngIf="possuiPerfilGestorSindico; else faleComOsindico"
                    class="Wrapper_Card_Title"
                  >
                    Ocorrências
                  </div>
                  <ng-template #faleComOsindico>
                    <div class="Wrapper_Card_Title">Fale com o Síndico</div>
                  </ng-template>
                </a>
              </div>
  
              <div
                *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(285)"
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/ocorrenciaGT" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-fale-com-gestor">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                      <span class="path5"></span>
                      <span class="path6"></span>
                      <span class="path7"></span>
                    </span>
                  </div>
                  <div
                    *ngIf="possuiPerfilGestorSindico; else faleComOsindico"
                    class="Wrapper_Card_Title"
                  >
                    Ocorrências
                  </div>
                  <ng-template #faleComOsindico>
                    <div class="Wrapper_Card_Title">Fale com o Síndico</div>
                  </ng-template>
                </a>
              </div>
  
              <div
                *ngIf="
                  stateService.permitirEntregaExpressa() === 0 &&
                  stateService.possuiAcessoFuncionalidade(206)
                "
                class="ListCards_Wrapper_Card CalendarioDeVisitas"
              >
                <a routerLink="/calendario-visitas" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon calendarioVisitasIcon">
                    <i class="far fa-calendar-check"></i>
                  </div>
                  <div class="Wrapper_Card_Title">Calendário de visitas</div>
                </a>
              </div>
            </div>
          </div>
  
          <div class="SubModule" *ngIf="isModuleSelected('reservasEFacilidades')">
            <div class="flex SubModuleTitle">
              <h2 class="SubModuleTitle_Text flex1">Reservas e facilidades</h2>
  
              <button class="flex SubModule__Back" (click)="deselectAllModules()">
                <i class="fas fa-chevron-left"></i>
                Voltar
              </button>
            </div>
  
            <div class="ListCards_Wrapper">
              <div
                *ngIf="
                  this.ehGestaoTotal &&
                  (possuiPermissaoAcesso(286) ||
                    possuiPermissaoAcesso(294) ||
                    possuiPermissaoAcesso(304))
                "
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/reserva" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-reservas1">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                    </span>
                  </div>
                  <div class="Wrapper_Card_Title">Reservas</div>
                </a>
              </div>
  
              <div
                *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(823)"
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/reserva-conv" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-reservas1">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                    </span>
                  </div>
                  <div class="Wrapper_Card_Title">Reservas</div>
                </a>
              </div>
  
              <div
                class="ListCards_Wrapper_Card"
                *ngIf="ehGestaoTotal && possuiPermissaoAcesso(292)"
              >
                <a routerLink="/achados-perdidos" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
                      <i class="fas fa-box-open" style="font-size: 24px"></i>
                    </span>
                  </div>
  
                  <div class="Wrapper_Card_Title">Achados e perdidos</div>
                </a>
              </div>
  
              <div
                *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(305)"
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/courrier" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon CourrierIcon">
                    <i class="fas fa-business-time"></i>
                    <i class="fas fa-clock"></i>
                  </div>
  
                  <div class="Wrapper_Card_Title">Courrier</div>
                </a>
              </div>
  
              <div
                *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(295)"
                class="ListCards_Wrapper_Card GerenciarDisponibilidade"
              >
                <a routerLink="/disponibilidade" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-reservas1"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span
                    ></span>
                  </div>
                  <div class="Wrapper_Card_Title">Gerenciar Disponibilidade</div>
                </a>
              </div>
  
              <div
                *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(822)"
                class="ListCards_Wrapper_Card ConfiguracaoDeReservas"
              >
                <a routerLink="/reserva-configuracao" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-reservas1">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                    </span>
                  </div>
                  <div class="Wrapper_Card_Title">Configuração de Reservas</div>
                </a>
              </div>
            </div>
          </div>
  
          <div class="SubModule" *ngIf="isModuleSelected('comunicacao')">
            <div class="flex SubModuleTitle">
              <h2 class="SubModuleTitle_Text flex1">Comunicação</h2>
  
              <button class="flex SubModule__Back" (click)="deselectAllModules()">
                <i class="fas fa-chevron-left"></i>
                Voltar
              </button>
            </div>
  
            <div class="ListCards_Wrapper">
              <div
                class="ListCards_Wrapper_Card"
                *ngIf="ehGestaoTotal && possuiPermissaoAcesso(291)"
              >
                <a routerLink="/muralGT" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon __not-colorful">
                    <i
                      class="apsa-icon-solid-mural Card_Icon_SolidAsColorfull"
                    ></i>
                  </div>
                  <div class="Wrapper_Card_Title">Mural</div>
                </a>
              </div>
  
              <div
                class="ListCards_Wrapper_Card"
                *ngIf="!ehGestaoTotal && possuiPermissaoAcesso(814)"
              >
                <a routerLink="/mural" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon __not-colorful">
                    <i
                      class="apsa-icon-solid-mural Card_Icon_SolidAsColorfull"
                    ></i>
                  </div>
                  <div class="Wrapper_Card_Title">Mural</div>
                </a>
              </div>
  
              <div
                *ngIf="possuiPermissaoAcesso(811)"
                class="ListCards_Wrapper_Card"
              >
                <a routerLink="/enquetes" routerLinkActive="active">
                  <div class="Wrapper_Card_Icon">
                    <span class="apsa-icon-colorido-enquete">
                      <span class="path1"></span>
                      <span class="path2"></span>
                    </span>
                  </div>
                  <div class="Wrapper_Card_Title">Enquetes</div>
                </a>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
  
      <mobile-shortcuts-with-list-design
        *ngIf="isListDesign"
      ></mobile-shortcuts-with-list-design>
    </ng-container>
  </ng-container>
  
</div>
