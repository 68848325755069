<div class="Modal Modal_AssinaturaDigital" [ngClass]="{ '__is-open': isOpenModalAssinatura }">
  <div class="Modal_Header">
    <div class="Modal_Header_Title">
      Assinatura digital
    </div>
  </div>

  <div class="Modal_Content">
    <div class="Block">
      <div class="Block_Container">
        
        <div class="AssinaturaDigital">
          <h1 class="title">Olá, {{ nome }}!</h1>
        
          <p class="Paragraph">
            Por conta das recentes atualizações nas regras estabelecidas pelo Banco Central do Brasil, duas cláusulas do nosso contrato estão sendo ajustadas para que estejamos cumprindo as novas exigências, mantendo as mesmas condições já praticadas na prestação de nossos serviços e gestão do seu condomínio.
          </p>
  
          <p class="Paragraph __is-bold">
            Fique tranquilo! Todos os nossos compromissos continuam válidos e não serão alterados.
          </p>
  
          <ng-container *ngFor="let doc of (optins && optins.documentosAssinaturaDigital ? optins.documentosAssinaturaDigital : [])">
            <div class="Link" (click)="openLinkAditivo(doc.urldownload)">
              <a> {{ doc.dscCondominio }} </a>
            </div>
          </ng-container>
        </div>

      </div>
    </div>
  </div>

  <div class="Modal_Footer">
    <div class="Modal_Footer_ListActions">
      <div class="Footer_ListActions_Item __is-full-width">
        <ds-button (click)="cadastrarTermoAssinaturaDigital()" full="true" leftIcon="fas fa-check">
         Assinar
        </ds-button>
      </div>

      <div class="Footer_ListActions_Item __is-full-width __is-flex">
        <button (click)="lembrarDepois()" class="RememberLater">
          Ver mais tarde
        </button>
      </div>
    </div>
  </div>
</div>