import { Component, OnInit, Output, Input, EventEmitter, OnDestroy, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core';
import { ProfilePictureFacade } from '../../facades/profile-picture.facade';
import { ModalService } from '../../services/modal.service';
import { StateService } from '../../services/stateService';
import { LightBoxesService } from '../lightboxes';
import { PopupChangeUserImageComponent } from '../popup-change-user-image/popup-change-user-image.component';

@Component({
  selector: 'app-dados-conta',
  templateUrl: './dados-conta.component.html',
  styleUrls: ['./dados-conta.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DadosContaComponent implements OnInit, OnDestroy {
  @Output() eventClose = new EventEmitter();
  @Output() toggleAdesao = new EventEmitter();
  @Output() toggleAdesaoIPTU = new EventEmitter();
  @Input() open: boolean;
  @ViewChild(PopupChangeUserImageComponent) popupChangeUserImage: PopupChangeUserImageComponent;

  constructor(
    private modalService: ModalService,
    public stateService: StateService,
    private lightboxesService: LightBoxesService,
    public profilePictureFacade: ProfilePictureFacade,
  ) { }

  ngOnDestroy() {
    this.modalService.removeHasModalClassFromContainerDiv();
  }

  ngOnInit() { }

  close() {
    this.eventClose.emit(false);
    let container = document.querySelectorAll('.Container');
    for (var i = 0; i < container.length; i++) {
      container[i].classList.remove('__has-modal')
    }
  }

  get nomeDoUsuario() {
    return this.stateService.getPessoaLogada().Nome;
  }

  get emailDoUsuario() {
    return this.stateService.getPessoaLogada().Email;
  }

  
  openOkPopup(title: string, message: string): Promise<boolean> {
    return this.lightboxesService.open(
      'custom-generico',
      { title, text: message },
      15_000
    );
  }
}
