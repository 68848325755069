import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import CustomerService from '../../services/customer.service';
import { CondominioService } from '../../services/condominio.service';

type Contato = {
  lugar: string;
  telefone: string;
}
@Component({
  selector: 'app-contatos',
  templateUrl: './contatos.component.html',
  styleUrls: ['./contatos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContatosComponent implements OnInit {

  public contatos: Contato[] = [];

  constructor(public customerService: CustomerService, private condominioService: CondominioService) {
  }
  
  ngOnInit() {
    this.getContatos();
  }

  getContatos() {
    if(!sessionStorage.getItem('telefones')) {
      this.condominioService.buscarTelefones().subscribe({
        next: res =>  {
          sessionStorage.setItem('telefones', JSON.stringify(res.viewModel))
          this.contatos = res.viewModel;
        },
        error: error => {}
      })
    } else {
      this.contatos = JSON.parse(sessionStorage.getItem('telefones'));
    }
  }

  formatarTelefone(tel: string): string {
    const telNoSpeacialCharacteres = tel.replace('(', '').replace(')', '').replace(' ', '').replace('-','');
    return `tel: +55${telNoSpeacialCharacteres}`;
  }

}
