<div
  class="UploadImageComponent"
  [ngClass]="{
    '__is-sidebar-open': this.constantsService.statusMenu,
    '__is-sidebar-closed': !this.constantsService.statusMenu
  }"
>
  <ng-container *ngFor="let item of imageSlots">
    <ds-box-with-dashed-border
      icon="apsa-icon-solid-image"
      [fillImage]="item.src"
      (onClick)="handleItemClick(item.id)"
      (onDelete)="handleItemDelete(item.id)"
    >
      Adicionar imagem
    </ds-box-with-dashed-border>
  </ng-container>

  <ds-box-with-dashed-border (onClick)="handleAddImage()" *ngIf="canAddMoreImage" icon="fas fa-plus">
    Adicionar outra imagem
  </ds-box-with-dashed-border>

  <input type="file" accept=".jpg,.png,jpeg" (change)="handleFileChange($event)" #fileInput>
</div>