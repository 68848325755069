import { Router } from '@angular/router';
import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { LightBoxesService } from '../lightboxes.services';
import { StateService } from 'src/app/shared/services/stateService';
import { RecorrenciaCartaoCreditoService } from 'src/app/modules/recorrencia-cartao-credito/services/recorrencia-cartao-credito.service';

@Component({
  selector: 'app-modal-cancelamento-adesao-recorrencia',
  templateUrl: './modal-cancelamento-adesao-recorrencia.component.html',
  styleUrls: ['./modal-cancelamento-adesao-recorrencia.component.scss'],
})
export class ModalCancelamentoAdesaoRecorrenciaComponent implements OnInit {
  @Input() id: string;
  @Input() className: string;
  private element: any;
  private uidRecorrencia: any;
  private userId: any;

  constructor(
    private el: ElementRef,
    private router: Router,
    private service: LightBoxesService, 
    private recorrenciaService: RecorrenciaCartaoCreditoService, 
    ) { 
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    if (!this.id) {
      return;
    }

    document.body.appendChild(this.element);

    this.element.addEventListener("click", el => {
      if (el.target.className === "Lightbox") 
        this.close();      
    });

    this.service.add(this);
  }

  ngOnDestroy(): void {
    this.element.remove();
  }

  open(uid: string, userId: string) {
    this.uidRecorrencia = uid;
    this.userId = userId;
    this.element.firstChild.classList.add(this.className);
  }
  
  close(): void {
    this.element.firstChild.classList.remove(this.className);
  }
  
  keepAcession() {
    this.close();
  }
  
  cancelAcession() {
    this.close();
    this.recorrenciaService.cancelarAdesaoCartao(this.uidRecorrencia, this.userId).subscribe(response => {
      if(response) {
          this.service.open('custom-generico', { title: 'Sucesso!', text: response }, 5000);
          this.router.navigate(['/home']);
        }
      }, err => this.service.open('custom-generico', { title: 'Atenção!', text: err.error.message }, 5000));
  }

}
