<div class="Lightbox">
  <div class="Popup Popup_ImageWithLinkButton">    
    
    <div class="Close_Modal" (click)="close()">
      <i class="fas fa-times"></i>
    </div>

    <div class="Popup_Content" (click)="abrirLink()">
      <div class="Popup_Content_MsgDefault">
        <img class="LightboxImage">
      </div>
    </div>

    <div class="Popup_Footer">
      <div class="Popup_Footer_Buttons">
          <a class="Link_Button" (click)="dontShowItAgain()">
            <div class="Button_Content">
              <div class="Button_Content_Label">
                Não exibir novamente
              </div>
            </div>
          </a>
      </div>
    </div>
  </div>

</div>
