import { ModalService } from 'src/app/shared/services/modal.service';
import { Component, OnInit, Input, ViewEncapsulation, EventEmitter, Output, OnDestroy } from '@angular/core';
import { StateService } from '../../services/stateService';
import { LightBoxesService } from '../lightboxes';
import { AssinaturaDigitalService } from '../../services/assinatura-digital.service';

@Component({
  selector: 'modal-assinatura-digital',
  templateUrl: './modal-assinatura-digital.component.html',
  styleUrls: ['./modal-assinatura-digital.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalAssinaturaDigitalComponent implements OnInit, OnDestroy {
  public optins = JSON.parse(sessionStorage.getItem('optins'));
  public data = new Date().toLocaleDateString()
  
  @Input() isOpenModalAssinatura: boolean;
  @Output() closeModalAssinaturaDigital = new EventEmitter()

  constructor(
    public stateService: StateService,
    private modalService: ModalService,
    private lightBoxesService: LightBoxesService,
    private assinaturaDigitalService: AssinaturaDigitalService,
  ) { }

  ngOnInit() {}

  get nome(): string {
    var consulta = this.stateService.getPessoaLogada();
    if (consulta) {
      return consulta.Nome;
    }
  }

  ngOnDestroy() {
    this.modalService.removeHasModalClassFromContainerDiv();
  }

  openLinkAditivo(url) {
    this.stateService.openExternalLink(url)
  }

  close() {
    sessionStorage.setItem('exibirModalAssinaturaDigital', JSON.stringify(false));
    if (this.stateService.platformIsDeviceMobile())
      this.closeModalAssinaturaDigital.emit()
  }

  cadastrarTermoAssinaturaDigital() {
    let obj = {
      cpfCnpj: this.stateService.getPessoaLogada().CpfCnpj,
      idPessoa: this.stateService.getPessoaLogada().Id,
      origemAcesso: 1,
      listaCondominios: this.optins.documentosAssinaturaDigital.map((doc) => {
        return {
          agencia: doc.agencia, contaCondominio: doc.contaCondominio
        }
      })
    }

    this.assinaturaDigitalService.cadastrarTermoAssinaturaDigital(obj).subscribe(item => {
      if (item) {
        this.lightBoxesService.open(
          'custom-generico', { 
            title: 'Sucesso', 
            text: `Serviço aderido com sucesso. <br> Enviaremos cópia dessa confirmação para o seu email e/ou celular.`
          }, 180000)
          
        this.close()
      }
    }, err => this.lightBoxesService.open('custom-generico', { title: 'Atenção', text: err.error.message }, 15000))

    this.close()
  }

  lembrarDepois() {
    this.lightBoxesService.open(
      'custom-generico', {
      title: 'Atenção',
      text: `Por se tratar de alterações nas regulamentações vigentes, solicitaremos novamente a assinatura digital de nosso aditivo em seu próximo acesso. ${this.optins.dataExpiracaoAssinaturaDigital ? `<br>Você pode assinar o seu contrato digitalmente até ${this.optins.dataExpiracaoAssinaturaDigital}` : ''}`
    }, 180000)

    this.close()
  }

}
