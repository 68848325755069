<div class="Lightbox">
  <div class="Popup ImageWithTitleAndToggleButton">
    <div class="Popup_Header">
      <div class="Popup_Header_Title Custom_Title">Titulo genérico</div>
    </div>

    <div class="Close_Modal" (click)="close()">
      <i class="fas fa-times"></i>
    </div>

    <div class="Popup_Content">
      <div class="Popup_Content_MsgDefault">
        <img class="LightboxImage">
        <!-- <div class="FormGroupToggle">
          <div class="FormGroupToggle_Label">
            Aqui vai qualquer texto
          </div>

          <div class="FormGroupToggle_Switch">
            <ion-toggle [formControl]="toggle"></ion-toggle>
          </div>
        </div> -->
      </div>
    </div>

    <div class="Popup_Footer">
      <div class="Popup_Footer_Buttons">
          <a class="Link_Button" (click)="dontShowItAgain()">
            <div class="Button_Content">
              <div class="Button_Content_Label">
                Não exibir novamente
              </div>
            </div>
          </a>
      </div>
    </div>
  </div>

</div>
