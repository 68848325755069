import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, AfterViewChecked, Input, TemplateRef, OnChanges, ChangeDetectorRef, SimpleChanges, OnDestroy, inject, Inject } from '@angular/core';
import { StateService } from 'src/app/shared/services/stateService';
import { Router, ActivatedRoute, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { CondominioDTO } from 'src/app/shared/services/DTO/response/Condominio.DTO';
import { CondominioService } from 'src/app/shared/services/condominio.service';
import { Subject, BehaviorSubject, Observable, distinctUntilChanged, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LightBoxesService } from 'src/app/shared/components/lightboxes';
import { ConstantsService } from '../../shared/services/constants.service';
import { PerfilDTO } from 'src/app/shared/services/DTO/response/Perfil.DTO';
import { FuncionalidadeDTO } from 'src/app/shared/services/DTO/response/Funcionalidade.DTO';
import { NgIfContext } from '@angular/common';
import { HomeService } from 'src/app/modules/home/services/home.service';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ModalService } from '../../shared/services/modal.service';
import { Platform } from '@ionic/angular';
import { HeaderService } from 'src/app/shared/services/header.service';
import { AlertaService } from 'src/app/shared/services/alerta.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BannerModalService } from 'src/app/shared/services/bannerModal.service';
import { PessoaLogadaDTO } from 'src/app/shared/services/DTO/Request/PessoaLogada.DTO';
import { AvaliacaoLojaComponent } from 'src/app/shared/components/avaliacao-loja-app/avaliacao.component';
import { CondominoService } from 'src/app/modules/condomino/services/condomino.service';
import { ListaCondominiosUnidadesDTO } from 'src/app/shared/services/DTO/response/UnidadesCondominio.DTO';
import { AcessoRapidoService } from './../../shared/services/acesso-rapido.service';
import CustomerService from 'src/app/shared/services/customer.service';
import { forEach } from 'lodash';

type ItensLista = {
  titulo: string;
  rota: string;
  img: string;
  click: string;
  subItens: string;
}

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('buscaElemento') buscaElemento: ElementRef;
  public isAdmView: boolean;
  public rotaAtual = this.router.url;
  public numeroCondSelecionado: number;
  public numeroDeCondominios: number;
  public filterCondominioMenu: any = { type: 1 };
  public filterProprietarioMenu: any = { type: 2 };
  public filterLocatarioMenu: any = { type: 3 };
  public openableMenuItems = {
    "reserva": false,
    "gestaoDePessoal": false,
    "comunicacao": false,
    "o-condominio": false,
    "sobre": false,
    "informativos": false,
    "financeiro": false,
    "tutorial": false,
    "relatoriosGerenciais": false,
    "relatorios": false,
  };
  public menuList: Array<any> = [];
  public isMenuAdmOpen: boolean = false;
  private searchSubject: Subject<string> = new Subject();
  public openModalDadosConta: boolean = false;
  public openNotificationModalMobile: boolean = false;
  // public openAdesaoModalMobile: boolean;
  public openAdesaoModalMobile: boolean;
  public openAdesaoIPTUModalMobile: boolean;
  public openAssinaturaDigitalMobile: boolean;
  public ehGestaoTotal: boolean = (sessionStorage.getItem('ehGestaoTotal') == "true");
  //public ehContaPool: boolean = (this.stateService.getCondominioLogado()) ? this.stateService.getCondominioLogado().ehContaPool : false;
  public perfilLogadoEhGestorOuSindico: boolean;
  private pessoa: any;
  public urlArray = [];
  public totalAlertas;
  public listNotifications;
  public isMobileDevice?: boolean;
  private rotasPorId = [{
    value: 287,
    route: '/checklist',
  }, {
    value: 291,
    route: '/muralGT',
  },
  {
    value: 811,
    route: '/muralGT',
  },
  {
    value: 816,
    route: '/ocorrencia',
  },
  {
    value: 814,
    route: '/muralGT',
  },
  {
    value: 811,
    route: '/enquetes',
  }, {
    value: 814,
    route: '/mural',
  }, {
    value: 211,
    route: '/condomino',
  }, {
    value: 219,
    route: '/cotas',
  },
  {
    value: 220,
    route: '/cotas',
  },
  {
    value: 306,
    route: '/envio-documento',
  },
  {
    value: 285,
    route: '/ocorrenciaGT',
  },
  {
    value: 219,
    route: '/cotas',
  },
  {
    value: 227,
    route: '/funcionario'
  },
  {
    value: 293,
    route: '/colaboradores'
  },
  {
    value: 218,
    route: '/extrato/sindico'
  },
  {
    value: 286,
    route: '/reserva'
  },
  {
    value: 294,
    route: '/reserva'
  },
  {
    value: 304,
    route: '/reserva'
  },
  {
    value: 205,
    route: '/dados-condominio'
  },
  {
    value: 819,
    route: '/cadastro-de-espacos'
  },
  // {
  //   value: 853,
  //   route: '/minhas-solicitacoes'
  // },
  {
    value: 810,
    route: '/documento'
  }
    // {
    //   value: 833,
    //   route: '/relatorio-pagamentos'
    // }
  ]

  public modalAvaliacao: boolean = false;
  public modalAvaliacaoForm: UntypedFormGroup;
  public trocaDeCondominio: number = 0;

  public userAceitouTermos: boolean;

  public popupAvaliacaoMobile: boolean = false
  public ehMoradorResponsavelOuPodeVisualizarCondominos: boolean = false;
  public condominioLogado = this.stateService.getCondominioLogado();
  public displayModalBusca: boolean = false;
  public possuiAcessoControlePagamentos: boolean = false;
  public possuiAcessoControlePagamentosGerencial: boolean = false;
  public subscription: Subscription;
  public abrirModalEdicaoFavoritos: boolean = false;
  public customer$ = this.customerService.getCustomerObservable();
  public customer = this.customerService.getCustomer();

  constructor(
    private condominioService: CondominioService,
    public stateService: StateService,
    private router: Router,
    private lightBoxesService: LightBoxesService,
    public constant: ConstantsService,
    private activatedRoute: ActivatedRoute,
    private homeService: HomeService,
    private cdr: ChangeDetectorRef,
    private activedRoute: ActivatedRoute,
    // private statusBar: StatusBar,
    public modalService: ModalService,
    public platform: Platform,
    public headerService: HeaderService,
    public alertaService: AlertaService,
    public formbuilder: UntypedFormBuilder,
    private bannerModalService: BannerModalService,
    public avaliacaoLoja: AvaliacaoLojaComponent,
    private condominoService: CondominoService,
    @Inject(CustomerService) private customerService: CustomerService,
  ) {
    activedRoute.params.subscribe(val => {
      this.getAceiteTermosPoliticas();
      this.ngOnInit();
      this.condominioService.verificaAdesaoCondominio();
    });
    
    this.createForm()
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.usuarioAceitouTermos.currentValue) this.getAceiteTermosPoliticas();
  }
  
  ngOnInit() {
    this.isMobileDevice = this.platform.is('mobileweb') || this.platform.is('ios') || this.platform.is('android') || this.platform.is('iphone');
    this.getBooleanOcorrenciaOuFaleComSindico();
    this.ehGestaoTotal = (this.stateService.getCondominioLogado()) ? this.stateService.getCondominioLogado().ehGestaoTotal : false;
    this.selecionarCondominioUnico();
    this.constant.condominioImovelMostrado = this.constant.condominioImovelAtivo;
    this.setAdmView();
    this.modalService.add('app-dados-conta');
    this.modalService.add('modal-avaliar-app');
    this.carregarPagina();
    this.openAdesaoModalMobile = this.stateService.platformIsDeviceMobile() && JSON.parse(sessionStorage.getItem('exibirModalAdesaoBoletoDigital'));
    // this.openAdesaoModalMobile = true;
    this.openAssinaturaDigitalMobile = this.stateService.platformIsDeviceMobile() && JSON.parse(sessionStorage.getItem('exibirModalAssinaturaDigital'));
    this.openAdesaoIPTUModalMobile = this.stateService.platformIsDeviceMobile() && JSON.parse(sessionStorage.getItem('exibirModalIPTU'));
    // this.openAdesaoIPTUModalMobile = true;
    
    if (this.stateService.visaoCentralAtendimento()) { this.listarMenuCentralAtendimento(); this.numeroDeCondominios = 1 }
    else { this.listarMenu().then(menu => this.numeroDeCondominios = menu ? menu.length : 0); }
    
    this.defineStatusInicialMenu();
    this.stateService.validarTemaUsuario()
    this.verificaSeTemAcessoArota();
    this.salvaUltimaURLvalida();
    this.redirectIfGiven();
    this.stateService.modalAvaliacao$.subscribe(value => {this.modalAvaliacao = value})
    this.stateService.modalAvaliacaoMobile$.subscribe(value => this.popupAvaliacaoMobile = value);
    
    this.subscription = AcessoRapidoService.abrirModalEdicaoFavoritos.pipe(distinctUntilChanged()).subscribe(toggle => {
      this.abrirModalEdicaoFavoritos = toggle;
    });
    this.acessoControlePagamentos();
  }
  
  acessoControlePagamentos(){
    let pooh = false;
    let listaCondominios = JSON.parse(sessionStorage.getItem('listaCondominiosImoveis'))
    if(listaCondominios && listaCondominios.condominios){

      listaCondominios.condominios.forEach(condominio => {
        if(condominio.condominio.ehContaPool)(pooh = true)
      })
    }
    this.possuiAcessoControlePagamentosGerencial = pooh;

    if(this.stateService.getCondominioLogado() ){
      this.possuiAcessoControlePagamentos = this.stateService.getCondominioLogado().ehContaPool && this.possuiPermissaoAcesso(306);
    }else{
      this.possuiAcessoControlePagamentos = false;
    }
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

  updateImovelMostrado(item) {
    if(item == 'clear'){
      this.zerarMenu();
      sessionStorage.removeItem('imovelLogado');
    }
    if (item.idMenu != this.constant.condominioImovelMostrado) {
      this.constant.trocaRotaMenu.next(item.idMenu);
      this.constant.condominioImovelMostrado = item.idMenu;
    }
  }

  setAdmView() {
    this.isAdmView = sessionStorage.getItem('visaoADM') === 'true';
  }

  salvaUltimaURLvalida() {
    if (this.router.url != '/forbidden') {
      let temPermissao = this.verificaSeTemAcessoArota();
      if (temPermissao) {
        localStorage.setItem('ultimaURLpermitida', this.router.url)
      }
    }
  }

  redirectIfGiven() {
    const redirectToSpecificCondominium: any = JSON.parse(localStorage.getItem('redirect'));
    const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');

    if (redirectToSpecificCondominium) {
      this.redirectToSpecificPageAndCondominium(redirectToSpecificCondominium);
    }
    else if (redirectAfterLogin) {
      this.redirectToPageIfUserHasOnlyOneCondominium(redirectAfterLogin);
    }
  }

  async redirectToSpecificPageAndCondominium(redirect: any) {
    const menuList = await this.listarMenu();

    for (let menuItem of menuList) {
      if (menuItem.ref.contaEmpresa == redirect.conta) {
        await this.selecionarMenu(menuItem);
        this.router.navigate([redirect.rota]);
        localStorage.removeItem('redirect');
      }
    }
  }

  async redirecionarParaCentralDePagamentos(){
    let contaEmpresa = await this.stateService.getCondominioLogado().contaEmpresa;
    this.router.navigate(["/centralpagamentos/" + contaEmpresa]);
  }

  async redirectToPageIfUserHasOnlyOneCondominium(route: string) {
    localStorage.removeItem('redirectAfterLogin');
    console.log ('entrou no redirectToPageIfUserHasOnlyOneCondominium')
    // Espera os dados serem gravados na sessão. Sem esse sleep não funciona, pois possuiPermissaoAcesso retorna null.
    for(let i = 0; i < 5; i++) {
      await this.sleep(1000);
      
      const menuList = await this.listarMenu();

      const possuiAcessoMenuGestaoDePessoal = this.possuiPermissaoAcesso(227)
      || this.possuiPermissaoAcesso(276)
      || this.possuiAcessoFuncionalidadeGeral(243)
      || (this.possuiAcessoFuncionalidadeGeral(853) && this.possuiAcessoFuncionalidadeGeral(854))
      || (this.ehGestaoTotal && this.possuiPermissaoAcesso(293))
  
      const possuiAcessoMenuFinanceiro = (this.possuiPermissaoAcesso(283) || this.possuiPermissaoAcesso(219) || this.possuiPermissaoAcesso(220))
      // https://condominiodigital.hmg.marlin.com.br/login?redirectAfterLogin=%2Farquivo-digital
      if(menuList?.length === 1 || route === '/usuario/edicao-dados') {       
        if(route === '/cotas' && (this.possuiPermissaoAcesso(219) || this.possuiPermissaoAcesso(220))) {
          this.router.navigateByUrl(route);         
          break;
        } else if(route === '/extrato/sindico' && (possuiAcessoMenuFinanceiro) && this.possuiPermissaoAcesso(218)) {
          // this.selecionarMenu(this.menuList[0]);
          this.router.navigateByUrl(route);         
          break;
        } else if(route == '/extrato/proprietario'  && menuList.some(m => m.type == 2)) {         
          // this.selecionarMenu(this.menuList[0]);
          this.router.navigateByUrl(route);
          break;
        }
        else if(route === '/minhas-solicitacoes/cadastro/35'&& (possuiAcessoMenuGestaoDePessoal) && (this.possuiPermissaoAcesso(853) || this.possuiPermissaoAcesso(854))) {
          // this.selecionarMenu(this.menuList[0]);
          this.router.navigateByUrl(route);
          break;
        }else if(route === '/cotas-inadimplentes' && (possuiAcessoMenuFinanceiro) && this.possuiPermissaoAcesso(885)) {
          // this.selecionarMenu(this.menuList[0]);
          this.router.navigateByUrl(route);
          break;
        }else if(route === '/prestacao-contas' && (possuiAcessoMenuFinanceiro) && (this.possuiPermissaoAcesso(215) || this.possuiPermissaoAcesso(216) || this.possuiPermissaoAcesso(217))) {
          // this.selecionarMenu(this.menuList[0]);
          this.router.navigateByUrl(route);
          break;
        }else if(route === '/envio-documento' && this.possuiPermissaoAcesso(306)){
          // this.selecionarMenu(this.menuList[0]);
          this.router.navigateByUrl(route);
          break;
        }else if(route === '/lancamento-proventos-descontos'&& (possuiAcessoMenuGestaoDePessoal) && this.possuiAcessoFuncionalidadeGeral(243)) {
          // this.selecionarMenu(this.menuList[0]);
          this.router.navigateByUrl(route);
          break;
        }else if(route === '/arquivo-digital' &&  (this.stateService.possuiPerfilSindico() || this.stateService.possuiPerfilGerencial())) {
          // this.selecionarMenu(this.menuList[0]);
          this.router.navigateByUrl(route);
          break;
        }else if(route === '/usuario/edicao-dados') {
          this.router.navigateByUrl(route);
          break;
        } else if(route === '/envio-pagamentos' && this.possuiPermissaoAcesso(306)) {
          this.router.navigateByUrl(route);
        } else if(route === '/chame-moto' && (this.stateService.permitirEntregaExpressa() != 0 && this.possuiPermissaoAcessoValidacaoCentralAtendimento(842))) {
          this.router.navigateByUrl(route);
        } else if(route === '/lancamento-proventos-descontos' && 
          (this.possuiPermissaoAcesso(243) || (this.stateService.possuiPerfilFuncionario()) || this.stateService.possuiPerfilGerencial())
        ) {
          this.router.navigateByUrl(route);
        } else if(route === '/prestacao-conta-digital' && 
          (this.possuiPermissaoAcesso(283) || this.possuiPermissaoAcesso(219) || this.possuiPermissaoAcesso(220))
        ) {
          this.router.navigateByUrl(route);
        }
      }
    }
  }

  verificaSeTemAcessoArota() {
    let rotasComValidacao = this.rotasPorId.filter(item => item.route == this.router.url)
    if (rotasComValidacao.length) {
      let temPermissaoArota = rotasComValidacao.some(element => this.possuiPermissaoAcesso(element.value))
      if (!temPermissaoArota) {
        this.router.navigate(['/forbidden'], { queryParams: { ultimaURLpermitida: localStorage.getItem('ultimaURLpermitida') } })
      }
      return temPermissaoArota;
    }
  }

  // verificaGestaoTotal() {
  //   this.ehGestaoTotal = this.stateService.getCondominioLogado().ehGestaoTotal;
  // }

  verificaContaPool() {
    if (this.stateService.getCondominioLogado()) {
      return this.stateService.getCondominioLogado().ehContaPool;
    }
    return false;
  }

  validaExibicaoContrato() {
    var gerencial = this.stateService.possuiAcessoCentralAtendimento();
    var condominio = this.stateService.getCondominioLogado();
    if (gerencial && condominio && !condominio.ehGestaoTotal) {
      if (condominio) {
        return true;
      } else return false;
    }
    else if (condominio) {
      return (this.possuiPermissaoAcesso(866) || this.possuiPermissaoAcesso(289));
    }
  }

  getBooleanOcorrenciaOuFaleComSindico() {
    this.homeService.verificarPerfilLogado().subscribe(item => this.perfilLogadoEhGestorOuSindico = item);
  }

  abrirFecharModalDadosConta(event: number) {
    if (event === 1)
      this.modalService.open('app-dados-conta');
  }

  fecharModalDadosConta(event: number) {
    this.modalService.close('app-dados-conta');
  }

  abriModalNotificacoesMobile() {
    this.openNotificationModalMobile = true;
  }

  exibeModalAdesaoBoletoDigital() {
    this.openAdesaoModalMobile = !this.openAdesaoModalMobile;
  }

  exibeModalAdesaoIPTU(){
    this.openAdesaoIPTUModalMobile = !this.openAdesaoIPTUModalMobile;    
  }

  exibeModalAssinaturaDigital() {
    this.openAssinaturaDigitalMobile = !this.openAssinaturaDigitalMobile;

  }

  buscarImovel(argumentoBusca) {
    this.listarMenu().then((menuList) => {
      this.menuList = menuList.filter(obj => obj.label.toLowerCase().indexOf(argumentoBusca) > -1);
    });
  }

  carregarPagina() {
    this.setBuscarImovelSubject();
    this.validarPessoaLogada();
    if (this.stateService.visaoCentralAtendimento()) { this.listarMenuCentralAtendimento(); }
    else { this.listarMenu(); }
    this.verificarCondominioLogado();
    this.verificarImovelLogado();
  }

  defineStatusInicialMenu() {
    if (window.innerWidth > 800) {
      this.constant.statusMenu = true;
    } else if (window.innerWidth <= 799) {
      this.constant.statusMenu = false;
    }
  }

  closeMenuMobile() {
    this.stateService.openCloseMenuMobile();
  }

  extrairMenuCondominios(condominios) {
    // console.log("extrair", condominios);

    if (condominios == null) return [];
    let menu = [];
    condominios.forEach(obj => {
      let condominio = obj.condominio;
      let perfis = obj.perfis;
      let func = obj.funcionalidades;
      menu.push({
        idMenu: this.getCodigoCondominio(condominio),
        agenciaConta: condominio.agencia + "-" + condominio.contaEmpresa,
        label: condominio.nome,
        type: 1,
        ref: condominio,
        perfis: perfis,
        funcionalidades: func
      });
    });
    return menu;
  }

  extrairMenuCondominioCentralAtendimento(condominio) {
    if (condominio == null) return [];
    let menu = [];
    menu.push({
      idMenu: this.getCodigoCondominio(condominio),
      agenciaConta: condominio.agencia + "-" + condominio.contaEmpresa,
      label: condominio.nome,
      type: 1,
      ref: condominio,
      perfis: null,
      funcionalidades: null
    });
    return menu;
  }

  isMenuItemOpen(menuItemName: string, ...routes: string[]) {
    return this.openableMenuItems[menuItemName];
  }

  isRoute(...routes: string[]) {
    return routes.includes(this.rotaAtual);
  }

  openMenuItem(menuItemName: string) {
    if (this.openableMenuItems[menuItemName]) {
      this.openableMenuItems[menuItemName] = false;
    }
    else {
      switch (menuItemName) {
        case 'informativos':
          this.closeAllMenuItemsExcept('sobre');
          break;

        case 'reserva':
        case 'gestaoDePessoal':
        case 'relatoriosGerenciais':
          window.setTimeout(() => this.scrollThisSidebarToBottom(), 400);
          break;

        default:
          this.closeAllMenuItems();
      }

      this.openableMenuItems[menuItemName] = true;
    }
  }

  closeAllMenuItems() {
    for (const menuItem in this.openableMenuItems) {
      this.openableMenuItems[menuItem] = false;
    }
  }

  closeAllMenuItemsExcept(...menuItemsToIgnore: string[]) {
    for (const menuItem in this.openableMenuItems) {
      if (!menuItemsToIgnore.includes(menuItem))
        this.openableMenuItems[menuItem] = false;
    }
  }

  extrairMenuImovelCentralAtendimento(imovel) {
    if (imovel == null) return [];
    let menu = [];
    menu.push({
      idMenu: "IMO-" + imovel.codigoImovelPrinipal,
      agenciaConta: null,
      label: imovel.endereco,
      type: imovel.tipoPerfil,
      ref: imovel,
      perfis: null,
      funcionalidades: null
    });
    return menu;
  }

  extrairMenuProprietario(imoveis) {
    if (imoveis == null) return [];
    let menu = [];
    let tipo = 2;
    imoveis.forEach(obj => {
      menu.push({
        idMenu: "IMO-" + obj.imovel.codigoImovel,
        label: obj.imovel.endereco,
        agenciaConta: null,
        type: tipo,
        ref: obj.imovel
      });
    });
    return menu;
  }

  extrairMenuLocatario(imoveis) {
    if (imoveis == null) return [];
    let menu = [];
    let tipo = 3;
    imoveis.forEach(obj => {
      menu.push({
        idMenu: "IMO-" + obj.imovel.codigoImovelPrinipal,
        label: obj.imovel.enderecoimovel,
        agenciaConta: null,
        type: tipo,
        ref: obj.imovel
      });
    });
    return menu;
  }

  getCodigoCondominio(condominio: CondominioDTO) {
    //console.log("get", condominio)
    return "CO-" + condominio.contaEmpresa;
  }

  // getPrefixoImovel(tipoImovel) {
  //   if (tipoImovel == 2) return "P";
  //   return "L";
  // }

  // getTipoFromPrefixo(prefixo) {
  //   console.log("pref", prefixo, prefixo == "L")
  //   return (prefixo == "L") ? 3 : 2;
  // }

  listarMenuCentralAtendimento() {
    this.menuList = [];
    var condominioLogado = this.stateService.getCondominioLogado();
    if (condominioLogado) {
      var menuCondominio = this.extrairMenuCondominioCentralAtendimento(condominioLogado)
      this.menuList = this.menuList.concat(menuCondominio);
      //console.log("menu", this.menuList);
    }

    var imovelLogado = this.stateService.getImovelLogado();
    if (imovelLogado) {
      var menuImovel = this.extrairMenuImovelCentralAtendimento(imovelLogado)
      this.menuList = this.menuList.concat(menuImovel);
      //console.log(this.menuList);

    }
    return this.menuList;

  }



  listarMenu() {
    // const pessoa = this.stateService.getPessoaLogada();
    return this.condominioService.listarCondominiosImoveis(this.pessoa.cpfCnpj).then(item => {
      if (item) {
        var menuCondominio = this.extrairMenuCondominios(item.condominios);
        var menuProprietario = this.extrairMenuProprietario(item.imoveisProprietario);
        var menuLocatario = this.extrairMenuLocatario(item.imoveisLocatario);

        this.menuList = [];
        this.menuList = this.menuList.concat(menuCondominio);
        this.menuList = this.menuList.concat(menuProprietario);
        this.menuList = this.menuList.concat(menuLocatario);
        // console.log('list', this.menuList)
        return this.menuList;
      }
    });
  }

  isMenuActive(menuItem) {
    return (this.constant.condominioImovelAtivo === menuItem.idMenu);
  }

  searchEvent(buscaElement) {
    var argumento = buscaElement.value.toLowerCase();
    if (argumento.length > 1 && argumento.length <= 2) return;
    this.searchSubject.next(argumento);
  }

  limparFiltroMenu() {
    this.searchSubject.next('');
    this.buscaElemento.nativeElement.value = "";
  }

  exibirModalSalaoImoveis() {
      this.bannerModalService.exibirBannerSelecaoDeCondominio();

    // let exibeModalSessao = sessionStorage.getItem('exibeBannerFaleComAApsa');
    // let exibeModalLocal = JSON.parse(localStorage.getItem('exibeBannerFaleComAApsa'));
    // // let possuiPerfilSindico = this.stateService.possuiPerfilSindico_();
    // let obj = exibeModalLocal.find(item => item.id === this.stateService.getPessoaLogada().Id);

    // if (!exibeModalSessao && obj.exibeModal) {
    //   this.lightBoxesService.openModalImageLinkButton("image-with-link-button", {
    //     path: '../../../assets/img/BannerSalaoDeImoveis.png',
    //     userId: obj.id,
    //     url: 'https://apsa.com.br/salaodeimoveis'
    //   }, 0)
    // }

  }

  async selecionarMenu(menuItem): Promise<void> {
    return new Promise(async resolve => {
      this.stateService.closeAllComponentsHeader('.Header_Atalho_CentralAtendimento');
      this.stateService.closeAllComponentsHeader('.Header_Atalho_Perfil');
      sessionStorage.removeItem('dadosGerente');
      sessionStorage.removeItem('tiposSeguro');
      this.isMenuAdmOpen = false;

      if (menuItem.idMenu == this.constant.condominioImovelAtivo) {
        sessionStorage.removeItem('condominioLogado');
        this.constant.condominioImovelAtivo = null;
        return;
      }
      switch (menuItem.type) {
        case 1:
          await this.selecionarCondominio(menuItem.ref, menuItem.perfis, menuItem.funcionalidades);
          break;
        default:
          this.selecionarImovel(menuItem.ref);
          break;
      }
      this.constant.condominioImovelAtivo = menuItem.idMenu;

      this.verificacaoExibicaoBanners();
      resolve()
    })
  }

  selecionaMenuAdministracao() {
    this.stateService.closeAllComponentsHeader('.Header_Atalho_CentralAtendimento');
    this.stateService.closeAllComponentsHeader('.Header_Atalho_Perfil');
    this.constant.condominioImovelAtivo = null;
    this.isMenuAdmOpen = !this.isMenuAdmOpen;
  }

  selecionarCondominio(condSelecionado: CondominioDTO, perfis: Array<PerfilDTO>, funcionalidades: Array<FuncionalidadeDTO>): Promise<void> {
    return new Promise(resolve => {
      if (condSelecionado == undefined) {
        resolve();
        return;
      }

      var condominio = <CondominioDTO>condSelecionado;
      this.condominioService.logarCondominio(condominio, perfis, funcionalidades).then(() => {
        this.constant.condominioImovelAtivo = this.getCodigoCondominio(condominio);
        this.ehGestaoTotal = condominio.ehGestaoTotal;
        CondominioService.trocaCondominio.next(condSelecionado);
        this.condominioService.verificaAdesaoCondominio();
        this.carregarQuantidadeAlertas(condominio.agencia, condominio.contaEmpresa);
        this.router.navigate(['/home']);
        this.getBooleanOcorrenciaOuFaleComSindico();
        resolve();
      });
    })
  }

  selecionarCondominioUnico() {
    if (this.pessoa &&
      (
        !this.stateService.possuiPerfilLocatario() &&
        !this.stateService.possuiPerfilProprietario() &&
        !this.stateService.possuiPerfilGerencial()
      )
    ) {
      this.listarMenu().then(list => {
        if (list && (list.length === 1 && !this.isMenuActive(list[0]))) {
          this.selecionarMenu(list[0]);
          this.isMenuActive(list[0]);
        }
      })
    }
    return;
  }


  ehCondominioPrime(): boolean {
    var condominioLogado = this.stateService.getCondominioLogado();
    if (!condominioLogado) return false;
    return condominioLogado.prime === 1
  }

  selecionarImovel(imovel) {
    if (imovel == undefined) return;
    this.condominioService.logarImovel(imovel);
    if (imovel.codigoImovel == null || imovel.codigoImovel == undefined) {
      this.constant.condominioImovelAtivo = "IMO-" + imovel.codigoImovelPrinipal;
    } else {
      this.constant.condominioImovelAtivo = "IMO-" + imovel.codigoImovel;
    }
    CondominioService.trocaCondominio.next(null);
    this.router.navigate(['/home']);
  }

  setBuscarImovelSubject() {
    this.searchSubject.pipe(debounceTime(300)).subscribe(searchTextValue => {
      this.buscarImovel(searchTextValue);
    });
  }

  validarPessoaLogada() {
    var obj = JSON.parse(sessionStorage.getItem('pessoaLogada'));
    if (obj == 'undefined' || obj == undefined) {
      sessionStorage.clear();
      console.log("VEIO LOGIN EXPIRADO 1")
      this.router.navigate(['/'], { queryParams: { showMsg: true, title: 'Acesso expirado', mensagem: 'Faça o login novamente' } });
      return;
    }
    this.pessoa = obj;
  }

  verificarCondominioLogado() {
    var condominio = this.stateService.getCondominioLogado();
    if (condominio == null || condominio == undefined) return;
    if (this.constant.condominioImovelAtivo == null || this.constant.condominioImovelAtivo == undefined) {
      this.constant.condominioImovelAtivo = "CO-" + condominio.contaEmpresa;
    }
    //this.selecionarCondominio(condominio, condominio.perfis);
  }

  verificarImovelLogado() {
    var imovel = this.stateService.getImovelLogado();

    if (imovel == null || imovel == undefined) return;

    if (imovel.codigoImovel == null || imovel.codigoImovel == undefined) {
      this.constant.condominioImovelAtivo = "IMO-" + imovel.codigoImovelPrinipal;
    } else {
      this.constant.condominioImovelAtivo = "IMO-" + imovel.codigoImovel;
    }
  }

  zerarMenu() {
    this.constant.condominioImovelAtivo = null;
  }

  possuiPermissaoAcesso(idFuncionalidade: number) {
    return this.stateService.possuiAcessoFuncionalidade(idFuncionalidade);
  }

  possuiAcessoFuncionalidadeGeral(idFuncionalidade: number) {
    return this.stateService.possuiAcessoFuncionalidadeGeral(idFuncionalidade)
  }

  // // função para só liberar para Monica.Brito
  // podeVisualizarLancamentoProvenDescontos(){
  //   var pessoa = this.stateService.getPessoaLogada();
  //   return pessoa && pessoa.Id == "21183"; // só libera p Monica
  // }

  possuiPermissaoAcessoValidacaoCentralAtendimento(idFuncionalidade: number) {
    if (this.stateService.visaoCentralAtendimento())
      return this.stateService.possuiAcessoFuncionalidadeGeral(idFuncionalidade);
    else
      return this.stateService.possuiAcessoFuncionalidade(idFuncionalidade);
  }


  scrollParaOMenuAberto() {
    let arrayDeObjetosComClasseIsOpen = document.getElementsByClassName('MenuList_Content_Item __is-open')
    var menuAbertoAtual = arrayDeObjetosComClasseIsOpen[arrayDeObjetosComClasseIsOpen.length - 1];
    if (menuAbertoAtual) {
      let posicaoMenuAberto = menuAbertoAtual['offsetTop'];

      let arraySidebarContent = document.getElementsByClassName('Sidebar_Content')
      let sidebarAtual = arraySidebarContent[arraySidebarContent.length - 1]

      if (sidebarAtual) {
        let alturaSideBarAtual = sidebarAtual['offsetTop'];
        sidebarAtual.scrollTo(0, (posicaoMenuAberto + alturaSideBarAtual));
      }
    }
  }

  handleClickVisiteONossoSite() {
    this.router.navigate(['/seguros']);
  }

  handleClickComodidades() {
    // this.router.navigate(['/descontos-parceiros']);

    this.stateService.abrirLinkDeComodidadesDeAcordoComOPerfil()
  }

  handleFornecedoresOptionClick() {
    this.stateService.openExternalLink('https://www.revistasindico.com.br/guia');
  }

  carregarQuantidadeAlertas(agencia, contaEmpresa) {
    if (this.ehGestaoTotal) {
      this.totalAlertas = null;
      this.listNotifications = null;

      var cpfPessoaLogada = (this.stateService.getPessoaLogada()) ? this.stateService.getPessoaLogada().CpfCnpj : null;
      var profileCode = "1";
      let origem;

      this.platform.is('desktop') ? origem = '1' : this.platform.is('mobile') ? origem = '2' : null;

      this.alertaService.getTotalAlertas(origem, cpfPessoaLogada, profileCode, agencia, contaEmpresa).subscribe((response) => {
        this.totalAlertas = response;
      });

      this.alertaService.getAlertas(origem, cpfPessoaLogada, profileCode, agencia, contaEmpresa).subscribe(response => {
        this.listNotifications = response.Data;
      });
    }
  }

  private scrollThisSidebarToBottom() {
    const elements = document.querySelectorAll(".Sidebar_Content");
    const el = elements[elements.length - 1];

    el.scrollTo({ top: el.scrollHeight, behavior: 'smooth' });
  }

  public returnPlaceHolder(): string {
    return 'Deixe seu comentário aqui. (Obrigatório)'
  }

  openModalAvaliacao() {
    if(this.stateService.platformIsDeviceMobile()) {
      this.stateService.exibirModalDeAvaliacaoMobile(true)
      return
    }

    this.modalAvaliacao = true
  }

  closeModalAvaliacao() {
    this.modalAvaliacao = false;
  }

  createForm() {
    this.modalAvaliacaoForm = this.formbuilder.group({
      descricao: [''],
      nota: [null, Validators.required]
    });
  }

  get obterLojaApp() {
    const linkLoja: string = this.platform.is('ios') ? 
      'https://apps.apple.com/br/app/apsa/id1171170928' 
      : 'https://play.google.com/store/apps/details?id=com.apsa&hl=pt_BR'

    const nomeLoja: string = this.platform.is('ios') ? 'App Store' : "Google Play Store"

    return {
      linkLoja,
      nomeLoja
    }
  }

  enviarAvaliacao() {
    const mensagem: string = this.modalAvaliacaoForm.get('descricao').value
    const nota: number = this.modalAvaliacaoForm.get('nota').value
    const avaliacao = {mensagem, nota}
    const { linkLoja, nomeLoja } = this.obterLojaApp

    this.stateService.registrarReclamacao(avaliacao).subscribe(
      item => {
        if(nota == 3){ 
          this.lightBoxesService.openYesOrNoWithLink(
            'yes-or-no-with-link', {
            title: 'Obrigado pela sua avaliação!',
            text: `Gostaria de avaliar nosso aplicativo na ${nomeLoja}?`,
            link: null,
          }, 0
          )
          .then((resposta: string) => {
            if(resposta == 'yes') {
              this.stateService.openExternalLink(linkLoja);
            }
          })
          return
        }
        this.lightBoxesService.open(
          'custom-generico', {
          title: 'Obrigada pela sua avaliação!',
          text: item
        }, 5000
        )
      }
      , err => {
        this.lightBoxesService.open(
          'custom-generico', {
          title: 'Atenção',
          text: err.error.message
        }, 5000
        )
      }
    )
  }

  getAceiteTermosPoliticas() {
    if(!sessionStorage.getItem('usuarioAceitouTermos')) {
      this.stateService.usuarioAceitouTermosCondicoes().subscribe(
        response => {
          this.userAceitouTermos = response.usuarioAceitou;
          
          if(this.userAceitouTermos) {
            sessionStorage.setItem('usuarioAceitouTermos', 'true');
          } else if(!response.txt_TermosUso || response.txt_TermosUso === null || response.txt_TermosUso === '') {
            this.userAceitouTermos = true;
            sessionStorage.setItem('usuarioAceitouTermos', 'true');
          }
        },
        error => {
          this.userAceitouTermos = true;
          sessionStorage.setItem('usuarioAceitouTermos', 'true');
          console.log(error)
        }
      )
    }
  }

  verificaAceiteUsuario(): boolean {
    if(sessionStorage.getItem('usuarioAceitouTermos') || this.userAceitouTermos) {
      return true;
    }
    return false;
  }

  private async sleep(ms: number) {
    return new Promise<void>((resolve) => {
      window.setTimeout(() => {
        resolve();
      }, ms);
    });
  }

  verificacaoExibicaoBanners() {
    let condominioLogado = this.stateService.getCondominioLogado();
    let imovelLogado = this.stateService.getImovelLogado();
    let pessoaLogada = this.stateService.getPessoaLogada();

    const payload = {
      cpfCnpj: pessoaLogada.CpfCnpj,
      idCampanha: 6,
      codigoImovel: 0,
      codweb: 0,
      sqsubd: 0,
      squnid: 0,
      codUnidade: 0,
      descricaoUnidade: ""
    }

    if(imovelLogado) {
      payload.codigoImovel = imovelLogado.codigoImovelPrinipal;

      this.condominioService.verificarAdesaoHotSite(payload).subscribe({
        next: res => { !res.aderido ? this.exibirBannerHotSite(res) : this.exibirBannersCondominioDigital(); }, 
        error: erro => this.exibirBannersCondominioDigital()
      })

    } else {
      this.stateService.listarCondominiosUnidades().subscribe({
        next: listarCondominiosUnidades =>{

          let condominioUnidadesBoletoFisico: any[] = 
          localStorage.getItem('condominioUnidadesBoletoFisico') ? 
          JSON.parse(localStorage.getItem('condominioUnidadesBoletoFisico')) :
          [];
          
          // console.log('condominioUnidadesBoletoFisico', condominioUnidadesBoletoFisico);
          
          let condominioSelecionado = listarCondominiosUnidades.find(condominioUnidade => condominioUnidade.contaEmpresaCondominio === parseInt(condominioLogado.contaEmpresa))
          // console.log('condominioSelecionado', condominioSelecionado);
          
          let condominioCadastradoLocalStorage = condominioUnidadesBoletoFisico.find(condominioUnidade => {
            return condominioUnidade.condominio == condominioSelecionado?.contaEmpresaCondominio
          })

          // console.log('condominioCadastradoLocalStorage', condominioCadastradoLocalStorage);
          
          let unidadesFiltradasCondominioSelecionado = condominioSelecionado?.unidades?.filter(unidade => {
            if(condominioCadastradoLocalStorage) {
              return condominioCadastradoLocalStorage?.unidades?.find(unidadeLocalStorage => unidadeLocalStorage !== unidade.idUnidade)
            } else {
              return true;
            }
          });
          
          let unidadeSelecionada = 
          unidadesFiltradasCondominioSelecionado && unidadesFiltradasCondominioSelecionado.length > 0 ? 
          unidadesFiltradasCondominioSelecionado[0] : 
          condominioSelecionado?.unidades[0] ? condominioSelecionado.unidades[0] : null;

          // console.log('unidadesFiltradas', unidadeSelecionada);
        
          if(unidadeSelecionada) {
            payload.codweb = condominioSelecionado.contaCondominio;
            payload.sqsubd = unidadeSelecionada.idSubdivisao;
            payload.squnid = unidadeSelecionada.idUnidade;
            payload.codUnidade = unidadeSelecionada.idUnidade;
            payload.descricaoUnidade = unidadeSelecionada.descricaoUnidade;
          }
  
          this.condominioService.verificarAdesaoHotSite(payload).subscribe({
            next: res => {
              if(res.campanhaId && !res.aderido) {
                this.exibirBannerHotSite(res, payload);
              }  else {
                this.exibirBannersCondominioDigital(); 
              } 
            }, 
            error: erro => this.exibirBannersCondominioDigital()
          })
  
        } 
      })
    }
    
  }

  async exibirBannerHotSite(payload, objUnidade = null) {
    const response: 'yes' | 'no' = await this.lightBoxesService.openTextLinkOptions(
      'text-link-options', 
      {
        title: 'A partir de MAIO, nossos boletos e comunicados serão enviados por e-mail.',
        subtitle: `Você pode continuar recebendo de forma física ${objUnidade?.descricaoUnidade ? `na unidade <strong>${objUnidade?.descricaoUnidade}</strong>` : ''}`,
        link: payload.linkHotSite,
        buttonTxt1: 'Fechar',
        buttonTxt2: 'Boleto/comunicados físicos'
      }
      )
      
      if(response === 'yes') {
        this.stateService.openExternalLink(payload.linkHotSite)
      } else {
        const getIndexToSlice = (payload.linkHotSite as string).lastIndexOf('/');
        const leadFormatado = (payload.linkHotSite as string).slice(getIndexToSlice + 1);
        this.condominioService.desabilitarSchatzCadastroLeads(leadFormatado).subscribe({
          next: ()=> {}, error: () => console.log('Erro ao desabilitar cadastro Schatz')
        })
      }
      
    let condominioJaAderiuAnteriormente: boolean = false;
    
    if(this.stateService.getCondominioLogado()) {
      let condominioUnidadeAderida = {
        condominio: parseInt(this.stateService.getCondominioLogado().ContaEmpresa),
        unidades: [objUnidade.squnid]
      }
      
      // console.log('condominioUnidadeAderida', condominioUnidadeAderida);
  
      let condominioUnidadesBoletoFisico: any[] = 
      localStorage.getItem('condominioUnidadesBoletoFisico') ? 
      JSON.parse(localStorage.getItem('condominioUnidadesBoletoFisico')) :
      [];
  
      condominioUnidadesBoletoFisico.map((item, index ) => {
        if(item.condominio === condominioUnidadeAderida.condominio) {
          if(!item.unidades.includes(condominioUnidadeAderida.unidades[0])) {
            item.unidades = [...item.unidades, condominioUnidadeAderida.unidades[0]];
          }
          condominioJaAderiuAnteriormente = true;
        }
      })
      if(!condominioJaAderiuAnteriormente) {
        condominioUnidadesBoletoFisico = [...condominioUnidadesBoletoFisico, condominioUnidadeAderida];
      }
  
      localStorage.setItem('condominioUnidadesBoletoFisico', JSON.stringify(condominioUnidadesBoletoFisico));
    }
    
  }

  async exibirBannersCondominioDigital() {
  //   console.log('disparou banners condominio digital!');
    const result = await this.bannerModalService.exibirBannerSelecaoDeCondominio();
      
    if(result === 'NOT_DISPLAYED')
      if(this.stateService.getCondominioLogado()) this.condominioService.exibirBanner2ViaBoletos();
  }

  // atualizarBusca() {
  //   this.resultadoBusca = this.itensBusca.filter(item => item.titulo.toUpperCase().indexOf(this.busca.toUpperCase().trim()) !== -1);
  // }
}
