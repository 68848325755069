<div class="debug-customer" [ngClass]="{'__is-closed': !isOpen}">
  <div class="toggle">
    <button (click)="toggleVisibility()" [title]="!isOpen ? 'Debugger de clientes' : ''">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>

  <form [formGroup]="formGroup">
    <div class="FormGroup">
      <label class="FormGroup_Label">
        Selecione o cliente:
      </label>
  
      <div class="FormGroup_Input">
        <select formControlName="customer">
          <option selected hidden [value]="null">
            Selecione um cliente
          </option>
  
          <option
            *ngFor="let customer of customers"
            [value]="customer.nomeApp"
          >
            {{customer.nomeApp}}
          </option>
        </select>
      </div>
    </div>
  </form>
</div>