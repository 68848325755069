import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-modal-with-text-field',
  templateUrl: './modal-with-text-field.component.html',
  styleUrls: ['./modal-with-text-field.component.scss'],
})
export class ModalWithTextFieldComponent implements OnInit {

  public isSubmitted: boolean = false;

  @Input() form: UntypedFormGroup;
  @Input() modalAvaliacaoOpen : boolean;
  @Input() formPlaceholder: string;
  @Output() closeModalAvaliacao = new EventEmitter()
  @Output() onSubmit = new EventEmitter()
  
  constructor() { }

  ngOnInit() {}

  
  returnPlaceHolder():string{
    return 'Envie a sua sugestão ou melhoria aqui...'
  }

  close(){
    this.reset()
    this.closeModalAvaliacao.emit()
  }

  reset() {
    this.form.reset();
    this.isSubmitted = false;
  }

  confirmar(): void {
    this.isSubmitted = true;
    if(this.form.valid) {
      console.log(this.form)
      this.onSubmit.emit();
      this.close();
    }
  }

  get descricaoValue() {
    return this.form.get('descricao');
  }

  getErrorClass() {
    return {
      '__is-error': this.isSubmitted && this.form.get('descricao').invalid
    };
  }
}

