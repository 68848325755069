<div class="Lightbox">
  <div class="Modal_Cancelar_Adesao_Recorrencia">
    <div class="Modal_Cancelar_Adesao_Recorrencia_Content">
  
      <div class="Block">
        <div class="Block_Container">
          <div class="Adesao_Mantida_Cancelada">
            <div class="Adesao_Mantida_Cancelada_Content">
              <div class="Adesao_Mantida_Cancelada_Header">
                <p>Deseja realmente cancelar os pagamentos automáticos em cartão de crédito?</p>
                <p>Quando você paga com cartão de crédito, evita multas por atraso e acumula pontos + benefícios do seu cartão.</p>
              </div>
  
              <div class="Buttons_Container">
                <div class="Buttons_Content">
                  <button class="Button Button_Primary Button_Solid __is-full" [ngClass]="{ '__is-inactive': false }"
                    [disabled]="false">
                    <div class="Button_Content" (click)="keepAcession()">
                      <div class="Button_Content_Label">Manter o pagamento automático</div>
                    </div>
                  </button>
                
                  <div class="Cancelar_Adesao" (click)="cancelAcession()">
                    <p>Cancelar o serviço e perder os benefícios</p>
                  </div>
                  
                </div>
              </div>
            </div>
  
            <div class="Banner">
              <img src="https://areaexclusiva.apsa.com.br/digital/assets/img/undraw_credit_card_payment.png" alt="banner">
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
