import { StateService } from './../../services/stateService';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { EnvioDocumentoDTO } from './../../../modules/envio-documento/services/DTO/response/EnvioDocumento.DTO';

@Component({
  selector: 'app-list-envio-documento',
  templateUrl: './list-envio-documento.component.html',
  styleUrls: ['./list-envio-documento.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListEnvioDocumentoComponent implements OnInit {
  @Input() listaDocumentos: EnvioDocumentoDTO[] = [];
  @Input() componenteRota: string;

  constructor(public stateService: StateService) { }

  ngOnInit() { }
  
  formatValue(n) {
    return n.replace(/^[0.]+/, "")
  }

  getStatusColorClass(status: number) : string {
    let cor: string;

    status == 2 ? cor = '__is-green' : cor = '__is-yellow';

    return cor;
  }
}
