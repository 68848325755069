<!-- <ion-content> -->
<div class="Modal Modal_PoliticasCondicoes" [ngClass]="{ '__is-open': open }">
  <!-- TODO: Adicionar a class "__is-open" para habilitar o modal -->

  <div class="Modal_Area">
    <div class="Modal_Header">
      <img
        *ngIf="this.stateService.getTemaAcessibilidade() === 'Theme_Default' || this.stateService.getTemaAcessibilidade() === 'Theme_Grayed' "
        src="../../../../assets/img/logo_header_desktop.png" alt="logo apsa">
      <img *ngIf="this.stateService.getTemaAcessibilidade() === 'Theme_Dark'"
        src="../../../../assets/img/logo_branco_header_desktop.png" alt="logo apsa">
      A APSA atualizou os "Termos e Condições de Uso" de seu site/app. <br /> Para prosseguir é necessário ler e aceitar
      os termos abaixo:
    </div>
    <div class="Modal_Content">

      <form action="" [formGroup]="this.form">
        <div class="checkbox-area">
          <ion-checkbox formControlName="aceiteTermo" color="primary"></ion-checkbox>
          <p>Li e aceito os
            <a (click)="goToExternalLink('https://apsa.com.br/termos-e-condicoes-de-uso')">
              "Termos e Condições de Uso".
              <i class="fas">&#xf08e;</i>
            </a>
          </p>
        </div>

        <p>
          A APSA também atualizou a sua
          <a (click)="goToExternalLink('https://apsa.com.br/politica-de-privacidade')">
            "Politica de Privacidade".
            <i class="fas">&#xf08e;</i>
          </a>
        </p>

        <ds-button type="Solid" [full]="true" [inactive]="!this.form.valid"
          (click)="this.form.valid && aceitarTermosCondicoes($event)">
          Prosseguir
        </ds-button>
      </form>

    </div>

  </div>

</div>
<!-- </ion-content> -->