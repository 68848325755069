<div class="Lightbox">
  <div class="Popup Popup_ComunicacaoGenerica">
    <div class="Popup_Header">
      <div class="Popup_Header_Title">Titulo genérico</div>
    </div>
    <div class="Popup_Content">
      <div class="Popup_Content_MsgDefault">
        Aqui entrará qualquer texto
      </div>
    </div>
    <div class="Popup_Footer">
      <div class="Popup_Footer_Buttons">
        <button class="Button Button_Primary Button_Outline __is-full" (click)="close()">
          <div class="Button_Content">
            <div class="Button_Content_Label">
              Não
            </div>
          </div>
        </button>

        <button class="Button Button_Primary Button_Solid __is-full" (click)="confirmar()">
          <div class="Button_Content">
            <div class="Button_Content_Label">
              Sim
            </div>
          </div>
        </button>
      </div>
    </div>
  </div> 
</div>
