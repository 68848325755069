<div class="Lightbox"  [ngClass]="{ '__is-open': isOpen }">
  <div class="Popup Popup_ComunicacaoGenerica">
    <div class="Popup_Header">
      <div class="Popup_Header_Title">Você está gostando do nosso APP?</div>
    </div>

    <div class="Popup_Content">
      <div class="Popup_Content_MsgDefault">
        Para avaliar você será redirecionado para a {{dadosLoja.nomeLoja}}
      </div>
    </div>
  
    <div class="Popup_Footer">
      <div class="Popup_Footer_Buttons">
        <button class="Button Button_Primary Button_Outline __is-full" (click)="recusar()">
          <div class="Button_Content">
            <div class="Button_Content_Label">
              Cancelar
            </div>
          </div>
        </button>

        <button class="Button Button_Primary Button_Solid __is-full" (click)="confirmar()">
          <div class="Button_Content">
            <div class="Button_Content_Label">
              Ok
            </div>
          </div>
        </button>
      </div>
    </div>

    <!-- <div class="Popup_Link_Container">
      <button (click)="link()"></button>
    </div> -->
  </div> 
</div>
