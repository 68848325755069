import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ElementRef,
  OnDestroy
} from "@angular/core";

import { LightBoxesService } from '../../lightboxes';

@Component({
  selector: "app-default",
  templateUrl: "./default.component.html",
  styleUrls: ["../popup.scss"],
  encapsulation: ViewEncapsulation.None
})
export class DefaultComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() className: string;
  private element: any;

  constructor(private service: LightBoxesService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener("click", el => {
      if (el.target.className === "Block_Popup_Lateral") {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.service.add(this);
  }

  ngOnDestroy(): void {
    this.service.remove(this.id);
    this.element.remove();
  }

  open(time): void {
    const n = this.className;
    const a = this.element;
    this.element.firstChild.classList.add(this.className);

    if (time)
      setTimeout(function() {
        a.firstChild.classList.remove(n);
      }, time);
  }

  close(): void {
    this.element.firstChild.classList.remove(this.className);
  }
}
