import { Injectable } from '@angular/core';
import { StateService } from '../services/stateService';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import CustomerService from './customer.service';


@Injectable({
  providedIn: 'root'
})
export class HeaderService {


  private urlApiHost: string;
  private methods = environment.apiCondominioDigital.endpoints.methods;



  constructor(private http: HttpClient, public stateService: StateService, private customerService: CustomerService) {
    this.urlApiHost = environment.apiCondominioDigital.host;
  }


  private getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.stateService.getTokenApiCondominioDigital()
    });
  }

  getAssuntos() {
    let perfis: string[] = [];
    let codominioLogado = this.stateService.getCondominioLogado();

    if(codominioLogado) {
      perfis = codominioLogado.perfisIds.map(id => id.toString());
    } else {

    let jsonString = JSON.parse(sessionStorage.getItem('listaCondominiosImoveis'));
    if(jsonString) {
      if(jsonString.condominios) {
      jsonString.condominios.map(c => c.perfis.filter(p => {
        perfis.push(p.idPerfil)
      }))
    }
    if(jsonString.imoveisLocatario) {
      jsonString.imoveisLocatario.map(c => c.perfis.filter(p => {
        perfis.push(p.idPerfil)
      }))
    }
    if(jsonString.imoveisProprietario) {
      jsonString.imoveisProprietario.map(c => c.perfis.filter(p => {
        perfis.push(p.idPerfil)
      }))
    }
    }
  }

    // if (this.stateService.possuiPerfilCondomino()) perfis.push('5');
    // if (this.stateService.possuiPerfilEquipe()) perfis.push('17');
    // if (this.stateService.possuiPerfilGestor()) perfis.push('16');
    // if (this.stateService.possuiPerfilMoradorGT()) perfis.push('18');
    // if (this.stateService.possuiPerfilProprietario()) perfis.push('2');
    // if (this.stateService.possuiPerfilLocatario()) perfis.push('1');
    // if (this.stateService.possuiPerfilSindico()) perfis.push('3');
    // if (this.stateService.possuiPerfilSindico_()) perfis.push('3');
    // if (this.stateService.possuiPerfilFuncionario()) perfis.push('4');

    var parametros = new HttpParams().set('listaPerfis', perfis.join(','))

    var endpoint = this.urlApiHost + this.methods.condomino.getAssunto;
    
    return this.http.get<any>(`${endpoint}`, { params: parametros, headers: this.getHeader() })
  }
  enviarMensagem(data) {
    var endpoint: string = '';
    if(this.customerService.getCustomer().id === '1') {
     endpoint = this.urlApiHost + this.methods.condomino.enviarFaleComApsa;
     
    } else {
      endpoint = this.urlApiHost + this.methods.condomino.enviarFaleComDoittOuHH;
      data = {...data, idOrigemDoitt: this.customerService.getCustomer().id }
    }
    return this.http.post<any>(
      `${endpoint}`,
      data,
      { headers: this.getHeader(), withCredentials: false }
    );
  }
}
