// import { FileChooser } from '@ionic-native/file-chooser/ngx';
// import { File } from '@ionic-native/file/ngx';
// import { FilePath } from '@ionic-native/file-path/ngx';
import { LightBoxesService } from '../../lightboxes';
// import { Base64 } from '@ionic-native/base64/ngx';
import { StateService } from 'src/app/shared/services/stateService';
// import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { Platform } from '@ionic/angular';
import { ImagemArquivoMaloteDTO } from 'src/app/modules/envio-documento/services/DTO/request/ImagemArquivoMalote.DTO';
import { Component, ViewEncapsulation, Injectable } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FilePicker, PickFilesResult } from '@capawesome/capacitor-file-picker';


@Injectable({ providedIn: 'root' })
export class InputFileMobile {

  public path;

  constructor(
    private lightBoxesService: LightBoxesService,
    // private base64: Base64,
    public stateService: StateService,
    // private filePicker: IOSFilePicker,
    public platform: Platform,
    // private fileNGX: File
  ) { }

  async anexar(mimeTypeFilter: string[] = null): Promise<ImagemArquivoMaloteDTO> {
    let file;
    let filtro: string[] = mimeTypeFilter ? mimeTypeFilter : ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'application/msword', 'application/vnd.ms-excel'];
    await FilePicker.pickFiles({ types: filtro, limit: 1, readData: true })
      .then(data => file = this.montaImagem(data.files[0].mimeType, data.files[0].name, data.files[0].data))
    return file;
  }


  //Inicio verificação de tamanho
  // extrairFileInfo(path) {
  //   return this.fileNGX.resolveLocalFilesystemUrl(path);
  // }

  extrairSizeMetadata(fileInfo) {
    let size;
    fileInfo.getMetadata(metadata => size = metadata.size)
    return size;
  }

  verificarTamanhoUpload(size) {
    return this.stateService.verificarTamanhoUpload(size)
  }
  //Fim da verificação de tamanho 

  // Função auxiliar android => Codifica o arquivo em base 64 e retorna além dele o nome e o tipo, utilizando a função abaixo
  // async encodeFileBase64Android(nativePath) {
  //   let encoded;
  //   await this.base64.encodeFile(nativePath).then(item => {
  //     encoded = item
  //   })
  //   let [fileName, fileType] = this.devolveFileNameEfileTypeAndroid(nativePath);
  //   return [fileType, fileName, encoded]
  // }


  // Função auxiliar android => Devolve o nome do arquivo que será montado e o tipo dele
  devolveFileNameEfileTypeAndroid(nativeUri) {
    let filetype;

    if (nativeUri.toLowerCase().includes(".pdf")) {
      filetype = "pdf";
    }
    else if (nativeUri.toLowerCase().includes(".xls")) {
      filetype = "xls";
    }
    else if (nativeUri.toLowerCase().includes(".xlsx")) {
      filetype = "xlsx";
    }
    else if (nativeUri.toLowerCase().includes(".jpg")) {
      filetype = "image/jpg";
    }

    else if (nativeUri.toLowerCase().includes(".jpeg")) {
      filetype = "image/jpeg";
    }
    else if (nativeUri.toLowerCase().includes(".png")) {
      filetype = "image/png";
    }

    else {
      this.lightBoxesService.open(
        'custom-generico',
        { title: 'Atenção', text: 'O formato de arquivo informado não é aceito.' },
        5000
      );
      return;
    }

    let fileName = nativeUri.split('/')[nativeUri.split('/').length - 1]

    return [fileName, filetype];
  }


  // Função que monta o arquivo final
  montaImagem(fileType, fileName, base64File) {
    var src = this.stateService.getCaminhoImagemExibicao(fileType, base64File)
    let tamanhoEmBytes = (base64File.toString().length * 3) / 4
    var file = new ImagemArquivoMaloteDTO(base64File, fileType, fileName, src, tamanhoEmBytes);
    return file;
  }

  // Fim função auxiliares android



  // Função principal IOS => TODO : Refatorar da mesma forma do android
  async anexarDocumentosIOS(): Promise<ImagemArquivoMaloteDTO> {
    let file;

    await FilePicker.pickFiles({ types: ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'application/msword'], limit: 1, readData: true })
      .then(data => file = this.montaImagem(data.files[0].mimeType, data.files[0].name, data.files[0].data))

    return file;
  }

  //devolve o path nativo IOS
  resolverPathIOS(uri) {
    let correctPath = uri.substr(0, uri.lastIndexOf('/') + 1);
    return correctPath;
  }

  //função auxiliar que gera o nome e o tipo através do path
  defineNameTypeIOS(path) {
    let fileType;

    if (path.toLowerCase().includes(".pdf")) {
      fileType = "pdf";
    }
    else if (path.toLowerCase().includes(".xls")) {
      fileType = "xls";
    }
    else if (path.toLowerCase().includes(".xlsx")) {
      fileType = "xlsx";
    }
    else if (path.toLowerCase().includes(".jpg")) {
      fileType = "image/jpg";
    }

    else if (path.toLowerCase().includes(".jpeg")) {
      fileType = "image/jpeg";
    }
    else if (path.toLowerCase().includes(".png")) {
      fileType = "image/png";
    } else {
      this.lightBoxesService.open(
        'custom-generico',
        { title: 'Atenção', text: 'O formato de arquivo informado não é aceito.' },
        5000
      );
      return;
    }

    let fileName = path.split('/')[path.split('/').length - 1]

    return [fileName, fileType];
  }


}
