<div
  class="announcement-message"
  [ngClass]="{'__is-primary': primaryBackground}"
  *ngIf="announcement && announcement.exibirMensagem && !userAlreadyDismissed"
>
  <div class="row">
    <p>
      {{ announcement.mensagem }}
    </p>
  
    <button class="mensagem__close" (click)="dismiss()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  
  <button *ngIf="announcement.exibirLink" class="access-button" (click)="handleAccessClick()">
    {{this.route === 'funcionario' ? 'Peça aqui sua cotação' : 'Acesse'}} <i class="fas fa-caret-right"></i>
  </button>
</div>

<div
  class="announcement-image"
  *ngIf="announcement && announcement.exibirBanner && !userAlreadyDismissed"
>
  <img
    (click)="announcement.link && handleAccessClick()"
    [src]="isDesktop() ? announcement.urlImagemDesktop : announcement.urlImagemMobile"
  >
</div>