
<div class="FormGroup">
  <div class="FormGroup_Label">Selecione o bloco</div>
  <div class="FormGroup_Input">
    <div class="ListSelect">
      <div class="ListSelect_Content __is-grid-list">
        <div class="ListSelect_Content_Item" [ngClass]="{
            '__is-selected': block.Uid === item.Uid, 'is-disabled': this.disabled
          }" (click)="!this.disabled && selectBlock(item)" *ngFor="let item of blocks">
          <a>
            <div class="Content_Item_Label">{{ item.Name }}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="Block">
  <div class="Block_Title">Selecione o bloco</div>
  <div class="Block_Container">
  </div>
</div> -->
