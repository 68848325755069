import { Platform } from '@ionic/angular';
import { Component, OnInit, Output, Input, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SessionStorageService } from '../../services/session-storage.service';
import { GerenteContaDTO } from '../../services/DTO/response/GerenteContaDTO';
import { CondominioService } from '../../services/condominio.service';
import { StateService } from '../../services/stateService';
import { Texts } from './texts';
import { HeaderService } from 'src/app/shared/services/header.service';
import { Validations } from '../../utils/validations';
import { LightBoxesService } from '../lightboxes';
import { ActivatedRoute, Router } from '@angular/router';
import { IFaleComApsaDTO } from '../../services/DTO/Request/IFaleComApsa.DTO';
import { Subscription } from 'rxjs';
import { Assuntos } from './assuntos';
import CustomerService from '../../services/customer.service';

@Component({
  selector: 'app-fale-com-a-apsa',
  templateUrl: './fale-com-a-apsa.component.html',
  styleUrls: ['./fale-com-a-apsa.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class FaleComAApsaComponent implements OnInit, AfterViewInit, OnDestroy {
  public formFaleComApsa: UntypedFormGroup;
  public gerenteConta: GerenteContaDTO;
  public textsCustom = Texts;
  // public listAssuntos = null;
  public nomeCondominio: string;
  public isSubmitted: boolean = false;
  public isMobile: boolean = this.platform.is('mobile');
  public isDesktop: boolean = this.platform.is('desktop');
  public pessoaLogada = this.stateService.getPessoaLogada();
  public possuiPerfilSindico: boolean = false;
  public subscription: Subscription;
  @Input() load: boolean;
  @Input() listaAssuntos: any[] = [];

  constructor(
    private sessionStorageService: SessionStorageService,
    private fb: UntypedFormBuilder,
    private condominioService: CondominioService,
    public stateService: StateService,
    private headerService: HeaderService,
    public lightBoxesService: LightBoxesService,
    public activedRoute: ActivatedRoute,
    private router: Router,
    public platform: Platform,
    public customerService: CustomerService
  ) {
    activedRoute.params.subscribe(val => {
      this.ngOnInit();
      this.possuiPerfilSindico = this.stateService.possuiPerfilSindico_();
    });
  }

  ngOnInit() {
    
    this.subscription = CondominioService.trocaCondominio.subscribe(
      condominio => {
        sessionStorage.removeItem('dadosGerente');
        sessionStorage.removeItem('tiposSeguro');
        
        if(condominio) {
          this.fetchData();
        }
      },
    )
    
    this.formFaleComApsa = this.fb.group({
      tipoMensagem: ['1', Validators.required],
      mensagem: ['', [Validators.required, Validators.minLength(8)] ],
      idAssunto: ['', Validators.required],
      contaEmpresa: this.sessionStorageService.get('condominioLogado') ? [JSON.parse(this.sessionStorageService.get('condominioLogado')).contaEmpresa] : undefined,
      idPessoa: [JSON.parse(this.sessionStorageService.get('pessoaLogada')).id],
    });
    
    // console.log('saida onInit:', this.listaAssuntos);

    if (this.listaAssuntos && this.listaAssuntos[0])
      this.formControls.idAssunto.setValue(this.listaAssuntos[0].idAssunto)
    
  }
  
  ngAfterViewInit(): void {
    // this.getListaAssuntos();
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  fetchData() {
    this.gerenteConta = null;
    // this.listAssuntos = null;

    if (this.stateService.getCondominioLogado()) {
      const dadosGerente = JSON.parse(sessionStorage.getItem('dadosGerente'));
      if(!dadosGerente){
        const contaEmpresa = this.stateService.getCondominioLogado().ContaEmpresa;
  
        this.condominioService.getDadosGerenteConta(contaEmpresa).subscribe(response => {
          sessionStorage.setItem('dadosGerente', JSON.stringify(response));
    
          this.gerenteConta = response;
          this.load = false;
        });  
      } else if(dadosGerente){
        this.gerenteConta = dadosGerente;
        this.load = false;
      }
    } else if (this.stateService.getImovelLogado()) {
      this.gerenteConta = null;
      // this.listAssuntos = null;
      this.load = false;
    }
    
    // if (!this.listAssuntos) {
    // }
  }

  getErrorMessage(input: string) {
    if (this.formControls[input].errors) {
      return this.textsCustom.form.error[input][
        Object.keys(this.formControls[input].errors)[0]
      ];
    }
  }

  getGerenteConta() {
    if (this.stateService.getCondominioLogado()) {
      const contaEmpresa = this.stateService.getCondominioLogado().ContaEmpresa;

      this.condominioService.getDadosGerenteConta(contaEmpresa).subscribe(response => {
        this.gerenteConta = response;
      });
    }
  }

  enviarMensagem() {
    this.isSubmitted = true;
    let condominioLogado = this.stateService.getCondominioLogado();
    let getPerfisUser = this.stateService.obterPerfisEmTodosOsCondominiosEImoveis();
    let ids: Array<number> = [];

    for(let perfil of getPerfisUser) {
      ids.push(perfil.idPerfil);
    }

    let obj = {} as IFaleComApsaDTO;
    
    obj = this.formFaleComApsa.value;
    obj.perfis = condominioLogado ? condominioLogado.perfisIds : ids;
    obj.contaEmpresa = condominioLogado ? JSON.parse(condominioLogado.contaEmpresa) : null;


    // if (this.formFaleComApsa.invalid) {
    //   Validations.markFormGroupTouched(this.formFaleComApsa);
    //   return;
    // }

    if(this.formFaleComApsa.valid) {
      this.headerService.enviarMensagem(obj).subscribe(result => {
        this.stateService.openCloseComponent('.Header_Atalho_CentralAtendimento')
        this.lightBoxesService.open(
          'custom-generico',
          { title: '', text: "Solicitação aberta com sucesso!" },
          5000
        );
        if (window.innerWidth < 800)
          this.router.navigate(['/atalhos']);
      },
        err => {
          console.log(err);
          this.lightBoxesService.open(
            'custom-generico',
            { title: '', text: "Não foi possível enviar a mensagem" },
            5000
          );
        });
    } else {
      this.lightBoxesService.open('custom-generico', 
      {
        title: 'Atenção!', 
        text: 'Todos os campos são obrigatórios. Por favor, preencha-os antes de enviar a mensagem'
      },
        5000
      )
    }

    
  }

  get formControls() {
    return this.formFaleComApsa.controls;
  }
}
