<a class="saldo-devedor" *ngIf="saldoDevedor !== null" routerLink="/acompanhamento-orcamentario">
  <h1 class="title">
    Saldo devedor
  </h1>

  <div class="warning">
    <i class="fas fa-exclamation-triangle"></i>

    <p class="description">
      Fechamento do saldo<br>
      do mês anterior:
    </p>

    <p class="value" [ngClass]="{'__is-danger': saldoDevedor[0] === '-'}">
      R$ {{ saldoDevedor }}
    </p>
  </div>
</a>